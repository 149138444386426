import React from "react";
import { Link } from "react-router-dom";
class BlogWgt extends React.Component {
  constructor(props) {
    super(props);
      this.state = {

      };
    }
  render() {
    return <div className="blog-other-wgt">
      <div className="blog-other-wgt__inner">
        <div className="blog-other-wgt__thumb-wrap">
          <Link to={this.props.to}>
            <img className="blog-other-wgt__thumb" src={this.props.thumb} alt={this.props.headline} />
          </Link>
        </div>
        <div className="blog-other-wgt__content">
          <div className="blog-other-wgt__info-wrap">
            <div className="blog-other-wgt__info">
              <span>{this.props.date ? this.props.date  : ''}</span>
              <span>5 min read</span>
            </div>
            <Link to={this.props.to}>
              <h2 className="blog-other-wgt__headline">{this.props.headline}</h2>
            </Link>
          </div>
          <div className="blog-other-wgt__label-wrap">
            <span className="blog-other-wgt__label">{this.props.category}</span>
          </div>
        </div>
      </div>
    </div>;
  }
}
export default BlogWgt;
