import React from "react";
class OrderModalDropdownStatusChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusDropDown: false,
      clickedInside: false
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  handleClick() {
    this.setState((prevState) => ({
      statusDropDown: !prevState.statusDropDown,
      clickedInside: true
    }));
  }
  handlerDropDownStatus(event) {
    this.setState(prevState => ({
      statusDropDown: !prevState.statusDropDown
    }));
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        statusDropDown: false,
      });
    }
    this.setState({ clickedInside: false });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  render() {
    if(this.props.restoreFromSubmit){
      setTimeout(() => {
        this.setState(prevState => ({
          statusDropDown: false
        }));
      }, 10)
    }
      return     <div className="modal__order-nav-dropdown" ref={this.setWrapperRef}>
                  <button className={`modal__order-nav-button modal__order-nav-button-dropdown modal__order-nav-button_${this.props.activeStatus.toLowerCase()}`} type="button" onClick={this.handlerDropDownStatus.bind(this)}>{this.props.title}: {this.props.statusItems[this.props.activeStatus]}</button>
                  <div className={this.state.statusDropDown && (this.props.activeStatus !== 'DECLINED' && this.props.activeStatus !== 'DONE') ? 'modal__order-nav-content-dropdown active' : 'modal__order-nav-content-dropdown'}>
                    <ul>
                      {Object.keys(this.props.statusItems).map((item, index) => {
                        return <li key={index}>
                                <button className={`modal__order-nav-content-dropdown_${item.toLowerCase()}`} type="button" onClick={this.props.handler.bind(this, this.props.id, item)}>{this.props.statusItems[item]}
                                </button>
                               </li>;
                      })}
                    </ul>
                  </div>
                </div>
  }
}
export default OrderModalDropdownStatusChange;
