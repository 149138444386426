import React from "react";
import AccountNav from './components/AccountNav';
import AccountHeader from './components/AccountHeader';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Loading from './components/Loading';
import { Link, Navigate } from "react-router-dom";
import AccountContentHeader from './components/AccountContentHeader';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import TabItems from './components/TabItems';
import Table from './components/Table';
import Select from 'react-select';
import Pagination from './components/Pagination';
import FilterSearch from './components/FilterSearch';
import DeleteModal from './components/DeleteModal';
import member_1 from './media/avatar-1.png';
import mail_photo from './media/mail-icon.png';
import { getAuth } from "firebase/auth";
import sprite from './media/icons.svg';
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  }
}),
placeholder: (provided, state) => ({
  ...provided,
  color: '#a7a7a7',
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex',
    backgroundColor: '#fff'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
function validateEmail(email) {
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(String(email).toLowerCase());
}
class AccountUsers extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        token: this.props.token(),
        modalCreateUser: false,
        createUserEmailValue: '',
        createUserNameValue: '',
        createUserSurNameValue: '',
        createUserPositionValue: '',
        mobileMenuStart: true,
        mobileMenu: false,
        dropdownStatus: false,
        indexRowUser: null,
        removeIndex: null,
        indexRowStatus: null,
        errorUser: null,
        isLoadedUser: false,
        user: [],
        isLoadedGetUser: false,
        isLoaded: false,
        users: [],
        totalPages: null,
        currentPage: 0,
        modalClass: null,
        userInfo: false,
        modalDisabled: false,
        modal: false,
        modalAddUser: false,
        modalGetUser: false,
        modalData: {},
        usersIDs: [],
        allUsers: false,
        modalInvite: false,
        successSubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? JSON.parse(localStorage.getItem("successDeposit")) : '',
        submitLoading: false,
        totalItems: '',
        getUserID: null,
        totalItemsTeam: '',
        successError: false,
        resultError: null,
        roles: [],
        teamOptions: [],
        errorFields: false,
        validateEmailError: false,
        roleValue: [{label: this.props.getTranslate('role-user'), value: 'ROLE_EMPLOYEE'}],
        teamValue: null,
        currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
        errors: null,
        error: false,
        loadingVerify: false,
        modalDeleteUser: false,
        idDeleteUser: null,
        sortFullNameDirection: 'ASC',
        sortEmailDirection: 'ASC',
        sortPositionDirection: 'ASC',
        sortBalanceDirection: 'ASC',
        controlRow: null,
        redirectDeposit: false,
        getUsersIds: [],
        updateId: null,
        modalCreateTeam: false,
        modalGetUsers: false,
        usersTeam: [],
        teamMembers: [],
        searchUsersValue: '',
        searchEmpty: false,
        errorNameOnlySpace: false,
        errorLastNameOnlySpace: false,
        errorPositionOnlySpace: false
      }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }
  handlerDeleteUserModal(id, index, event){
    this.setState({
      modalDeleteUser: true,
      removeIndex: index,
      idDeleteUser: id
    });
  }
  hanlderUserDelete (event) {
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${that.state.idDeleteUser}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              loadingDeleteSumbit: false
            })
          });
        } else {
          that.setState({
            indexRowUser: that.state.removeIndex,
            successSubmit: true,
            successText: that.props.getTranslate('successStatus-Delete')
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
          fetch(`${that.props.queryLink}/api/v1/users/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({"itemsPerPage": 20, "page": that.state.users.length < 2 ? (that.state.currentPage - 1) : that.state.currentPage})
          }).then(res => {
            if (res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors,
                  loadingDeleteSumbit: false
                })
              });
            } else {
              if(that.state.modalGetUser){
                that.handleModalDisabled();
              }
              res.json().then(function(data) {
                that.setState({
                  indexRowUser: null,
                  removeIndex: null,
                  modalDeleteUser: false,
                  users: data.data,
                  loadingDeleteSumbit: false
                })
              });
            }
          })
        }
      })
    })
  }

  handleModalDisabled = () => {
    document.querySelector('body').style.overflow = "";
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalGetUser: false,
        modalCreateUser: false,
        modalDisabled: false,
        user: [],
        isLoadedGetUser: false,
        modalInvite: false,
        createUserEmailValue: '',
        createUserSurNameValue: '',
        createUserNameValue: '',
        createUserPositionValue: '',
        roleValue: [{label: this.props.getTranslate('role-user'), value: 'ROLE_EMPLOYEE'}],
        teamValue: null,
        modalDeleteUser: false,
        idDeleteUser: null,
        modalCreateTeam: false,
        updateId: null,
        modalGetUsers: false,
        usersTeam: [],
        getUsersIds: [],
        teamName: '',
        errorNameOnlySpace: false,
        errorLastNameOnlySpace: false,
        errorPositionOnlySpace: false,
        errorFields: false
      }));
    }, 500);
  }
  handlerCreateUserEmailValue(event){
    this.setState({
      createUserEmailValue: event.target.value
    });
  }
  handlerCreateUserNameValue(event){
    if(this.state.errorNameOnlySpace){
      this.setState({
        errorNameOnlySpace: false
      });
    }
    this.setState({
      createUserNameValue: event.target.value
    });
  }
  handlerCreateUserSurNameValue(event){
    if(this.state.errorLastNameOnlySpace){
      this.setState({
        errorLastNameOnlySpace: false
      });
    }
    this.setState({
      createUserSurNameValue: event.target.value
    });
  }
  handlerCreateUserPositionValue(event){
    if(this.state.errorPositionOnlySpace){
      this.setState({
        errorPositionOnlySpace: false
      });
    }
    this.setState({
      createUserPositionValue: event.target.value
    });
  }
  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }
  handlerSubmitCreateUser (e) {
    e.preventDefault();
    let that = this;
    let error = false;
    let firstName = that.validItemEmpty('createUserNameValue');
    let lastName = that.validItemEmpty('createUserSurNameValue');
    let positionValue = that.validItemEmpty('createUserPositionValue');
    if(/^\s*$/.test(firstName) && firstName !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    if(/^\s*$/.test(lastName) && lastName !== ''){
      error = true;
      this.setState({errorLastNameOnlySpace: true });
    }
    if(/^\s*$/.test(positionValue) && positionValue !== ''){
      error = true;
      this.setState({errorPositionOnlySpace: true });
    }
    if(this.state.createUserEmailValue === '' || (this.state.createUserEmailValue !== '' && !validateEmail(this.state.createUserEmailValue)) || !this.state.roleValue || firstName === '' || lastName === ''){
      error = true;
    }
    this.setState({
      submitLoading: true
    });
    if(!error) {
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/users`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "companyLogo": that.props.companyLogo,
            "companyName": that.props.companyName,
            "email": that.state.createUserEmailValue,
            "lastName": lastName,
            "name": firstName,
            "position": positionValue,
            "roles": that.state.roleValue.map(x => x.value),
            "teamID": that.state.teamValue
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                submitLoading: false
              })
            });
          } else {
              that.setState({
                submitLoading: false
              });
            fetch(`${that.props.queryLink}/api/v1/users/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({"itemsPerPage": 20})
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  })
                });
              } else {
                res.json().then(function(data) {
                  that.setState({
                    isLoaded: true,
                    users: data.data,
                    totalPages: data.totalPages,
                    totalItems: data.totalItems
                  });
                });
                that.handleModalDisabled();
                setTimeout(() => {
                  that.setState(prevState => ({
                    modal: !prevState.modal,
                    modalInvite: true
                  }))
                }, 500);
              }
            })
          }
        })
      })
    } else {
      this.setState({
        submitLoading: false,
        errorFields: true
      });
    }
  }
  handlerAddUser () {
    this.setState(prevState => ({
      modal: !prevState.modal,
      modalClass: null,
      modalAddUser: !prevState.modalAddUser
    }))
  }
  handlerCreateUserInfo () {
    this.setState(prevState => ({
      userInfo: !prevState.userInfo
    }))
  }
  handlerPaginationItems = (userItems, current) => {
    this.setState({
      users: userItems,
      currentPage: current
    });
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(
          {
            "itemsPerPage": 20
          }
        )
      }).then(res => {
        if(res.status === 200) {
          res.json().then(function(data) {
            that.setState({
            isLoaded: true,
            users: data.data,
            totalPages: data.totalPages,
            totalItems: data.totalItems
            })
          });
        } else if(res.status === 401) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: [data.message],
              error: true
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.errors,
              error: true
            })
          });
        }
      })
        fetch(`${that.props.queryLink}/api/v1/teams/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "itemsPerPage": 20
          })
        }).then(res => {
        if(res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              totalItemsTeam: data.totalItems
            })
          });
        }
      })
    })
  }

handlerSearch(event) {
  let that = this;
    this.setState({
    isLoadedSearch: true,
    searchUsersValue: event.target.value,
    searchEmpty: false
  });
  let timeoutId = setTimeout(() => {
    getAuth().currentUser.getIdToken().then(function(idToken) {
            const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "query": that.state.searchUsersValue})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedSearch: false
            });
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedSearch: false,
              users: data.data,
              totalPages: data.totalPages,
              totalItems: data.totalItems
            });
            if(data.data.length < 1){
              that.setState({
                searchEmpty: true
              });
            }
          });
        }
      });
    });
  }, 500);
  if (this.timeoutId) {
    clearTimeout(this.timeoutId);
  }

  this.timeoutId = timeoutId;
}


  handlerSortUsers(orderField, event){
    let that = this;
    let direction = '';
    if(orderField === 'fullName'){
      direction = this.state.sortFullNameDirection;
      that.setState(prevState => ({
        sortFullNameDirection: prevState.sortFullNameDirection === 'ASC' ? 'DESC' : 'ASC'
      }));
    } else if(orderField === 'email') {
      direction = this.state.sortEmailDirection;
      that.setState(prevState => ({
        sortEmailDirection: prevState.sortEmailDirection === 'ASC' ? 'DESC' : 'ASC'
      }));
    } else if(orderField === 'position') {
      direction = this.state.sortPositionDirection;
      that.setState(prevState => ({
        sortPositionDirection: prevState.sortPositionDirection === 'ASC' ? 'DESC' : 'ASC'
      }));
    } else if(orderField === 'balance') {
      direction = this.state.sortBalanceDirection;
      that.setState(prevState => ({
        sortBalanceDirection: prevState.sortBalanceDirection === 'ASC' ? 'DESC' : 'ASC'
      }));
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(
          {
            "direction": direction,
            "itemsPerPage": 20,
            "page": that.state.currentPage,
            "orderField": orderField
          }
        )
      }).then(res => {
        if(res.status === 200) {
          res.json().then(function(data) {
            that.setState({
            isLoaded: true,
            users: data.data,
            totalPages: data.totalPages,
            totalItems: data.totalItems
            })
          });
        } else if(res.status === 401) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: [data.message],
              error: true
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.errors,
              error: true
            })
          });
        }
      })
    })
  }

  handlerUserStatus (id, index, event) {
    let that = this;
    let usersArr = this.state.users;
    that.setState({
      indexRowStatus: index
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
      let status = (usersArr[index].userStatus === 'NOT_ACTIVE' || !usersArr[index].userStatus) ? 'ACTIVE' : 'NOT_ACTIVE';
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${id}/status`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"userStatus": status})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              indexRowStatus: null
            })
          });
        } else {
          usersArr[index].userStatus = status;
          that.setState({
            users: usersArr,
            indexRowStatus: null
          });
        }
      })
    })
  }
  handlerCreateUser(){
    let that = this;
    if(this.state.roles){
      that.setState({
        teamOptions: [{'value': null, 'label': this.props.getTranslate('modalAddUser-NoTeamLabel')}],
        roles: []
      });
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/roles`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
              data.map((item, index) => {
                let label = '';
                if(item === 'ROLE_ADMINISTRATOR'){
                  label = that.props.getTranslate('role-administrator');
                } else {
                  label = that.props.getTranslate('role-user');
                }
                that.setState(prevState => ({
                  roles: [...prevState.roles, {value: item, label: label} ]
                }));
              });
          });
        }
      });
      fetch(`${that.props.queryLink}/api/v1/teams/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "itemsPerPage": 20
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            let teams = data.data.map((item, index) => {
              return {value: item.teamID, label: item.name}
            });
            that.setState(prevState => ({
              teamOptions: [...prevState.teamOptions, ...teams]
            }));
          });
        }
      })
    })
    this.setState(prevState => ({
      modalCreateUser: !prevState.modalCreateUser
    }));
  }
  addUsersIds(){
    if(this.state.usersIDs){
      localStorage.setItem("usersIds", JSON.stringify(this.state.usersIDs));
    }
    if(this.state.allUsers){
      localStorage.setItem("allUsers", true);
    }
  }
  handlerChooseUser(id, name, list, event){
    if(this.state.allUsers){
      this.setState(prevState => ({
        allUsers: false
      }));
    }
    let arr = this.state.usersIDs;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {id: id, name: name}];
    }
    this.setState(prevState => ({
      usersIDs: arr
    }));
    if(list === 'list' && arr.length > 0){
      if(this.state.usersIDs){
        localStorage.setItem("usersIds", JSON.stringify(arr));
      }
      this.setState(prevState => ({
        redirectDeposit: true
      }));
    }
  }
  addUsersId(id, name, event){
    let obj = {id: id, name: name};
    localStorage.setItem("usersIds", JSON.stringify([obj]));
  }
  handlerGetAllUsers(){
    if(this.state.usersIDs){
      this.setState(prevState => ({
        usersIDs: []
      }));
    }
    this.setState(prevState => ({
      allUsers: !prevState.allUsers
    }));
  }
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSubmit: false
    }));
    localStorage.removeItem('successDeposit');
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errorSubmit: null,
      successError: false,
      errors: null
    }));
  }
  handlerGetUsers(id, index, event){
    if(!event.target.closest('.control-wgt') && !event.target.closest('.team-table__choose') && !event.target.closest('.tooltips-wrap')){
      this.setState({
        getUserID: id
      });
    }
  }
  handlerGetRole(event){
    this.setState(prevState => ({
      roleValue: event.map(item => item)
    }));
  }
  handlerGetTeam(event){
    this.setState({
      teamValue: event.value
    });
  }
  handlerSendVerify(id, email, event){
    let that = this;
    that.setState({
      loadingVerify: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/${id}/invites`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "companyLogo": that.props.companyLogo,
          "companyName": that.props.companyName,
          "email": email,
          "userID": id
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
            errors: data.errors,
            loadingVerify: false
          })
        });
      } else {
        that.setState({
          successSubmitVerify: true,
          loadingVerify: false
        });
      }
      })
    })
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCreateTeamModal () {
    this.setState(prevState => ({
      updateId: null,
      modalCreateTeam: !prevState.modalCreateTeam
    }));
  }

  handlerAddNewMember (event) {
    event.preventDefault();
    let that = this;
    this.setState(prevState => ({
      modalDisabled: true,
      addNewMember: false
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalCreateTeam: false,
        modalGetTeam: false,
        modalGetUsers: false,
        modalDisabled: false
      }));
    }, 500);
    setTimeout(() => {
      this.setState(prevState => ({
        modalGetUsers: true,
        addNewMember: true
      }));
    }, 500);
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedUser: true,
            errorUsers: data.errors
          })
        });
      } else {
        res.json().then(function(data) {
          that.setState({
            isLoadedUser: true,
            usersTeam: data
          })
        });
      }
    })
  })
  }

  handlerToggleUser(index, name, id, photo){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {id: id, name: name, photo: photo}];
    }
    this.setState(prevState => ({
      getUsersIds: arr
    }));
  }

  handlerAddUserIds(){
    let ids = this.state.getUsersIds.map((x => x.id));
    this.setState({
      teamMembers: ids
    });
    this.setState(prevState => ({
      modalDisabled: true,
      addNewMember: false
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalCreateTeam: false,
        modalGetTeam: false,
        modalGetUsers: false,
        modalDisabled: false
      }));
    }, 500);
    setTimeout(() => {
      this.setState(prevState => ({
        modalCreateTeam: true
      }));
    }, 500);
  }

  handlerTeamName (event) {
    this.setState({
      teamName: event.target.value
    });
  }

  handlerCreateTeamSubmit (event) {
    event.preventDefault();
    let that = this;
    if(!this.state.teamName){
      this.setState({
        errorFields: true
      });
    }
    if(this.state.teamName){
      this.setState({
        isLoadedSubmitTeam: true
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
        fetch(`${that.props.queryLink}/api/v1/teams`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "name": that.state.teamName,
            "users": that.state.teamMembers
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSubmitTeam: false,
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                isLoadedSubmitTeam: false,
                teamName: '',
                totalItemsTeam: prevState.totalItemsTeam + 1,
                successSubmit: true,
                errorFields: false
              }));
              that.handleModalDisabled();
              setTimeout(() => {
                that.setState({
                  successSubmit: false
                });
              }, 5000);
              Promise.all(that.state.addMembersByCreateTeam).then(values => {
                values.map((item, index) => {
                  fetch(`${that.props.queryLink}/api/v1/teams/${data.teamID}/assign`, {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify({
                      "userID": item
                    })
                  }).then(res => {
                    if(res.status !== 200) {
                      res.json().then(function(data) {
                        that.setState({
                          errors: data.errors
                        })
                      });
                    }
                  })
                })
              });
            })
          }
        })
      })
      }
    }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('employees-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.successSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    if(this.state.successError){
      setTimeout(() => {
        this.handlerCloseError();
      }, 5000);
    }


    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users =  this.state.searchValue ? <div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID, elem.photo)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div> : <Scrollbars
      renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
      renderTrackVertical={props => <div {...props} className="track-vertical"/>}
      renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
      renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
      renderView={props => <div {...props} className="view"/>}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={500}
        ><div className="team-list-modal">
        {this.state.usersTeam.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label">{item.name ? item.name : this.props.getTranslate('employees-AddUserTeamTitle')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
              return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID, elem.photo)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }


    let resultUsers = '';
    if(this.state.error) {
      resultUsers = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultUsers = <Loading />;
    } else {
      resultUsers = <div className="account-wgt account-wgt__users">

            {/* Table component */}
              <Table
                header={[
                  <div>
                    <input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" checked={this.state.allUsers} onChange={this.handlerGetAllUsers.bind(this)} />
                    <label htmlFor="styled-checkbox-0"></label>
                  </div>,
                  {
                    label: this.props.getTranslate('employees-ListNameTitle'),
                    handler: this.handlerSortUsers.bind(this, 'fullName')
                  },
                  {
                    label: 'E-mail',
                    handler: this.handlerSortUsers.bind(this, 'email')
                  },
                  {
                    label: this.props.getTranslate('employees-ListPositionTitle'),
                    handler: this.handlerSortUsers.bind(this, 'position')
                  },
                  this.props.getTranslate('employees-ListTeamTitle'),
                  {
                    label: this.props.getTranslate('employees-ListBalanceTitle'),
                    handler: this.handlerSortUsers.bind(this, 'balance'),
                  },
                  this.props.getTranslate('employees-ListAccessTitle'),
                  ''
                ]}
                handlerRow={this.handlerGetUsers.bind(this)}
                userTable={true}
                usersIDs={this.state.usersIDs}
                allUsers={this.state.allUsers}
                confirmationPendingLabel={this.props.getTranslate('employees-UserConfirmationPending')}
                confirmationPendingBtnLabel={this.props.getTranslate('employees-UserConfirmationLink')}
                confirmationPendingBtnHandler={this.handlerSendVerify.bind(this)}
                loadingVerify={this.state.loadingVerify}
                sendSuccessActivity={this.state.successSubmitVerify}
                usersRoles={{
                    'ROLE_ADMINISTRATOR': <div className="team-table__rights-tooltip" style={{paddingRight: '3px'}}><svg className="team-table__rights-icon">
                                <use href={`${sprite}#access-admin-icon`}></use>
                              </svg><span>{this.props.getTranslate('role-administrator')}</span></div>,
                    'ROLE_EMPLOYEE': <div className="team-table__rights-tooltip" style={{paddingRight: '3px'}}><svg className="team-table__rights-icon">
                                <use href={`${sprite}#access-user-icon`}></use>
                              </svg><span>{this.props.getTranslate('role-user')}</span></div>
                  }}
                userSelf=<div className="team-table__rights-tooltip" style={{paddingRight: '3px'}}><svg className="team-table__rights-icon">
                              <use href={`${sprite}#access-owner-icon`}></use>
                            </svg><span>{this.props.getTranslate('role-owner')}</span></div>
                checkboxesHandler={this.handlerChooseUser.bind(this)}
                bodyData={this.state.users}
                bodyDataNames={['', 'name', 'email', 'position', 'team', 'balance', 'roles']}
                checkboxItems={true}
                controlItemsStatus={true}
                controlItems={{
                  'sendCalculation': {
                    'status': true,
                    'handler': this.handlerChooseUser.bind(this),
                    'label': this.props.getTranslate('tableDropdown-SendCalculation')
                  },
                  'sendMessage': false,
                  'enabled': {'status': true, 'handler': this.handlerUserStatus.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusUserLabel'), 'show': this.props.getTranslate('tableDropdown-StatusUserLabelActive')}},
                  'editButton': false,
                  'editLink': {
                    'status': true,
                    'handler': this.props.getPermalink('/account/users/edit/'),
                    'label': this.props.getTranslate('tableDropdown-EditLabel'),
                    'state': this.props.getPermalink('/account/users/')
                  },
                  'delete': {
                    'status': true,
                    'handler': this.handlerDeleteUserModal.bind(this),
                    'label': this.props.getTranslate('tableDropdown-EditRemove')
                  }
                }}
                currencyLabel={{
                  'balance': this.props.currency
                }}
                hightlightsCol={['balance']}
                itemIDName={'userID'}
                itemEnableNameForStatus={'NOT_ACTIVE'}
                itemEnableNameForStatusData={'userStatus'}
                removeIndex={this.state.removeIndex}
                enableTooltipMessage={this.props.getTranslate('tableEnable-TooltipMessage')}
              />

      </div>
    }
    return <>

    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.getUserID && <Navigate
      to={this.props.getPermalink(`/account/users/view/${this.state.getUserID}`)}
      replace={true} />) : ''}

    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.redirectDeposit && <Navigate
      to={this.props.getPermalink(`/account/deposits/create`)}
      replace={true} />) : ''}

    {/* Query result handler */}
    {this.state.successSubmit ? <ResultQuery
      success={this.state.successSubmit}
      resultText={this.props.getTranslate('successStatus-Save')}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    /> : ''}

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

      {/* Navigation component */}
      <AccountNav
        getPermalink={this.props.getPermalink}
        queryLink={this.props.queryLink}
        configsApp={this.props.configsApp}
        newReview={this.props.newReview}
        companyName={this.props.companyName}
        token={this.props.token()}
        status={this.state.mobileMenu}
        statusStart={this.state.mobileMenuStart}
        handler={this.handlerMobileMenu}
        dropdownStatus={this.state.dropdownStatus}
        dropdownHandler={this.handlerDropdown}
        getTranslate={this.props.getTranslate}
        completed={this.props.completed}
      />

      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            title={this.props.getTranslate('employees-Title')}
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
          />

            {/* Tabs component */}
            <TabItems items={[
              {
                label: this.props.getTranslate('employees-TabList'),
                path: this.props.getPermalink("/account/users"),
                amount: this.state.totalItems
              },
              {
                label: this.props.getTranslate('employees-TabTeams'),
                path: this.props.getPermalink("/account/teams"),
                amount: this.state.totalItemsTeam
              }
            ]} />

            <div className="tab-navs">
              <div className="tab-navs__inner">
              <button className="btn btn-header btn_with_icon team-nav__btn" type="button" onClick={this.handlerCreateUser.bind(this)}>
                <span className="btn-header__inner">
                  <svg className="btn__icon-add">
                    <use href={`${sprite}#plus-icon`}></use>
                  </svg>{this.props.getTranslate('employees-ListAddButton')}
                </span>
                  <svg className="btn-header__icon-mob">
                    <use href={`${sprite}#add-user-icon`}></use>
                  </svg>
              </button>

              <Link className="btn btn-header btn_theme_secondary team-nav__btn" to={this.props.getPermalink("/account/deposits/create/")} onClick={this.addUsersIds.bind(this)}><span className="btn-header__inner">{this.props.getTranslate('employees-ListAddBonusButton')}</span>              <svg className="btn-header__icon-mob">
                              <use href={`${sprite}#add-bonuses-icon`}></use>
                            </svg></Link>
                            </div>

              {/* Filter search input component */}
              <FilterSearch
                placeholder={this.props.getTranslate('userSearch-Placeholder')}
                value={this.state.searchUsersValue}
                handler={this.handlerSearch.bind(this)}
              />

            </div>

          <div className="account-content__content">

            {/* Query result */}
            {resultUsers}

          </div>

          <div className="account-content__footer">

            {/* Pagination component */}
            <Pagination
              total={this.state.totalPages}
              perPage="20"
              path={`${this.props.queryLink}/api/v1/users/search`}
              currentPage={this.state.currentPage}
              items={this.handlerPaginationItems}
            />

          </div>
        </div>
      </div>

    </div>

    {this.state.modalCreateUser ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('employees-AddUserTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
    <form className="add-user-form" onSubmit={this.handlerSubmitCreateUser.bind(this)}>
     <div className="field-wrap">
       <label className="field-wrap__label" htmlFor="email-user">{this.props.getTranslate('employees-AddUserSubTitle')}</label>
       <input id="email-user" className={`field-wrap__input ${(this.state.errorFields && (!this.state.createUserEmailValue || !validateEmail(this.state.createUserEmailValue))) ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.createUserEmailValue} onChange={this.handlerCreateUserEmailValue.bind(this)} placeholder="E-mail" />
       {this.state.errorFields && this.state.createUserEmailValue === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
       {this.state.errorFields && (!validateEmail(this.state.createUserEmailValue) && this.state.createUserEmailValue !== '') ? <div className="validation-error">{this.props.getTranslate('validation-IncorrectEmail')}</div> : ''}
     </div>
     <div className="add-user-form__info">
       <div className="field-wrap">
        {this.state.roles ? <Select
          className="field-wrap__select"
          styles={this.state.errorFields && (this.state.roleValue.length < 1) ? customStylesError : customStyles}
          options={this.state.roles}
          defaultValue={{label: this.props.getTranslate('role-user'), value: 'ROLE_EMPLOYEE'}}
          onChange={this.handlerGetRole.bind(this)}
          placeholder={this.props.getTranslate('employees-AddUserAccessFieldCaption')}
          isSearchable={false}
          isMulti
        /> : ''}
        {this.state.errorFields && (this.state.roleValue.length < 1) ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
        </div>
        <div className="field-wrap">
         <Select
           className="field-wrap__select"
           styles={customStyles}
           options={this.state.teamOptions}
           defaultValue={{'value': null, 'label': this.props.getTranslate('modalAddUser-NoTeamLabel')}}
           placeholder={this.props.getTranslate('employees-AddUserTeamsFieldCaption')}
           onChange={this.handlerGetTeam.bind(this)}
           isDisabled={this.state.teamOptions ? false : true}
           isSearchable={false}
         />
         </div>
     </div>
      <div className="field-wrap">
       <input className={`field-wrap__input ${(this.state.errorFields && !this.state.createUserNameValue) || (this.state.errorNameOnlySpace && this.state.createUserNameValue !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.createUserNameValue} onChange={this.handlerCreateUserNameValue.bind(this)} placeholder={this.props.getTranslate('employees-AddUserNameFieldCaption')} />
       {this.state.errorFields && this.state.createUserNameValue === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
       {this.state.errorNameOnlySpace && this.state.createUserNameValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
     </div>
     <div className="field-wrap">
       <input  className={`field-wrap__input ${(this.state.errorFields && !this.state.createUserSurNameValue) || (this.state.errorLastNameOnlySpace && this.state.createUserSurNameValue !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.createUserSurNameValue} onChange={this.handlerCreateUserSurNameValue.bind(this)} placeholder={this.props.getTranslate('employees-AddEUsereLastNameFieldCaption')} />
       {this.state.errorFields && this.state.createUserSurNameValue === '' ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
       {this.state.errorLastNameOnlySpace && this.state.createUserSurNameValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
      </div>
     <div className="field-wrap">
       <input className={`field-wrap__input ${this.state.errorPositionOnlySpace && this.state.createUserPositionValue !== '' ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.createUserPositionValue} onChange={this.handlerCreateUserPositionValue.bind(this)} placeholder={this.props.getTranslate('employees-AddUserPositionFieldCaption')} />
       {this.state.errorPositionOnlySpace && this.state.createUserPositionValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
     </div>
     <div className="register-form__submit">
        <button className="btn btn_size_lg btn_width_100 user-info__nav-btn  form-nav__submit" type="submit" disabled={this.state.submitLoading? true : false}>{this.props.getTranslate('employees-AddUserInviteButton')}{this.state.submitLoading && <Loading />}</button>
      </div>
    </form></div></div> : ''}
        {this.state.modalInvite ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
          <div className="modal">
            <div className="modal-header">
              <div></div>
              <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                <svg className="close-modal__icon">
                  <use href={`${sprite}#close-icon`}></use>
                </svg>
              </button>
            </div>
            <div className="success-add-user">
              <img className="success-add-user__icon" src={mail_photo} alt="" />
              <div className="success-add-user__headline">{this.props.getTranslate('registerUserModal-userInvite')}</div>
              <a className="success-add-user__mail" href={`mailto:${this.state.createUserEmailValue}`}>{this.state.createUserEmailValue}</a>
            </div></div></div> : ''}


            {this.state.modalCreateTeam ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
              <div className="modal-background" onClick={this.handleModalDisabled}></div>
              <div className="modal">
                <div className="modal-header">
                  <h2 className="modal-headline">{this.state.updateId ? this.props.getTranslate('employees-EditTeamTitle') : this.props.getTranslate('employees-AddTeamTitle')}</h2>
                  <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                    <svg className="close-modal__icon">
                      <use href={`${sprite}#close-icon`}></use>
                    </svg>
                  </button>
                </div>
                <form className="add-user-form" onSubmit={this.handlerCreateTeamSubmit.bind(this)}>
                 <div className="field-wrap">
                   <input className={`field-wrap__input ${this.state.errorFields && !this.state.teamName ? 'field-wrap__input_error' : ''}`}  type="text" value={this.state.teamName} onChange={this.handlerTeamName.bind(this)} placeholder={this.props.getTranslate('employees-AddTeamModalNamePlaceholder')} />
                   {this.state.errorFields && !this.state.teamName ? <div className="validation-error">{this.props.getTranslate('employees-AddTeamNameFiled')}</div> : ''}
                 </div>
                 <div className="field-wrap">
                    <button className="btn btn_with_icon btn_size_lg btn_width_100 btn_theme_secondary" type="button" onClick={this.handlerAddNewMember.bind(this)}>
                 <svg className="btn__icon-add">
                   <use href={`${sprite}#plus-icon`}></use>
                 </svg>{this.props.getTranslate('employees-AddTeamModalBtnAddUser')}</button>
                 </div>
                  {this.state.getUsersIds.length > 0 ? this.state.getUsersIds.map((item, index) => {
                    return <div className="users-modal__item" key={index}>
                         <div className="users-modal__info">
                           <img className="users-modal__avatar" src={item.photo ? item.photo : member_1} alt="" />
                           <div className="users-modal__name">{item.name ? item.name : ''}</div>
                         </div>
                         <button className="team-table__nav-btn" type="button">
                           <svg>
                             <use href={`${sprite}#delete`}></use>
                           </svg>
                         </button>
                       </div>
                  }) : ''}
                   <div className="field-wrap__submit">
                     <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="submit" disabled={this.state.isLoadedSubmitTeam ? true : false}>{this.props.getTranslate('employees-AddTeamCreatButton')}{this.state.isLoadedSubmitTeam && <Loading />}</button>
                   </div>
                </form>
              </div>
            </div> : ''}

            {this.state.modalGetUsers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
              <div className="modal-background" onClick={this.handleModalDisabled}></div>
              <div className="modal">
                <div className="modal-header">
                  <h2 className="modal-headline">{this.props.getTranslate('employees-AddUserTitle')}</h2>
                  <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
                    <svg className="close-modal__icon">
                      <use href={`${sprite}#close-icon`}></use>
                    </svg>
                  </button>
                </div>
                <div className="field-wrap field-wrap__search-members">
                  <input className="field-wrap__input field-wrap__input_bg" type="text" placeholder={this.props.getTranslate('employees-AddUserSearchFieldCaption')} value={this.state.searchValue} />
                </div>
                <div className="question-members">
                  <div className="question-members-wgt">
                    {users}
                  </div>
                    <div className="field-wrap__submit">
                      <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerAddUserIds.bind(this)}>{this.props.getTranslate('employees-AddUserAddButton')}</button>
                    </div>
                </div>
              </div>
            </div> : ''}

    {/* Delete item modal */}
    <DeleteModal
      modalStatus={this.state.modalDeleteUser}
      modalDisabledStatus={this.state.modalDisabled}
      modalDisabledClass={this.state.addClass}
      handlerModalDisabled={this.handleModalDisabled}
      modalTitle={this.props.getTranslate('removeModal-UserTitle')}
      modalDescription={this.props.getTranslate('removeModal-UserTitleDescription')}
      modalHandlerDelete={this.hanlderUserDelete.bind(this)}
      modalHandlerBtnTxt={this.props.getTranslate('removeModal-ButtonSubmit')}
      modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
    />

    </>;
  }
}
export default AccountUsers;
