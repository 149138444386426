import React from "react";
import moment from 'moment-timezone';
import { Link, Navigate } from "react-router-dom";
import Loading from './components/Loading';
import Header from './components/Header';
import Footer from './components/Footer';
import Select from 'react-select';
import company_img from './media/company-logo.png';
import success_img from './media/success-reg.png';
import apple_img from './media/apple-store.png';
import google_img from './media/google-play.png';
import app_img from './media/app-gallery.png';
import sprite from './media/icons.svg';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyCiErL0xg1EQ-FODI8KjJZbn0j73t6WcxU"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  })
}

const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  })
}

function validateEmail(email) {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // Check that email matches the regex and contains no Cyrillic characters
  return re.test(email) && !/[А-Яа-яЁё]/.test(email);
}


function validatePassword(password) {
  let validCharacters = /^[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\]^_`{|}~]+$/;
  let containsLetterOrDigit = /[A-Za-z0-9]/;
  let isValid = validCharacters.test(password) && containsLetterOrDigit.test(password);
  return isValid;
}
function validateName(name){
  let nameValid =  /[a-zA-Zа-яА-Я0-9]|[.\-_!]/.test(name) && !/^[.\-_!]+$/.test(name);
  return nameValid;
}
class Registration extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        loading: false,
        registerTab: false,
        passwordHide: true,
        repasswordHide: true,
        nameValue: '',
        employeesValue: '',
        typeValue: '',
        loginValue: '',
        passwordValue: '',
        rePasswordValue: '',
        userNameValue: '',
        userLastNameValue: '',
        errorFields: false,
        errorEmail: false,
        errors: null,
        errorPasswordConfirm: false,
        errorPasswordLattin: false,
        errorPasswordLength: false,
        errorNameValue: false,
        success: false,
        countValues: {},
        errorNameOnlySpace: false,
        errorLengthLogin: false,
        errorLengthUserName: false,
        errorLengthUserLastName: false,
        errorLengthPasswordValue: false,
        errorLengthRePasswordValue: false
      };
      this.handlerRegisterTab = this.handlerRegisterTab.bind(this, false);
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      window.scrollTo(0, 0);
    }
    let that = this;
    const timeZone = moment.tz.guess();
    that.setState({
      timeZone: timeZone
    });
    const myHeaders = new Headers({
        'Content-Type': 'application/json'
    });
    fetch(`${this.props.queryLink}/api/v1/companies/employee-types`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {

      } else {
        res.json().then(function(data) {
          let values = data;
          that.setState({
            countValues: values
          });
        })
      }
    })
  }
  handlerPassword () {
    this.setState({ passwordHide: !this.state.passwordHide });
  };
  handlerRepassword () {
    this.setState({ repasswordHide: !this.state.repasswordHide});
  };

  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }

  handlerRegisterSubmit(event) {
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState({
      errorFields: false,
      errorEmail: false,
      errorPasswordConfirm: false,
      errorPasswordLattin: false,
      errorPasswordLength: false,
      errorNameOnlySpace: false,
      errorLastUserName: false,
      errorUserName: false
    });
    let location;
    let language = 'uk-UA';
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US'
      }
    }

    let nameValue = this.validItemEmpty('nameValue');
    let userLastNameValue = this.validItemEmpty('userLastNameValue');
    let userNameValue = this.validItemEmpty('userNameValue');
    let loginValue = this.state.loginValue.trim();
    if(nameValue === '' || !this.state.employeesValue || !this.state.typeValue){
      error = true;
      this.setState({
        errorFields: true
      });
    }
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true, errorFields: true});
    }
    if(/^\s*$/.test(userLastNameValue) && userLastNameValue !== ''){
      error = true;
      this.setState({errorUserLastNameOnlySpace: true, errorFields: true});
    }
    if(/^\s*$/.test(userNameValue) && userNameValue !== ''){
      error = true;
      this.setState({errorUserNameOnlySpace: true, errorFields: true});
    }
    const nameRegex = /^[a-zA-Zа-яА-ЯёЁіІїЇґҐ]+(?:[-—][a-zA-Zа-яА-ЯёЁіІїЇґҐ]+)*$/;
    if (!nameRegex.test(userLastNameValue) && userLastNameValue !== '') {
        error = true;
        this.setState({
          errorLastUserName: true,
          errorFields: true
        });
    }
    if (!nameRegex.test(userNameValue) && userNameValue !== '') {
        error = true;
        this.setState({
          errorUserName: true,
          errorFields: true
        });
    }
    if(!error && nameValue !== '' && this.state.employeesValue && this.state.typeValue && loginValue && validateEmail(loginValue) && userLastNameValue && userNameValue && this.state.passwordValue && this.state.rePasswordValue && validatePassword(this.state.passwordValue) && ((this.state.passwordValue && this.state.rePasswordValue) && (this.state.passwordValue === this.state.rePasswordValue))) {
      this.setState({
        loading: true
      });
      let obj = {
        "companyName": this.state.nameValue,
        "companyType": this.state.typeValue,
        "email": this.state.loginValue,
        "employeeCountType": this.state.employeesValue,
        "lastName": this.state.userLastNameValue,
        "name": this.state.userNameValue,
        "password": this.state.passwordValue,
        "passwordConfirmation": this.state.rePasswordValue,
        "timeZone": this.state.timeZone
      };
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Accept-Language': language
      });
      fetch(`${that.props.queryLink}/api/v1/companies/registration`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(obj)
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
          that.setState({
            loading: false
          });
        } else {
          that.setState({
            errors: null
          });

        const auth = getAuth();
        signInWithEmailAndPassword(auth, that.state.loginValue, that.state.passwordValue)
          .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            const myHeaders = new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.accessToken
            });
            fetch(`${that.props.queryLink}/api/v1/users/me`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
                that.setState({
                  login: true,
                  loading: false,
                  success: true
                });
            })

          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            this.setState({
              loading: false
            });
          });

        }
      })
    } else {
      this.setState({
        errorFields: true
      });
    }
    if(this.state.loginValue && !validateEmail(this.state.loginValue)) {
      this.setState({
        errorEmail: true
      });
    }
    if((this.state.passwordValue && this.state.rePasswordValue) && (this.state.passwordValue !== this.state.rePasswordValue)) {
      this.setState({
        errorPasswordConfirm: true
      });
    }
    if(this.state.passwordValue && !validatePassword(this.state.passwordValue)){
      this.setState({
        errorPasswordLattin: true
      });
    }
    if(this.state.passwordValue && this.state.passwordValue.length < 6){
      this.setState({
        errorPasswordLength: true
      });
    }
  }
  handlerRegisterTab (event, stateTab) {
    let error = false;
    if(stateTab === true){
      this.setState({
        errorFields: false,
        errorNameValue: false
      });
      let nameValue = this.validItemEmpty('nameValue');
      if(/^\s*$/.test(nameValue) && nameValue !== ''){
        error = true;
        this.setState({errorNameOnlySpace: true, errorFields: true});
      }
      if(nameValue !== '' && this.state.employeesValue && this.state.typeValue) {
        if(validateName(nameValue)){
          this.setState({
            registerTab: true
          });
        } else {
          this.setState({
            errorNameValue: true
          });
        }
      } else {
        this.setState({
          errorFields: true
        });
      }
    } else {
      this.setState({
        registerTab: stateTab
      });
    }
  }

  handlerContinueRegister (event) {
    let error = false;
    this.setState({
      errorFields: false,
      errorNameValue: false
    });
    let nameValue = this.validItemEmpty('nameValue');
    if(nameValue === '' || !this.state.employeesValue || !this.state.typeValue){
      error = true;
      this.setState({
        errorFields: true
      });
    }
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true, errorFields: true});
    }
    if(!validateName(nameValue)){
      error = true;
      this.setState({
        errorNameValue: true
      });
    }
    if(!error){
      this.setState({
        registerTab: true
      });
    }
  }
  handlerNameValue(event) {
    if(this.state.errorFields){
      this.setState({
        errorNameValue: false,
        errorNameOnlySpace: false
      });
    }
    if(event.target.value.length <= 254){
      this.setState({
        nameValue: event.target.value,
        inputNameLength: false
      });
    } else {
      this.setState({
        inputNameLength: true
      });
    }
  }

  handlerEmployeesValue(event){
    this.setState({
      employeesValue: event.value
    });
  }
  handlerTypeValue(event){
    this.setState({
      typeValue: event.value
    });
  }
  handlerLoginValue(event){
    if(this.state.errorEmail){
      this.setState({
        errorEmail: false
      });
    }
    this.setState({
      loginValue: event.target.value
    });
  }

  handlerLoginValue(event) {
    const inputValue = event.target.value;
    if(this.state.errorEmail){
      this.setState(prevState => ({
        errorEmail: false
      }));
    }
    if (inputValue.length <= 100) {
      this.setState(prevState => ({
        loginValue: inputValue,
        errorLengthLogin: false
      }));
    } else {
      this.setState(prevState => ({
        errorLengthLogin: true
      }));
    }
  }

  handlerPasswordValue(event) {
    const value = event.target.value;
    if(this.state.errorLengthPasswordValue){
      this.setState(prevState => ({
        errorLengthPasswordValue: false
      }));
    }
    if (value.length <= 50) {
      this.setState({
        passwordValue: value
      });
    } else {
      this.setState({
        errorLengthPasswordValue: true
      });
    }
  }

  handlerRePasswordValue(event) {
    const value = event.target.value;
    if(this.state.errorLengthRePasswordValue){
      this.setState(prevState => ({
        errorLengthRePasswordValue: false
      }));
    }
    if (value.length <= 50) {
      this.setState({
        rePasswordValue: value
      });
    } else {
      this.setState({
        errorLengthRePasswordValue: true
      });
    }
  }

  handlerUserNameValue(event) {
    const inputValue = event.target.value;
    if(this.state.errorFields){
      this.setState(prevState => ({
        errorUserName: false,
        errorUserNameOnlySpace: false
      }));
    }
    if (inputValue.length <= 20) {
      this.setState(prevState => ({
        userNameValue: inputValue,
        errorLengthUserName: false
      }));
    } else {
      this.setState(prevState => ({
        errorLengthUserName: true
      }));
    }
  }

  handlerUserLastNameValue(event) {
    const inputValue = event.target.value;
    if(this.state.errorFields){
      this.setState(prevState => ({
        errorLastUserName: false,
        errorLastUserNameOnlySpace: false
      }));
    }
    if (inputValue.length <= 30) {
      this.setState(prevState => ({
        userLastNameValue: inputValue,
        errorLengthUserLastName: false
      }));
    } else {
      this.setState(prevState => ({
        errorLengthUserLastName: true
      }));
    }
  }

  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: false
    }));
  }
  render() {
    let valuesObj = [];
    if(this.state.countValues && this.state.countValues.length > 0){
      this.state.countValues.forEach((item, i) => {
        if(item === 'TO_10'){
          valuesObj.push({ value: item, label: this.props.getTranslate('registrationCompany-employeesOne')});
        } else if(item === 'FROM_10_TO_30') {
          valuesObj.push({ value: item, label: this.props.getTranslate('registrationCompany-employeesTwo')});
        } else if(item === 'FROM_30_TO_50'){
          valuesObj.push({ value: item, label: this.props.getTranslate('registrationCompany-employeesThree')});
        } else {
          valuesObj.push({ value: item, label: this.props.getTranslate('registrationCompany-employeesFive')});
        }
      });
    }
    let title = this.props.getTranslate('registerPage-Title');
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = title ? title : 'MateSpace.io';
    }
    const employees = valuesObj;
    const type = [
      { value: 'IT_DEVELOPMENT', label: this.props.getTranslate('registrationCompany-TypeIT') },
      { value: 'DESIGN_AND_MARKETING', label: this.props.getTranslate('registrationCompany-TypeDesign') },
      { value: 'PRODUCT_COMPANY', label: this.props.getTranslate('registrationCompany-TypeProduct') },
      { value: 'STARTUP', label: this.props.getTranslate('registrationCompany-TypeStartup') },
      { value: 'OTHER', label: this.props.getTranslate('registrationCompany-TypeOther') },
    ];
    return <>
    {this.props.PageWithCanonical}
    {this.state.login && <Navigate to="/account" replace={true} />}
    {this.state.errors ? <div className="response-submit">
    {this.state.errors.map((item, index) => {
      return <div key={index} className="response-submit__item response-submit__item_error">{item}
            <button className="response-submit__close" type="button" onClick={this.handlerCloseError.bind(this)}>
            <svg><use href={`${sprite}#close-icon`}></use></svg>
            </button></div>
    })}</div>: ''}
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main>
    <section className="registration">
      <div className="wrapper">
        <div className="register-form-wgt auth-form-wgt">
          <h2 className="auth-form-wgt__headline">{this.props.getTranslate('registration-Title')}</h2>
          <nav className="register-wgt-nav">
            <button className={this.state.registerTab ? 'register-wgt-nav__btn' : 'register-wgt-nav__btn active'} type="button" onClick={this.handlerRegisterTab.bind(this, false)}>{this.props.getTranslate('registration-TabAboutCompany')}</button>
            <button className={this.state.registerTab ? 'register-wgt-nav__btn active' : 'register-wgt-nav__btn'} type="button" onClick={this.handlerRegisterTab.bind(this, true)}>{this.props.getTranslate('registration-TabPersonal')}</button>
          </nav>
          <form className="auth-form" onSubmit={this.handlerRegisterSubmit.bind(this)}>
            <div className={this.state.registerTab ? 'auth-form__content' : 'auth-form__content active'}>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="company-name">{this.props.getTranslate('registration-CompanyName')}:</label>
                <input id="company-name" className={`field-wrap__input ${(this.state.errorFields && !this.state.nameValue) || (this.state.nameValue && this.state.errorNameValue) || (this.state.errorNameOnlySpace && this.state.nameValue !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.nameValue} onChange={this.handlerNameValue.bind(this)} placeholder={this.props.getTranslate('registration-CompanyNameFieldCaption')} />
                {this.state.errorFields && !this.state.nameValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.nameValue && this.state.errorNameValue ? <div className="validation-error">{this.props.getTranslate('validation-ErrorSpecialField')}</div> : ''}
                {this.state.errorNameOnlySpace && this.state.nameValue !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label">{this.props.getTranslate('registration-CountEmployees')}:</label>
                <Select
                  styles={this.state.errorFields && !this.state.employeesValue ? customStylesError : customStyles}
                  isSearchable={false}
                  value={this.state.employeesValue ? employees[employees.findIndex(el => el.value === this.state.employeesValue)] : null}
                  options={employees}
                  onChange={this.handlerEmployeesValue.bind(this)}
                  placeholder={this.props.getTranslate('registration-CountEmployeesFieldCaption')}
                />
                {this.state.errorFields && !this.state.employeesValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="activity-name">{this.props.getTranslate('registration-CompanyType')}:</label>
                <Select
                  styles={this.state.errorFields && !this.state.typeValue ? customStylesError : customStyles}
                  isSearchable={false}
                  value={this.state.typeValue ? type[type.findIndex(el => el.value === this.state.typeValue)] : null}
                  options={type}
                  onChange={this.handlerTypeValue.bind(this)}
                  placeholder={this.props.getTranslate('registration-CompanyTypeFieldCaption')}
                />
                {this.state.errorFields && !this.state.typeValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
            </div>
            <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100" type="button" onClick={this.handlerContinueRegister.bind(this)}>{this.props.getTranslate('registration-RegistrationButtonContinue')}</button>
            </div>
            </div>
            <div className={this.state.registerTab ? 'auth-form__content active' : 'auth-form__content'}>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-login">{this.props.getTranslate('registration-Email')}:</label>
                <input id="register-login" className={`field-wrap__input ${(this.state.errorFields && !this.state.loginValue) || (this.state.loginValue && this.state.errorEmail) || this.state.errorLengthLogin ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.loginValue} onChange={this.handlerLoginValue.bind(this)} placeholder={this.props.getTranslate('registration-EmailFieldCaption')} />
                {this.state.errorFields && !this.state.loginValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorEmail && this.state.loginValue ? <div className="validation-error">{this.props.getTranslate('validation-IncorrectEmail')}</div> : ''}
                {this.state.errorLengthLogin ? <div className="validation-error">{this.props.getTranslate('validation-ErrorLengthLoginEmail')}</div> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-pass">{this.props.getTranslate('registration-Password')}:</label>
                <div className="field-wrap__inner">
                  <input id="register-pass" className={`field-wrap__input field-wrap__input-icon ${this.state.errorFields && !this.state.passwordValue || this.state.errorLengthPasswordValue || (this.state.errorPasswordConfirm && (this.state.passwordValue && this.state.rePasswordValue) || (this.state.errorPasswordLattin && this.state.passwordValue))  ? 'field-wrap__input_error' : ''}`} type={this.state.passwordHide ? 'password' : 'text'} value={this.state.passwordValue} onChange={this.handlerPasswordValue.bind(this)} placeholder={this.props.getTranslate('registration-PasswordFieldCaption')} />
                  <div className="field-wrap__hide-btn" onClick={this.handlerPassword.bind(this)}>
                    <svg className="field-wrap__hide-icon">
                      <use href={this.state.passwordHide ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                    </svg>
                  </div>
                </div>
                {this.state.errorFields && !this.state.passwordValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorPasswordConfirm && (this.state.passwordValue && this.state.rePasswordValue) ? <div className="validation-error">{this.props.getTranslate('validation-PasswordsNotMatch')}</div> : ''}
                {this.state.errorPasswordLattin && this.state.passwordValue ? <div className="validation-error">{this.props.getTranslate('validation-PasswordsOnlyLattin')}</div> : ''}
                {this.state.errorPasswordLength && this.state.passwordValue ? <div className="validation-error">{this.props.getTranslate('validation-PasswordsLength')}</div> : ''}
                {this.state.errorLengthPasswordValue ? <div className="validation-error">{this.props.getTranslate('validation-PasswordsLengthErrorValue')}</div> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-repass">{this.props.getTranslate('registration-RepeatPassword')}:</label>
                <div className="field-wrap__inner">
                  <input id="register-repass" className={`field-wrap__input field-wrap__input-icon ${(this.state.errorFields && !this.state.rePasswordValue || this.state.errorLengthRePasswordValue || (this.state.errorPasswordConfirm && (this.state.passwordValue && this.state.rePasswordValue))) ? 'field-wrap__input_error' : ''}`} type={this.state.repasswordHide ? 'password' : 'text'} value={this.state.rePasswordValue} onChange={this.handlerRePasswordValue.bind(this)} placeholder={this.props.getTranslate('registration-RepeatPasswordFieldCaption')} />
                  <div className="field-wrap__hide-btn" onClick={this.handlerRepassword.bind(this)}>
                    <svg className="field-wrap__hide-icon">
                      <use href={this.state.repasswordHide ? `${sprite}#hide-pass` : `${sprite}#view-pass`}></use>
                    </svg>
                  </div>
                </div>
                {this.state.errorFields && !this.state.rePasswordValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorPasswordConfirm && (this.state.passwordValue && this.state.rePasswordValue) ? <div className="validation-error">{this.props.getTranslate('validation-PasswordsNotMatch')}</div> : ''}
                {this.state.errorLengthRePasswordValue ? <div className="validation-error">{this.props.getTranslate('validation-PasswordsLengthErrorValue')}</div> : ''}
              </div>
              <div className="register-form__sep"></div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-name">{this.props.getTranslate('registration-Name')}:</label>
                <input id="register-name" className={`field-wrap__input ${(this.state.errorFields && !this.state.userNameValue) || (this.state.errorUserNameOnlySpace && this.state.userNameValue !== '') || this.state.errorUserName || this.state.errorLengthUserName ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.userNameValue} onChange={this.handlerUserNameValue.bind(this)} placeholder={this.props.getTranslate('registration-NameFieldCaption')} />
                {this.state.errorFields && !this.state.userNameValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorUserNameOnlySpace && this.state.userNameValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpacesField')}</div> : ''}
                {this.state.errorUserName ? <div className="validation-error">{this.props.getTranslate('validation-UserNameRegisterValidation')}</div> : ''}
                {this.state.errorLengthUserName ? <div className="validation-error">{this.props.getTranslate('validation-ErrorLengthLoginUserName')}</div> : ''}
              </div>
              <div className="field-wrap">
                <label className="field-wrap__label" htmlFor="register-surname">{this.props.getTranslate('registration-Surname')}:</label>
                <input id="register-surname" className={`field-wrap__input ${(this.state.errorFields && !this.state.userLastNameValue) || (this.state.errorLastNameOnlySpace && this.state.userLastNameValue !== '') || this.state.errorLastUserName || this.state.errorLengthUserLastName ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.userLastNameValue} onChange={this.handlerUserLastNameValue.bind(this)} placeholder={this.props.getTranslate('registration-SurnameFieldCaption')} />
                {this.state.errorFields && !this.state.userLastNameValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorUserLastNameOnlySpace && this.state.errorUserLastNameOnlySpace !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpacesField')}</div> : ''}
                {this.state.errorLastUserName ? <div className="validation-error">{this.props.getTranslate('validation-UserLastNameRegisterValidation')}</div> : ''}
                {this.state.errorLengthUserLastName ? <div className="validation-error">{this.props.getTranslate('validation-ErrorLengthLoginUserLastName')}</div> : ''}
              </div>
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100" type="submit" disabled={this.state.loading ? true : false}>{this.props.getTranslate('registration-RegistrationButton')}{this.state.loading && <Loading />}</button>
              </div>
            </div>
          </form>
          <div className="register-form__login">
            <span className="register-form__login-label">{this.props.getTranslate('registration-BottomText')}</span>
            <Link className="register-form__login-link" to={this.props.getPermalink('/login')}>{this.props.getTranslate('registration-BottomTextLogin')}</Link>
          </div>
        </div>
      </div>
    </section>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default Registration;
