import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import Loading from './components/Loading';
import NoteComment from './components/NoteComment';
import TabItems from './components/TabItems';
import Table from './components/Table';
import AccountContentHeader from './components/AccountContentHeader';
import Button from './components/Button';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import Modal from './components/Modal';
import Pagination from './components/Pagination';
import { getAuth } from "firebase/auth";
class Shops extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      modalClass: null,
      modalDisabled: false,
      modalAddShop: false,
      modalEditShop: false,
      modalAddComment: false,
      controlRow: null,
      statusModalShop: 'add',
      statusModalComment: 'add',
      totalItemsCategories: null,
      shops: [],
      totalPages: null,
      currentPage: 0,
      shopDescription: '',
      shopName: '',
      shopEnable: null,
      shopID: null,
      indexEditShop: null,
      loadingAddEditShop: false,
      indexRowDelete: null,
      configsData: null,
      configDescription: '',
      modalRemoveShop: false,
      idDeleteShop: null,
      modalRemoveConfig: false,
      idDeleteConfig: null,
      successSubmit: false,
      successText: '',
      modalSubmitStatus: false,
      objShopEdit: null,
      errorFields: false,
      errorCommentOnlySpace: false,
      errorShopNameOnlySpace: false,
      errorShopDescriptionOnlySpace: false,
      errorLengthNameShop: false
    }
  }

  /* Handler for get mobile menu */
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };

  /* Handler for dropdown */
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }

  /* Handler get data mount component */
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/shops/search`, {
        method: 'POST',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({
          "itemsPerPage": 20,
          "orderField": "creationDate",
          "direction": 'DESC'
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.errors,
              error: true
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              shops: data.data,
              totalPages: data.totalPages,
              totalItems: data.totalItems
            })
          })

          fetch(`${that.props.queryLink}/api/v1/shops/global/configs`, {
            method: 'GET',
            headers: that.props.queryHeaders(idToken)
          }).then(res => {
            if(res.status !== 200) {
              that.setState({
                isLoaded: true
              });
            } else {
              res.text().then(function(data) {
                if(data){
                  that.setState({
                    isLoaded: true,
                    configsData: JSON.parse(data)
                  });
                } else {
                  that.setState({
                    isLoaded: true,
                    configsData: null
                  });
                }
              })
            }
          })
          fetch(`${that.props.queryLink}/api/v1/products/categories/search`, {
            method: 'POST',
            headers: that.props.queryHeaders(idToken),
            body: JSON.stringify({
              "itemsPerPage": 20,
              "orderField": "creationDate",
              "direction": 'DESC'
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  errors: data.errors,
                  error: true
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  totalItemsCategories: data.totalItems
                });
              })
            }
        })
        }
      })
    })
  }

  /* Restore modal data */
  handlerRestoreModalData(event){
    setTimeout(() => {
      this.setState({
        modalAddShop: false,
        modalRemoveShop: false,
        modalEditShop: false,
        modalSubmitStatus: false,
        modalAddComment: false,
        modalEditComment: false,
        modalRemoveConfig: false,
        configDescription: '',
        shopDescription: '',
        shopName: '',
        errorCommentOnlySpace: false,
        errorFields: false,
        errorShopNameOnlySpace: false,
        errorShopDescriptionOnlySpace: false,
        errorLengthNameShop: false
      })
    }, 510);
  }

  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }
    return itemValue;
  }

  validItemEmptyInfo = (data, item) => {
    let itemData = { ...this.state[data] };
    let itemValue = itemData[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }
    return itemValue;
  };

  /* Handler for open "Add modal" */
  handlerAddShop(event) {
    this.setState(prevState => ({
      modalAddShop: !prevState.modalAddShop
    }));
  }

  /* Handler for close "Add modal" */
  handlerCloseAddShop(event) {
    this.setState(prevState => ({
      modalAddShop: false,
      modalEditShop: false
    }));
  }

  /* Shop add handler for submit */
  handlerAddShopSubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    let shopName = that.validItemEmpty('shopName');
    if(/^\s*$/.test(shopName) && shopName !== ''){
      error = true;
      this.setState({errorShopNameOnlySpace: true });
    }
    let shopDescription = that.validItemEmpty('shopDescription');
    if(/^\s*$/.test(shopDescription) && shopDescription !== ''){
      error = true;
      this.setState({errorShopDescriptionOnlySpace: true });
    }
    if(!error && shopName !== '') {
    that.setState({
      loadingAddEditShop: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    fetch(`${that.props.queryLink}/api/v1/shops`, {
      method: 'POST',
      headers: that.props.queryHeaders(idToken),
      body: JSON.stringify({
        "description": that.state.shopDescription,
        "shopName": that.state.shopName,
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            loadingAddEditShop: false
          });
        })
        setTimeout(() => {
          that.setState({
            errorSubmit: false,
            successSubmit: false
          });
        }, 5000);
      } else {
        res.json().then(function(data) {
          that.setState(prevState => ({
            shops: [data, ...prevState.shops]
          }))
        })
          that.setState(prevState => ({
            loadingAddEditShop: false,
            successSubmit: true,
            successText: that.props.getTranslate('successStatus-Created'),
            modalSubmitStatus: true,
          }));
        that.handlerRestoreModalData();
        setTimeout(() => {
          that.setState({
            errorSubmit: false,
            successSubmit: false
          });
        }, 5000);

        fetch(`${that.props.queryLink}/api/v1/shops/search`, {
          method: 'POST',
          headers: that.props.queryHeaders(idToken),
          body: JSON.stringify({
            "itemsPerPage": 20,
            "orderField": "creationDate",
            "direction": 'DESC'
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoaded: true,
                errors: data.errors,
                error: true
              });
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                shops: data.data,
                totalPages: data.totalPages,
                totalItems: data.totalItems
              })
            })
            }
            })


      }
    })
  })
  } else {
    that.setState({
      errorFields: true
    });
  }
}

  /* Shop handler get data and open modal */
  handlerEditShop(index, id, event) {
    this.setState(prevState => ({
      modalEditShop: !prevState.modalEditShop,
      indexEditShop: index
    }));
      let that = this;
      getAuth().currentUser.getIdToken().then(function(idToken){
        fetch(`${that.props.queryLink}/api/v1/shops/${id}`, {
          method: 'GET',
          headers: that.props.queryHeaders(idToken),
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
            setTimeout(() => {
              that.setState({
                errorSubmit: false,
                successSubmit: false
              });
            }, 5000);
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                objShopEdit: data
              }));
            });
            setTimeout(() => {
              that.setState({
                errorSubmit: false,
                successSubmit: false
              });
            }, 5000);
          }
        })
      })
  }

  /* Shop edit handler for submit */
  handlerEditShopSubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    let shopName = that.validItemEmptyInfo('objShopEdit', 'shopName');
    if(/^\s*$/.test(shopName) && shopName !== ''){
      error = true;
      this.setState({errorShopNameOnlySpace: true });
    }
    let shopDescription = that.validItemEmptyInfo('objShopEdit', 'description');
    if(/^\s*$/.test(shopDescription) && shopDescription !== ''){
      error = true;
      this.setState({errorShopDescriptionOnlySpace: true });
    }
    if(!error && shopName !== '') {
    that.setState({
      loadingAddEditShop: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    fetch(`${that.props.queryLink}/api/v1/shops/${that.state.objShopEdit.shopID}`, {
      method: 'PUT',
      headers: that.props.queryHeaders(idToken),
      body: JSON.stringify(that.state.objShopEdit)
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            loadingAddEditShop: false
          });
        })
        setTimeout(() => {
          that.setState({
            errorSubmit: false,
            successSubmit: false
          });
        }, 5000);
      } else {
        let dataShops = that.state.shops;
        let index = dataShops.findIndex(el => el.shopID === that.state.objShopEdit.shopID);
        res.json().then(function(data) {
          dataShops[index] = data;
        });
        that.setState({
          successSubmit: true,
          successText: that.props.getTranslate('successStatus-Edit'),
          modalSubmitStatus: true,
          shops: dataShops,
          loadingAddEditShop: false,
          editShopObj: null
        });
        that.handlerRestoreModalData();
        setTimeout(() => {
          that.setState({
            errorSubmit: false,
            successSubmit: false
          });
        }, 5000);
      }
    })
  })
} else {
  that.setState({
    errorFields: true
  });
}
  }

  /* Shop change status handler for submit */
  handlerEnabledShop(id, index, event){
    let that = this;
    that.setState({
      loadingAddEditShop: true
    });
    let arr = that.state.shops;
    getAuth().currentUser.getIdToken().then(function(idToken){
    fetch(`${that.props.queryLink}/api/v1/shops/${id}/enable`, {
      method: 'POST',
      headers: that.props.queryHeaders(idToken),
      body: JSON.stringify({
        "enable":!arr[index].enable,
        "shopID": id
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
        setTimeout(() => {
          that.setState({
            errorSubmit: false,
            successSubmit: false,
            loadingAddEditShop: false
          });
        }, 5000);
      } else {
        arr[index].enable = !arr[index].enable;
        that.setState(prevState => ({
          shops: arr,
          loadingAddEditShop: false
        }))
        setTimeout(() => {
          that.setState({
            errorSubmit: false,
            successSubmit: false
          });
        }, 5000);
      }
    })
  })
  }

  /* Handler for open "Remove shop modal" */
  handlerModalRemoveShop(id, index, event){
    this.setState({
      modalRemoveShop: true,
      removeIndex: index,
      idDeleteShop: id
    })
  }

  /* Handler for close "Delete shop modal" */
  handlerCloseDeleteModal(event){
    this.setState({
      modalRemoveShop: false,
      removeIndex: null,
      idDeleteShop: null
    });
  }

  /* Shop delete handler for submit */
  handlerDeleteShop(event){
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/shops/${that.state.idDeleteShop}`, {
        method: 'DELETE',
        headers: that.props.queryHeaders(idToken)
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              loadingDeleteSumbit: false,
              indexRowDelete: null
            });
          });
          setTimeout(() => {
            that.setState({
              errorSubmit: false,
              successSubmit: false
            });
          }, 5000);
        } else {
          that.setState(prevState => ({
              successSubmit: true,
              successText: that.props.getTranslate('successStatus-Delete'),
              modalSubmitStatus: true,
              loadingDeleteSumbit: false,
              indexRowDelete: null,
              totalItems: prevState.totalItems - 1
          }));
          that.handlerRestoreModalData();
          setTimeout(() => {
            that.setState({
              errorSubmit: false,
              successSubmit: false
            });
          }, 5000);
      fetch(`${that.props.queryLink}/api/v1/shops/search`, {
        method: 'POST',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({
          "itemsPerPage": 20,
          "orderField": "creationDate",
          "direction": 'DESC'
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.errors,
              error: true
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              shops: data.data,
              totalPages: data.totalPages,
              totalItems: data.totalItems
            })
          })

        }
      })

        }
      })
    })
  }

  /* Config shop open modal */
  handlerCommentShop(status, event) {
    this.setState(prevState => ({
      statusModalComment: status
    }));
    if(status === 'edit'){
      this.setState(prevState => ({
        configDescription: prevState.configsData.description,
        modalEditComment: !prevState.modalEditComment,
      }));
    } else {
      this.setState(prevState => ({
        modalAddComment: !prevState.modalAddComment
      }))
    }
  }

  /* Config shop add handler for submit */
  handlerAddCommentSubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    that.setState({
      errorFields: false,
      errorCommentOnlySpace: false
    });
    let configDescription = that.validItemEmpty('configDescription');
    if(/^\s*$/.test(configDescription) && configDescription !== ''){
      error = true;
      this.setState({errorCommentOnlySpace: true });
    }
    if(!error && configDescription !== '') {
    that.setState({
      loadingAddEditComment: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/shops/global/configs`, {
        method: 'POST',
        headers: that.props.queryHeaders(idToken),
        body: JSON.stringify({
          "description": configDescription
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
          that.setState({
            loadingAddEditComment: false
          });
          setTimeout(() => {
            that.setState({
              errorSubmit: false,
              successSubmit: false
            });
          }, 5000);
        } else {
          res.json().then(function(data) {
            that.setState({
              successSubmit: true,
              successText: that.props.getTranslate('successStatus-Created'),
              modalSubmitStatus: true,
              loadingAddEditComment: false,
              configsData: data
            });
          })
          that.handlerRestoreModalData();
          setTimeout(() => {
            that.setState({
              errorSubmit: false,
              successSubmit: false
            });
          }, 5000);
        }
      })
    })
    } else {
      that.setState({
        errorFields: true
      });
    }
  }

  /* Config shop edit handler for submit */
  handlerEditCommentSubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    that.setState({
      errorFields: false,
      errorCommentOnlySpace: false
    });
    let configDescription = that.validItemEmpty('configDescription');
    if(/^\s*$/.test(configDescription) && configDescription !== ''){
      error = true;
      this.setState({errorCommentOnlySpace: true });
    }
    if(!error && configDescription !== '') {
    that.setState({
      loadingAddEditComment: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    fetch(`${that.props.queryLink}/api/v1/shops/global/configs/${that.state.configsData.customShopConfigID}`, {
      method: 'PUT',
      headers: that.props.queryHeaders(idToken),
      body: JSON.stringify({
        "customShopConfigID": that.state.configsData.customShopConfigID,
        "description": that.state.configDescription
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          });
        })
        that.setState({
          loadingAddEditComment: false
        });
        setTimeout(() => {
          that.setState({
            errorSubmit: false,
            successSubmit: false
          });
        }, 5000);
      } else {
        res.json().then(function(data) {
          that.setState({
            loadingAddEditComment: false,
            successSubmit: true,
            successText: that.props.getTranslate('successStatus-Edit'),
            modalSubmitStatus: true,
            configsData: data
          });
        });
        that.handlerRestoreModalData();
        setTimeout(() => {
          that.setState({
            errorSubmit: false,
            successSubmit: false
          });
        }, 5000);
      }
    })
  })
} else {
  that.setState({
    errorFields: true
  });
}
  }

  /* Config shop delete handler for submit */
  handlerDeleteConfig(event){
    event.preventDefault();
    let that = this;
    that.setState({
      loadingRemoveComment: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      fetch(`${that.props.queryLink}/api/v1/shops/global/configs/${that.state.configsData.customShopConfigID}`, {
        method: 'DELETE',
        headers: that.props.queryHeaders(idToken),
      }).then(res => {
        if(res.status !== 200) {
          that.setState({
            loadingRemoveComment: false
          });
          setTimeout(() => {
            that.setState({
              errorSubmit: false,
              successSubmit: false
            });
          }, 5000);
        } else {
          that.setState({
            configsData: null,
            successSubmit: true,
            successText: that.props.getTranslate('successStatus-Delete'),
            modalSubmitStatus: true,
            loadingRemoveComment: false
          });
          that.handlerRestoreModalData();
          setTimeout(() => {
            that.setState({
              errorSubmit: false,
              successSubmit: false
            });
          }, 5000);
        }
      })
    })
  }

  /* Add shop name handler */
  handlerShopNameValue(event){
    const value = event.target.value;
    if (value.length <= 50) {
      if(this.state.errorLengthNameShop){
        this.setState({
            errorLengthNameShop: false
        });
      }
        this.setState({
            shopName: value
        });
    } else {
      this.setState({
          errorLengthNameShop: true
      });
    }
  }

  /* Add shop description handler */
  handlerShopDescriptionValue(event){
    this.setState({
      shopDescription: event.target.value
    });
  }

  /* Edit shop name handler */
  handlerEditShopNameValue(event){
    const value = event.target.value;
    if (value.length <= 50) {
      if(this.state.errorLengthNameShop){
        this.setState({
            errorLengthNameShop: false
        });
      }
      let arr = this.state.objShopEdit;
      arr.shopName = event.target.value;
      this.setState({
        objShopEdit: arr
      })
    } else {
      this.setState({
          errorLengthNameShop: true
      });
    }


  }

  /* Edit shop description handler */
  handlerEditShopDescriptionValue(event){
    let arr = this.state.objShopEdit;
    arr.description = event.target.value;
    this.setState({
      objShopEdit: arr
    })
  }

  /* Edit config description handler */
  handlerConfigDescriptionValue(event){
    const value = event.target.value;
    if (value.length <= 250) {
      if(this.state.errorLengthConfigDescription){
        this.setState({
            errorLengthConfigDescription: false
        });
      }
        this.setState({
            configDescription: value
        });
    } else {
      this.setState({
          errorLengthConfigDescription: true
      });
    }
  }

  /* Close handler config "Add modal" */
  handlerCloseConfigModal(event){
    this.setState({
      modalAddComment: false
    });
  }

  /* Close handler config "Edit modal" */
  handlerCloseConfigEditModal(event){
    this.setState({
      modalRemoveConfig: false
    })
  }

  /* Close handler config "Delete modal" */
  handlerModalRemoveConfig(event){
    this.setState({
      modalRemoveConfig: true
    })
  }

  /* Pagination handler */
  handlerPaginationItems = (categoriesItems, current) => {
    this.setState({
      shops: categoriesItems,
      currentPage: current
    });
  }

  /* Handler for close success labels */
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSubmit: false
    }));
  }

  /* Handler for close errors labels */
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }

  /* Render component */
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('catalog-Title') + ' | ' + this.props.companyName;
    }
    let resultShops = '';
    if(this.state.error) {
      resultShops = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
    } else if(!this.state.isLoaded) {
      resultShops = <Loading />;
    } else {
      resultShops = <>

        {/* Tabs component */}
        <TabItems items={[
          {
            label: this.props.getTranslate('tabItem-ItemProducts'),
            path: this.props.getPermalink('/account/products'),
            amount: this.state.totalItemsCategories
          },
          {
            label: this.props.getTranslate('tabItem-ItemShops'),
            path: this.props.getPermalink('/account/shops'),
            amount: this.state.totalItems
          }
        ]} />
          <div className={`team-nav ${this.state.configsData ? 'team-nav__shop' : ''}`}>
          {/* Button for add shop */}
          <Button
            type="button"
            icon={true}
            iconItem="#plus-icon"
            text={this.props.getTranslate('shop-AddShopButton')}
            addClass="header-content-nav__btn header-content-nav__btn-add-shop"
            handler={this.handlerAddShop.bind(this)}
          />
              <NoteComment
                data={this.state.configsData}
                title={this.props.getTranslate('shops-NoteCommentTitle')}
                description={this.state.configsData ? this.state.configsData.description : ''}
                btn={this.props.getTranslate('shops-NoteCommentBtn') ? this.props.getTranslate('shops-NoteCommentBtn') : 'shops-NoteCommentBtn'}
                handlerAddNote={this.handlerCommentShop.bind(this, 'add')}
                handlerEditNote={this.handlerCommentShop.bind(this, 'edit')}
                handlerDeleteNote={this.handlerModalRemoveConfig.bind(this)}
              />
            </div>
          <div className="account-wgt account-wgt__catalog">

          {/* Table component */}
          <Table
            header={[
              this.props.getTranslate('Shops-NameShop'),
              ''
            ]}
            bodyData={this.state.shops}
            bodyDataNames={['shopName']}
            controlItemsStatus={true}
            controlItems={{
              'enabled': {'status': true, 'handler': this.handlerEnabledShop.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusLabel'), 'show': this.props.getTranslate('tableDropdown-StatusLabelShow')}},
              'editButton': {'status': true, 'handler': this.handlerEditShop.bind(this), 'label': this.props.getTranslate('tableDropdown-EditLabel')},
              'editLink': false,
              'delete': {'status': true, 'handler': this.handlerModalRemoveShop.bind(this), 'label': this.props.getTranslate('tableDropdown-EditRemove')}
            }}
            enableTooltipMessage={this.props.getTranslate('tableEnable-TooltipMessage')}
            itemIDName={'shopID'}
            itemEnableName={'enable'}
            removeIndex={this.state.removeIndex}
          />

          </div></>
    }
    return <>

    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Query result handler */}
    <ResultQuery
      success={this.state.successSubmit}
      resultText={this.state.successText}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    />

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            title={this.props.getTranslate('catalog-Title')}
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
          />

            <div className="account-content__content">

              {/* Query result */}
              {resultShops}

            </div>
              <div className="account-content__footer">

                {/* Pagination component */}
                <Pagination
                  total={this.state.totalPages}
                  perPage="20"
                  path={`${this.props.queryLink}/api/v1/shops/search`}
                  currentPage={this.state.currentPage}
                  otherParams={{"orderField": 'creationDate', "direction": 'DESC'}}
                  items={this.handlerPaginationItems}
                />

              </div>
        </div>
      </div>
    </div>

    {
      /* Modal all components */
      /* Modal Add Shop */
    }
    {this.state.modalAddShop ? <Modal
      title={this.props.getTranslate('shopModal-AddShopTitle')}
      content={<><div className="field-wrap">
          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="name">{this.props.getTranslate('shopModal-nameShopLabel')}</label>
          <input id="name" className={`field-wrap__input ${this.state.errorLengthNameShop || (this.state.errorFields && !this.state.shopName) || (this.state.errorFields && this.state.errorShopNameOnlySpace && this.state.shopName !== '') ? 'field-wrap__input_error' : ''}`} placeholder={this.props.getTranslate('shopModal-descriptionShopPlaceholder')} type="text" placeholder={this.props.getTranslate('shopModal-nameShopPlaceholder')} value={this.state.shopName} onChange={this.handlerShopNameValue.bind(this)} />
          {this.state.errorFields && !this.state.shopName ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
          {this.state.errorFields && this.state.errorShopNameOnlySpace && this.state.shopName !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
          {this.state.errorLengthNameShop ? <div className="validation-error">{this.props.getTranslate('validation-LengthNameShop')}</div> : ''}
        </div>
        <div className="field-wrap">
          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="comment">{this.props.getTranslate('shopModal-descriptionShopLabel')}</label>
          <textarea className={`field-wrap__input field-wrap__input_textarea ${(this.state.errorFields && this.state.errorShopDescriptionOnlySpace && this.state.shopDescription !== '') ? 'field-wrap__input_error' : ''}`} placeholder={this.props.getTranslate('shopModal-descriptionShopPlaceholder')} value={this.state.shopDescription} onChange={this.handlerShopDescriptionValue.bind(this)}></textarea>
          {this.state.errorFields && this.state.errorShopDescriptionOnlySpace && this.state.shopDescription !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
        </div></>}
      type={'formSubmit'}
      buttonSubmitText={this.props.getTranslate('shopModal-addSubmitShopButton')}
      submitHandler={this.handlerAddShopSubmit.bind(this)}
      submitLoading={this.state.loadingAddEditShop}
      handlerClose={this.handlerCloseAddShop.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}

  {/* Modal Edit Shop */}
  {this.state.modalEditShop ? <Modal
    title={this.props.getTranslate('shopModal-EditShopTitle')}
    content={<><div className="field-wrap">
              <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="name">{this.props.getTranslate('shopModal-nameShopLabel')}</label>
              <input id="name" className={`field-wrap__input ${this.state.errorLengthNameShop || (this.state.errorFields && !this.state.objShopEdit.shopName) || (this.state.errorFields && this.state.errorShopNameOnlySpace && this.state.objShopEdit.shopName !== '') ? 'field-wrap__input_error' : ''}`} type="text" placeholder={this.props.getTranslate('shopModal-nameShopPlaceholder')} value={this.state.objShopEdit ? this.state.objShopEdit.shopName : ''} onChange={this.handlerEditShopNameValue.bind(this)} />
              {this.state.errorFields && !this.state.objShopEdit.shopName ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
              {this.state.errorFields && this.state.errorShopNameOnlySpace && this.state.objShopEdit.shopName !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
              {this.state.errorLengthNameShop ? <div className="validation-error">{this.props.getTranslate('validation-LengthNameShop')}</div> : ''}
            </div>
            <div className="field-wrap">
              <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="comment">{this.props.getTranslate('shopModal-descriptionShopLabel')}</label>
              <textarea className={`field-wrap__input field-wrap__input_textarea ${(this.state.errorFields && this.state.errorShopDescriptionOnlySpace && this.state.objShopEdit.description !== '') ? 'field-wrap__input_error' : ''}`} placeholder={this.props.getTranslate('shopModal-descriptionShopPlaceholder')} value={this.state.objShopEdit ? this.state.objShopEdit.description : ''} onChange={this.handlerEditShopDescriptionValue.bind(this)}></textarea>
              {this.state.errorFields && this.state.errorShopDescriptionOnlySpace && this.state.objShopEdit.description !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
            </div></>}
    type={'formSubmit'}
    buttonSubmitText={this.props.getTranslate('shopModal-editSubmitShopButton')}
    submitHandler={this.handlerEditShopSubmit.bind(this)}
    submitLoading={this.state.loadingAddEditShop}
    handlerClose={this.handlerCloseAddShop.bind(this)}
    submitStatus={this.state.modalSubmitStatus}
  /> : ''}

    {/* Modal Delete Shop */}
    {this.state.modalRemoveShop ? <Modal
      title={this.props.getTranslate('shopModal-RemoveShopTitle')}
      content={<div className="delete-category-form__description">{this.props.getTranslate('shopModalDelete-commentDelete') ? this.props.getTranslate('shopModalDelete-commentDelete') : 'shopModalDelete-commentDelete'}</div>}
      type={'delete'}
      buttonSubmitText={this.props.getTranslate('shopModal-removeSubmitShopButton')}
      submitHandler={this.handlerDeleteShop.bind(this)}
      submitLoading={this.state.loadingDeleteSumbit}
      handlerClose={this.handlerCloseDeleteModal.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}

    {/* Modal Add Config */}
    {this.state.modalAddComment ? <Modal
      title={this.props.getTranslate('shopModal-AddCommentTitle')}
      content={<>
        <div className="modal__description">
          <p>{this.props.getTranslate('shopModal-AddCommentDescription')}</p>
        </div>
        <div className="field-wrap">
          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="comment">{this.props.getTranslate('shopModal-commentLabel')}</label>
          <textarea className={`field-wrap__input field-wrap__input_textarea ${(this.state.errorFields && !this.state.configDescription) || (this.state.errorFields && this.state.errorCommentOnlySpace && this.state.configDescription !== '') || this.state.errorLengthConfigDescription ? 'field-wrap__input_error' : ''}`} value={this.state.configDescription} onChange={this.handlerConfigDescriptionValue.bind(this)} placeholder={this.props.getTranslate('shopModal-commentPlaceholder')} />
          {this.state.errorFields && !this.state.configDescription ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
          {this.state.errorFields && this.state.errorCommentOnlySpace && this.state.configDescription !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
          {this.state.errorLengthConfigDescription ? <div className="validation-error">{this.props.getTranslate('validation-LengthConfigDescription')}</div> : ''}
        </div>
        </>}
      type={'formSubmit'}
      buttonSubmitText={this.props.getTranslate('shopModal-addSubmitCommentButton')}
      submitHandler={this.handlerAddCommentSubmit.bind(this)}
      submitLoading={this.state.loadingAddEditComment}
      handlerClose={this.handlerCloseConfigModal.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}

    {/* Modal Edit Config */}
    {this.state.modalEditComment ? <Modal
      title={this.props.getTranslate('shopModal-EditCommentTitle')}
      content={<>
        <div className="modal__description">
          <p>{this.props.getTranslate('shopModal-AddCommentDescription')}</p>
        </div>
        <div className="field-wrap">
          <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="comment">{this.props.getTranslate('shopModal-commentLabel')}</label>
          <textarea className={`field-wrap__input field-wrap__input_textarea ${(this.state.errorFields && !this.state.configDescription) || (this.state.errorFields && this.state.errorCommentOnlySpace && this.state.configDescription !== '') || this.state.errorLengthConfigDescription ? 'field-wrap__input_error' : ''}`} value={this.state.configDescription} onChange={this.handlerConfigDescriptionValue.bind(this)} placeholder={this.props.getTranslate('shopModal-commentPlaceholder')} />
          {this.state.errorFields && !this.state.configDescription ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
          {this.state.errorFields && this.state.errorCommentOnlySpace && this.state.configDescription !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
          {this.state.errorLengthConfigDescription ? <div className="validation-error">{this.props.getTranslate('validation-LengthConfigDescription')}</div> : ''}
        </div>
        </>}
      type={'formSubmit'}
      buttonSubmitText={this.props.getTranslate('shopModal-editSubmitCommentButton')}
      submitHandler={this.handlerEditCommentSubmit.bind(this)}
      submitLoading={this.state.loadingAddEditComment}
      handlerClose={this.handlerCloseConfigModal.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}

    {/* Modal Remove Config */}
    {this.state.modalRemoveConfig ? <Modal
      title={this.props.getTranslate('shopModal-RemoveCommentTitle')}
      content={<div className="delete-category-form__description">{this.props.getTranslate('shopModal-RemoveSubmitCommentDescription')}</div>}
      type={'delete'}
      buttonSubmitText={this.props.getTranslate('shopModal-RemoveSubmitCommentButton')}
      submitHandler={this.handlerDeleteConfig.bind(this)}
      submitLoading={this.state.loadingRemoveComment}
      handlerClose={this.handlerCloseConfigEditModal.bind(this)}
      submitStatus={this.state.modalSubmitStatus}
    /> : ''}
    </>
  }
}
export default Shops;
