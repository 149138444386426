import Home from './Home';
import StandartPages from './StandartPages';
import Blog from './Blog';
import BlogView from './BlogView';
import Glossary from './Glossary';
import GlossaryView from './GlossaryView';
import Forgot from './Forgot';
import ForgotCode from './ForgotCode';
import Login from './Login';
import Registration from './Registration';
import RegistrationUser from './RegistrationUser';
import Confirm from './Confirm';
import About from './About';
import AboutPanel from './AboutPanel';
import ServicePages from './ServicePages';
import Price from './Price';
import Contacts from './Contacts';
import Dashboard from './Dashboard';
import User from './User';
import Company from './Company';
import UsersEdit from './UsersEdit';
import Users from './Users';
import UsersView from './UsersView';
import Teams from './Teams';
import Deposits from './Deposits';
import DepositsCreate from './DepositsCreate';
import Messages from './Messages';
import Products from './Products';
import ProductsNoCategory from './ProductsNoCategory';
import ProductsView from './ProductsView';
import ProductsCreate from './ProductsCreate';
import ProductsEdit from './ProductsEdit';
import Shops from './Shops';
import Orders from './Orders';
import OrdersEdit from './OrdersEdit';
import Bonuses from './Bonuses';
import BonusesCreate from './BonusesCreate';
import BonusesEdit from './BonusesEdit';
import BonusesView from './BonusesView';
import SurveysDefault from './SurveysDefault';
import SurveysQuick from './SurveysQuick';
import SurveysEditQuick from './SurveysEditQuick';
import SurveysEditDefault from './SurveysEditDefault';
import SurveysCreateDefault from './SurveysCreateDefault';
import SurveysCreateQuick from './SurveysCreateQuick';
import SurveysResult from './SurveysResult';
import Ads from './Ads';
import AdsCreate from './AdsCreate';
import AdsEdit from './AdsEdit';
import Benefits from './Benefits';
import BenefitsCreate from './BenefitsCreate';
import BenefitsEdit from './BenefitsEdit';
import Events from './Events';
import EventsCreate from './EventsCreate';
import EventsEdit from './EventsEdit';
import Library from './Library';
import LibraryReviews from './LibraryReviews';
import LibraryRequest from './LibraryRequest';
import LibraryCreate from './LibraryCreate';
import LibraryEdit from './LibraryEdit';
import Challenges from './Challenges';
import Faq from './Faq';
import FaqCreate from './FaqCreate';
import FaqEdit from './FaqEdit';
import Rewards from './Rewards';
import RewardsCreate from './RewardsCreate';
import RewardsEdit from './RewardsEdit';
import RewardsView from './RewardsView';
import Reminder from './Reminder';
import ReminderCreate from './ReminderCreate';
import ReminderEdit from './ReminderEdit';
import Settings from './Settings';
import Notification from './Notification';
import ControlPanel from './controlpanel/ControlPanel';
import ControlPanelAccount from './controlpanel/ControlPanelAccount';
import ControlPanelDashboard from './controlpanel/ControlPanelDashboard';
import ControlPanelTranslations from './controlpanel/ControlPanelTranslations';
import ControlPanelMessages from './controlpanel/ControlPanelMessages';
import ControlPanelCompanies from './controlpanel/ControlPanelCompanies';
import ControlPanelCompaniesView from './controlpanel/ControlPanelCompaniesView';
import ControlPanelCompanyUsers from './controlpanel/ControlPanelCompanyUsers';
import ControlPanelCompanyUsersView from './controlpanel/ControlPanelCompanyUsersView';
import ControlPanelPayments from './controlpanel/ControlPanelPayments';
import ControlPanelAccess from './controlpanel/ControlPanelAccess';
import ControlPanelAccessEdit from './controlpanel/ControlPanelAccessEdit';
import ControlPanelAccessGroup from './controlpanel/ControlPanelAccessGroup';
import ControlPanelAccessGroupEdit from './controlpanel/ControlPanelAccessGroupEdit';
import ControlPanelBlog from './controlpanel/ControlPanelBlog';
import ControlPanelBlogCreate from './controlpanel/ControlPanelBlogCreate';
import ControlPanelBlogEdit from './controlpanel/ControlPanelBlogEdit';
import ControlPanelGlossary from './controlpanel/ControlPanelGlossary';
import ControlPanelGlossaryCreate from './controlpanel/ControlPanelGlossaryCreate';
import ControlPanelGlossaryEdit from './controlpanel/ControlPanelGlossaryEdit';
import ControlPanelPages from './controlpanel/ControlPanelPages';
import ControlPanelPagesCreate from './controlpanel/ControlPanelPagesCreate';
import ControlPanelPagesEdit from './controlpanel/ControlPanelPagesEdit';
import ControlPanelLog from './controlpanel/ControlPanelLog';
import ControlPanelDemo from './controlpanel/ControlPanelDemo';
import NotFound from './NotFound';
import Loading from './components/Loading';
import LoadingServer from './components/LoadingServer';
import PageWithCanonical from './components/PageWithCanonical';
import Ga from './components/Ga';
import Cookies from './components/Cookies';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import './App.css';
import './controlpanel/AdminApp.css';
const apiUrl = process.env.REACT_APP_API_URL;
ReactGA.initialize('G-EDKNX5YNHK');
function App(props) {
  const queryLink = apiUrl ? apiUrl : 'https://stage-api.matespace.io';
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [companyLogo, setCompanyLogo] = useState(null);
  const [completed, setComplated] = useState(true);
  const [userPhoto, setUserPhoto] = useState(null);
  const [userName, setUserName] = useState(null);
  const [newReview, setNewReview] = useState(null);
  const [configApp, setConfigApp] = useState(null);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalName, setModalName] = useState(null);
  const [modalStatusLoading, setModalStatusLoading] = useState(false);

  const [language, setLanguage] = useState(props.languages);
  const [chooseLanguage, setChooseLanguage] = useState(props.isEnglish ? 'english' : 'ukrainian');

  async function refreshToken(){
    let currentUser = getAuth().currentUser;
    if(currentUser){
      await currentUser.getIdToken();
    }
  }

function number_format(number, decimals, dec_point, thousands_sep) {
  decimals = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals;
  dec_point = dec_point === undefined ? ',' : dec_point;
  thousands_sep = thousands_sep === undefined ? '.' : thousands_sep;

  // Преобразуем число в строку, чтобы манипулировать как целой, так и дробной частью
  var n = (+number || 0).toFixed(decimals).split('.');

  // Форматируем целую часть числа с разделением на тысячи
  var integerPart = n[0];
  var i = integerPart.length;
  var km = (i > 3 ? i % 3 : 0);

  var formattedNumber = (km ? integerPart.substr(0, km) + thousands_sep : '') +
                        integerPart.substr(km).replace(/(\d{3})(?=\d)/g, '$1' + thousands_sep);

  // Если дробная часть равна "00", не отображаем её
  var decimalPart = n[1] && n[1] !== '00' ? n[1] : '';

  // Собираем окончательный результат: если дробной части нет, не добавляем десятичный разделитель
  return formattedNumber + (decimalPart ? dec_point + decimalPart : '');
}



  function getPermalink(path){
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      let location = document.location.pathname;
      const isEnglish = location.startsWith('/en');

      let targetPath;
      if (isEnglish) {
        targetPath = '/en' + path;
      } else {
        targetPath = path;
      }
      return targetPath;
    }
  }
  function getHeadersForQuery(token){
    let location;
    let language = 'uk-UA';
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US'
      }
    }
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept-Language': language
    });
    return myHeaders;
  }
  function getTranslate(key){
    if(language && language.length > 0 && chooseLanguage){
      let index = language.findIndex(el => el.label === key);
      if(index !== -1){
        let item = language[index];
        let result = item[chooseLanguage];
        return result;
      } else {
        return key;
      }

    }
  }
  function getToken(){
    if (typeof window !== "undefined" && typeof document !== 'undefined'){
      let token;
      refreshToken();
      let currentUser = getAuth().currentUser;
      if(currentUser){
        token = currentUser.accessToken;
      }
      return token;
    }
  }
  function handlerChangeLanguage() {
    if(chooseLanguage === 'ukrainian'){
      setChooseLanguage('english');
    } else {
      setChooseLanguage('ukrainian');
    }
  }
  function updateCompanyData(){
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${queryLink}/api/v1/companies`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => res.json())
        .then((result) => {
          setCurrency(result.currency);
          setCompanyName(result.name);
          document.title = "MateSpace.io - " + result.name;
        },
          (error) => {

          }
        );
      })
  }
  function updateCountReview(){
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${queryLink}/api/v1/library/books/reviews/view`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          "view": true
        })
      }).then(res => {
        if(res.status === 200) {
          setNewReview(0);
        }
      })
      })
  }

  function handlerStatusSetting(name, event){
    let obj = configApp;
    if(modalStatus){
      setModalStatusLoading(true);
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        obj[modalName] = !obj[modalName];
        fetch(`${queryLink}/api/v1/companies/config`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify(obj)
        }).then(res => {
        if(res.status !== 200) {

        } else {
          res.json().then(function(data) {
            setConfigApp(data);
            setModalName(null);
            setModalStatus(false);
            setModalStatusLoading(false);
          });
        }
        })
      })
    }
    if(obj[name]){
      setModalStatus(true);
      setModalName(name);
    } else {
      setModalStatusLoading(true);
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        obj[name] = !obj[name];
        fetch(`${queryLink}/api/v1/companies/config`, {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify(obj)
        }).then(res => {
        if(res.status !== 200) {

        } else {
          res.json().then(function(data) {
            setConfigApp(data);
            setModalStatusLoading(false);
          });
        }
        })
      })
    }
  }

  function modalDisabledSettingStatus(){
    setModalStatus(false);
  }

  useEffect(() => {
    let location;
    let isEnglish;
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      location = window.location.pathname;
      isEnglish = location.startsWith('/en');
    }
        setChooseLanguage(isEnglish ? 'english' : 'ukrainian');
        const myHeaders = new Headers({
            'Content-Type': 'application/json'
        });
        fetch(`${queryLink}/bo/v1/configs/translations/all`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => res.json())
          .then((result) => {
                localStorage.setItem("languageItems", JSON.stringify(result));
            setLanguage(result);
          },
          (error) => {

          });
  onAuthStateChanged(auth, (user) => {
    if (user) {
      getAuth().currentUser.getIdToken().then(function(idToken){

        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${queryLink}/api/v1/companies/auth/after-login`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status === 200){
            const myHeaders = new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + idToken
            });
            if (localStorage.getItem('countMessage')) {
              localStorage.removeItem('countMessage');
            }
            if (localStorage.getItem('countOrders')) {
              localStorage.removeItem('countOrders');
            }

            fetch(`${queryLink}/api/v1/users/dashboards`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if (res.status === 200) {
                res.json().then(function (data) {
                    localStorage.setItem('countMessage', data.messages);
                    localStorage.setItem('countOrders', data.newOrders);
                })
              }
            })

        fetch(`${queryLink}/api/v1/companies`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => res.json())
          .then((result) => {
            setCurrency(result.currency);
            setCompanyName(result.name);
            let indexLogo = result.photos.findIndex(el => el.type === 'LOGO');
              if(indexLogo !== -1){
                setCompanyLogo(result.photos[indexLogo].url);
              } else {
                setCompanyLogo(null);
              }
            localStorage.setItem("currencyMt", result.currency);
          },
            (error) => {

            }
          );

          fetch(`${queryLink}/api/v1/companies/config`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if(res.status === 200) {
              res.json().then(function(data) {
                setConfigApp(data)
              });
            }
          })

            fetch(`${queryLink}/api/v1/library/books/info`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if(res.status === 200) {
                res.json().then(function(data) {
                  setNewReview(data.newReviews)
                });
              }
            });
        fetch(`${queryLink}/api/v1/users/me`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => res.json())
          .then((result) => {
            if(result.completed && !JSON.parse(localStorage.getItem('complateUser'))){
              localStorage.setItem("complateUser", JSON.stringify(result.completed));
            } else if(!result.completed && JSON.parse(localStorage.getItem('complateUser'))) {
              localStorage.removeItem("complateUser");
            } else {
              localStorage.removeItem("complateUser");
            }
            setComplated(result.completed);
            setUserPhoto(result.photo);
            setUserName(result.name);
          },
            (error) => {

            }
          );

          setUser(user);
        }
      })
        })
        } else {
          setUser(false);
        }
      });
  }, []);
  if(typeof window !== "undefined" && typeof document !== 'undefined'){
  if (user === null || !language){
    return <div><LoadingServer /></div>
  } else if(!user) {
    let path = '';
    let code = '';
    let codeForgot = '';
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      path = document.location.pathname;
      code = path.substr(path.lastIndexOf('/') + 1);
      if(path === '/forgetPassReset' && document.location.search){
                    const queryParams = new URLSearchParams(document.location.search);
             codeForgot = queryParams.get('u');
      }

    }
let arrPage = ['/blog', '/en/blog', '/blog/' + code, '/en/blog/' + code, '/glossary', '/en/glossary', '/glossary' + code, '/en/glossary' + code,'/offer', '/en/offer', '/about', '/en/about', '/about-panel', '/en/about-panel', '/service', '/en/service', '/price', '/en/price', '/contacts', '/en/contacts', '/forgot', '/en/forgot', `/forgetPassReset`, `/en/forgetPassReset`, '/terms', '/en/terms', '/login', '/en/login', '/registration/user/' + code, '/en/registration/user/' + code, '/registration', '/en/registration', '/', '/en', '/en/', '/controlpanel', '/en/controlpanel'];

let arrPageWithSlash = arrPage.map(item => item + '/');

if (!arrPage.includes(path) && !arrPageWithSlash.includes(path)) {
  if (typeof window !== "undefined" && typeof document !== 'undefined') {
    document.location.pathname = '/login';
  }
}

    return (<>
      <Router>
      <Cookies />
      <Ga />
        <Routes>
          <Route exact path="/:slug" element={<StandartPages getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/:slug" element={<StandartPages getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/about" element={<About title={'Про додаток – Додаток для співробітників | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/about" element={<About title={'Про додаток – Додаток для співробітників | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/about-panel" element={<AboutPanel title={'Про адмін панель – Система керування проектом | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/about-panel" element={<AboutPanel title={'Про адмін панель – Система керування проектом | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/blog" element={<Blog title={'Блог | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/blog" element={<Blog title={'Блог | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/blog/:code" element={<BlogView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/blog/:code" element={<BlogView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/glossary" element={<Glossary title={'Глосарій | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/glossary" element={<Glossary title={'Глосарій | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/glossary/:code" element={<GlossaryView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/glossary/:code" element={<GlossaryView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/service" element={<ServicePages getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/service" element={<ServicePages getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/price" element={<Price title={'Ціни | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/price" element={<Price title={'Ціни | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/contacts" element={<Contacts title={'Контакти | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/contacts" element={<Contacts title={'Контакти | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/forgot" element={<Forgot title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/forgot" element={<Forgot title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/forgetPassReset" element={<ForgotCode title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} codeForgot={codeForgot} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/forgetPassReset" element={<ForgotCode title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} codeForgot={codeForgot} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/login" element={<Login title={'Авторизація | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/login" element={<Login title={'Авторизація | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/registration/user/:code" element={<RegistrationUser title={'Додавання користувача | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/registration/user/:code" element={<RegistrationUser title={'Додавання користувача | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/confirm/:code" element={<Confirm title={'Підтвердження | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/confirm/:code" element={<Confirm title={'Підтвердження | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/registration" element={<Registration title={'Реєстрація компанії | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en/registration" element={<Registration title={'Реєстрація компанії | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/" element={<Home title={'Головна - MateSpace.io - Креативна экосистема мотивації та комунікації для вашої компанії'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/en" element={<Home title={'Головна - MateSpace.io - Креативна экосистема мотивації та комунікації для вашої компанії'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
          <Route exact path="/account" element={<Dashboard getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} token={getToken} />} />
          <Route exact path="/en/account" element={<Dashboard getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} token={getToken} />} />
          <Route exact path="/controlpanel" element={<ControlPanel getPermalink={getPermalink} queryLink={queryLink} />} />
          <Route exact path="/en/controlpanel" element={<ControlPanel getPermalink={getPermalink} queryLink={queryLink} />} />
          <Route exact path="*" element={<NotFound getPermalink={getPermalink} queryLink={queryLink} />} status={404} />
      </Routes>
    </Router></>)
  } else {
    let path = '';
    let codeForgot = '';
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      path = document.location.pathname;
      if(path === '/forgetPassReset' && document.location.search){
                    const queryParams = new URLSearchParams(document.location.search);
             codeForgot = queryParams.get('u');
      }
    }
return (
  <Router>
  <Cookies />
  <Ga />
  <Routes>
    <Route exact path="/:slug" element={<StandartPages getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/:slug" element={<StandartPages getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/library/edit/:id" element={<LibraryEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/library/edit/:id" element={<LibraryEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/library/create" element={<LibraryCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/library/create" element={<LibraryCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/library/request" element={<LibraryRequest getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/library/request" element={<LibraryRequest getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/library/reviews" element={<LibraryReviews getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} handlerUpdateReviews={updateCountReview} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/library/reviews" element={<LibraryReviews getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} handlerUpdateReviews={updateCountReview} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/library" element={<Library getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/library" element={<Library getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/notification" element={<Notification getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/notification" element={<Notification getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/settings" element={<Settings getPermalink={getPermalink} queryLink={queryLink} token={getToken} modalStatusLoading={modalStatusLoading} modalDisabledSettingStatus={modalDisabledSettingStatus} handlerStatusSetting={handlerStatusSetting} configsApp={configApp} modalStatus={modalStatus} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/settings" element={<Settings getPermalink={getPermalink} queryLink={queryLink} token={getToken} modalStatusLoading={modalStatusLoading} modalDisabledSettingStatus={modalDisabledSettingStatus} handlerStatusSetting={handlerStatusSetting} configsApp={configApp} modalStatus={modalStatus} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/accrual" element={<Benefits getPermalink={getPermalink} queryLink={queryLink} number_format={number_format} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/accrual" element={<Benefits getPermalink={getPermalink} queryLink={queryLink} number_format={number_format} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/accrual/create" element={<BenefitsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/accrual/create" element={<BenefitsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/accrual/edit/:id" element={<BenefitsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/accrual/edit/:id" element={<BenefitsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/reminder/edit/:id" element={<ReminderEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/reminder/edit/:id" element={<ReminderEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/reminder/create" element={<ReminderCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/reminder/create" element={<ReminderCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/reminder" element={<Reminder getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/reminder" element={<Reminder getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/faq/edit/:id" element={<FaqEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/faq/edit/:id" element={<FaqEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/faq/create" element={<FaqCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/faq/create" element={<FaqCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/faq" element={<Faq getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/faq" element={<Faq getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/rewards" element={<Rewards getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/rewards" element={<Rewards getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/rewards/create" element={<RewardsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/rewards/create/user/:id" element={<RewardsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/rewards/create" element={<RewardsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/rewards/create/user/:id" element={<RewardsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/rewards/edit/:id" element={<RewardsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/rewards/edit/:id" element={<RewardsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/rewards/view/:id" element={<RewardsView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/rewards/view/:id" element={<RewardsView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/challenges" element={<Challenges getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/events/edit/:id" element={<EventsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/events/edit/:id" element={<EventsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/events/create" element={<EventsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/events/create" element={<EventsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} currency={currency} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/events" element={<Events getPermalink={getPermalink} queryLink={queryLink} token={getToken} newReview={newReview} configsApp={configApp} currency={currency} companyName={companyName} currency={currency} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/events" element={<Events getPermalink={getPermalink} queryLink={queryLink} token={getToken} newReview={newReview} configsApp={configApp} currency={currency} companyName={companyName} currency={currency} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/marketplace/edit/:id" element={<AdsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/marketplace/edit/:id" element={<AdsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/marketplace/create" element={<AdsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed}handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/marketplace/create" element={<AdsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed}handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/marketplace" element={<Ads getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/marketplace" element={<Ads getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/surveys/result/:id" element={<SurveysResult getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/surveys/result/:id" element={<SurveysResult getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/surveys/create/quick" element={<SurveysCreateQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/surveys/create/quick" element={<SurveysCreateQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/surveys/create/default" element={<SurveysCreateDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/surveys/create/default" element={<SurveysCreateDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/surveys/edit/quick/:id" element={<SurveysEditQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/surveys/edit/quick/:id" element={<SurveysEditQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/surveys/edit/default/:id" element={<SurveysEditDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/surveys/edit/default/:id" element={<SurveysEditDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/surveys/default" element={<SurveysDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/surveys/default" element={<SurveysDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/surveys/quick" element={<SurveysQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/surveys/quick" element={<SurveysQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/benefits/view/:id" element={<BonusesView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/benefits/view/:id" element={<BonusesView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/benefits/create" element={<BonusesCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/benefits/create" element={<BonusesCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/benefits/edit/:id" element={<BonusesEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/benefits/edit/:id" element={<BonusesEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/benefits" element={<Bonuses getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/benefits" element={<Bonuses getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/orders/edit/:id" element={<OrdersEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/orders/edit/:id" element={<OrdersEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/orders" element={<Orders getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/orders" element={<Orders getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/products/edit/:id" element={<ProductsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/products/edit/:id" element={<ProductsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/products/create/:id" element={<ProductsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/products/create/:id" element={<ProductsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/products/no-category" element={<ProductsNoCategory getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/products/no-category" element={<ProductsNoCategory getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/products/:id" element={<ProductsView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/products/:id" element={<ProductsView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/products" element={<Products getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/products" element={<Products getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/shops" element={<Shops getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/shops" element={<Shops getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/messages" element={<Messages getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/messages" element={<Messages getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/deposits/create" element={<DepositsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/deposits/create" element={<DepositsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/deposits/:id" element={<Deposits getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/deposits/:id" element={<Deposits getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/teams" element={<Teams getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/teams" element={<Teams getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/users/edit/:id" element={<UsersEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/users/edit/:id" element={<UsersEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/users/view/:id" element={<UsersView getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} companyLogo={companyLogo} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/users/view/:id" element={<UsersView getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} companyLogo={companyLogo} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/users" element={<Users getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} companyLogo={companyLogo} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/users" element={<Users getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} companyLogo={companyLogo} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/company" element={<Company getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} updateCompany={updateCompanyData} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/company" element={<Company getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} updateCompany={updateCompanyData} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account/user" element={<User getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account/user" element={<User getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/account" element={<Dashboard getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/account" element={<Dashboard getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/about" element={<About title={'Про додаток – Додаток для співробітників | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/about" element={<About title={'Про додаток – Додаток для співробітників | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/about-panel" element={<AboutPanel title={'Про адмін панель – Система керування проектом | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/about-panel" element={<AboutPanel title={'Про адмін панель – Система керування проектом | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/blog" element={<Blog title={'Блог | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/blog" element={<Blog title={'Блог | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/blog/:code" element={<BlogView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/blog/:code" element={<BlogView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/glossary" element={<Glossary title={'Глосарій | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/glossary" element={<Glossary title={'Глосарій | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/glossary/:code" element={<GlossaryView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/glossary/:code" element={<GlossaryView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/service" element={<ServicePages getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/price" element={<Price title={'Ціни | MateSpace.io'} getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/price" element={<Price title={'Ціни | MateSpace.io'} getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/contacts" element={<Contacts title={'Контакти | MateSpace.io'} getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/contacts" element={<Contacts title={'Контакти | MateSpace.io'} getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="forgetPassReset" element={<ForgotCode title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} codeForgot={codeForgot} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="en/forgetPassReset" element={<ForgotCode title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} codeForgot={codeForgot} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/forgot" element={<Forgot title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/forgot" element={<Forgot title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/login" element={<Login title={'Авторизація | MateSpace.io'} getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/login" element={<Login title={'Авторизація | MateSpace.io'} getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/registration/user/:code" element={<RegistrationUser title={'Додавання користувача | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/registration/user/:code" element={<RegistrationUser title={'Додавання користувача | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/confirm/:code" element={<Confirm title={'Підтвердження | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/confirm/:code" element={<Confirm title={'Підтвердження | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/registration" element={<Registration title={'Реєстрація компанії | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en/registration" element={<Registration title={'Реєстрація компанії | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/" element={<Home title={'Головна - MateSpace.io - Креативна экосистема мотивації та комунікації для вашої компанії'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/en" element={<Home title={'Головна - MateSpace.io - Креативна экосистема мотивації та комунікації для вашої компанії'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} />
    <Route exact path="/controlpanel" element={<ControlPanel queryLink={queryLink} />} />
    <Route exact path="/controlpanel/account" element={<ControlPanelAccount queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/dashboard" element={<ControlPanelDashboard queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/translations" element={<ControlPanelTranslations queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/messages" element={<ControlPanelMessages queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/companies" element={<ControlPanelCompanies queryLink={queryLink} token={getToken} getPermalink={getPermalink} getTranslate={getTranslate} />} />
    <Route exact path="/controlpanel/companies/:id/" element={<ControlPanelCompaniesView queryLink={queryLink} token={getToken} getPermalink={getPermalink} getTranslate={getTranslate} />} />
    <Route exact path="/controlpanel/companies/:id/users" element={<ControlPanelCompanyUsers queryLink={queryLink} token={getToken} getPermalink={getPermalink} getTranslate={getTranslate} />} />
    <Route exact path="/controlpanel/companies/users/view/:id" element={<ControlPanelCompanyUsersView queryLink={queryLink} token={getToken} getPermalink={getPermalink} getTranslate={getTranslate} currency={currency} />} />
    <Route exact path="/controlpanel/payments" element={<ControlPanelPayments queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/access" element={<ControlPanelAccess queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/access/edit/:id" element={<ControlPanelAccessEdit queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/group" element={<ControlPanelAccessGroup queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/group/edit/:id" element={<ControlPanelAccessGroupEdit queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/blog" element={<ControlPanelBlog queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/blog/create" element={<ControlPanelBlogCreate queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/blog/edit/:id" element={<ControlPanelBlogEdit queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/glossary" element={<ControlPanelGlossary queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/glossary/create" element={<ControlPanelGlossaryCreate queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/glossary/edit/:id" element={<ControlPanelGlossaryEdit queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/pages" element={<ControlPanelPages queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/pages/create" element={<ControlPanelPagesCreate queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/pages/edit/:id" element={<ControlPanelPagesEdit queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/demo" element={<ControlPanelDemo queryLink={queryLink} token={getToken} />} />
    <Route exact path="/controlpanel/log" element={<ControlPanelLog queryLink={queryLink} token={getToken} />} />
    <Route exact path="*" element={<NotFound getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} />} status={404} />
  </Routes>
  </Router>
);
  }
} else {
  if(props.url === '/' || props.url === '/en'){
    const animationBlock = {
      backgroundColor: "#fff", position: "fixed", top: "0", left: "0", bottom: "0", right: "0", zIndex: "999998"
    };
    const animationStyle = {
        width: "40px",
        height: "40px",
        position: "fixed",
        top: "15px",
        left: "50%",
      animation: "spin 0.6s linear infinite",
      "@keyframes spin": {
        from: {
          transform: "rotate(0deg) translateX(-50%)"
        },
        to: {
          transform: "rotate(360deg) translateX(-50%)"
        }
      }
    };
    return <><LoadingServer /><Home title={'Головна сторінка | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={props.url === '/' ? 'ukrainian' : 'english'} getTranslate={getTranslate} /></>
  } else if(props.url === '/registration' || props.url === '/en/registration') {
    return <><LoadingServer /><Registration title={'Реєстрація компанії | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/login' || props.url === '/en/login') {
    return <><LoadingServer /><Login title={'Авторизація | MateSpace.io'} getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/about' || props.url === '/en/about') {
    return <><LoadingServer /><About title={'Про додаток – Додаток для співробітників | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/about-panel' || props.url === '/en/about-panel') {
    return <><LoadingServer /><AboutPanel title={'Про адмін панель – Система керування проектом | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/blog' || props.url === '/en/blog') {
    return <><LoadingServer /><Blog title={'Блог | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/blog/') && props.pageData && !props.statusServer) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const slug = parts[3];
    if(regex.test(slug)){
      return <><LoadingServer /><BlogView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} pageData={props.pageData} /></>
    }
  } else if(props.url.startsWith('/blog/') && props.pageData && !props.statusServer) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const slug = parts[2];
    if(regex.test(slug)){
      return <><LoadingServer /><BlogView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} pageData={props.pageData} /></>
    }
  } else if(props.url === '/glossary' || props.url === '/en/glossary') {
    return <><LoadingServer /><Glossary title={'Глосарій | MateSpace.io'} PageWithCanonical={<PageWithCanonical />} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/glossary/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const slug = parts[3];
    if(regex.test(slug)){
      return <><LoadingServer /><GlossaryView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} pageData={props.pageData} /></>
    }
  } else if(props.url.startsWith('/glossary/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const slug = parts[2];
    if(regex.test(slug)){
      return <><LoadingServer /><GlossaryView getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} pageData={props.pageData} /></>
    }
  } else if(props.url === '/service' || props.url === '/en/service') {
    return <><LoadingServer /><ServicePages getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/price' || props.url === '/en/price') {
    return <><LoadingServer /><Price title={'Ціни | MateSpace.io'} getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/contacts' || props.url === '/en/contacts') {
    return <><LoadingServer /><Contacts title={'Контакти | MateSpace.io'} getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/forgetPassReset' || props.url === '/en/forgetPassReset') {
    return <><LoadingServer /><ForgotCode title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/forgot' || props.url === '/en/forgot') {
    return <><LoadingServer /><Forgot title={'Забули пароль | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/registration/user/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const code = parts[4];
    if(regex.test(code)){
      return <><LoadingServer /><RegistrationUser title={'Додавання користувача | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/registration/user/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const code = parts[3];
    if(regex.test(code)){
      return <><LoadingServer /><RegistrationUser title={'Додавання користувача | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/en/confirm/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const code = parts[3];
    if(regex.test(code)){
      return <><LoadingServer /><Confirm title={'Підтвердження | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/confirm/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const code = parts[2];
    if(regex.test(code)){
      return <><LoadingServer /><Confirm title={'Підтвердження | MateSpace.io'} getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/en/controlpanel/pages/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><ControlPanelPagesEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url.startsWith('/controlpanel/pages/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><ControlPanelPagesEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url === '/controlpanel/pages/create' || props.url === '/en/controlpanel/pages/create') {
    return <><LoadingServer /><ControlPanelPagesCreate queryLink={queryLink} token={getToken} /></>
  } else if(props.url === '/controlpanel/pages' || props.url === '/en/controlpanel/pages') {
    return <><LoadingServer /><ControlPanelPages queryLink={queryLink} token={getToken} /></>
  } else if(props.url.startsWith('/en/controlpanel/glossary/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><ControlPanelGlossaryEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url.startsWith('/controlpanel/glossary/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><ControlPanelGlossaryEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url === '/controlpanel/glossary/create' || props.url === '/en/controlpanel/glossary/create') {
    return <><LoadingServer /><ControlPanelGlossaryCreate queryLink={queryLink} token={getToken} /></>
  } else if(props.url === '/controlpanel/glossary' || props.url === '/en/controlpanel/glossary') {
    return <><LoadingServer /><ControlPanelGlossary queryLink={queryLink} token={getToken} /></>
  } else if(props.url.startsWith('/en/controlpanel/blog/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><ControlPanelBlogEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url.startsWith('/controlpanel/blog/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><ControlPanelBlogEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url === '/controlpanel/blog/create' || props.url === '/en/controlpanel/blog/create') {
    return <><LoadingServer /><ControlPanelBlogCreate queryLink={queryLink} token={getToken} /></>
  } else if(props.url === '/controlpanel/blog' || props.url === '/en/controlpanel/blog') {
    return <><LoadingServer /><ControlPanelBlog queryLink={queryLink} token={getToken} /></>
  } else if(props.url.startsWith('/en/controlpanel/group/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><ControlPanelAccessGroupEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url.startsWith('/controlpanel/group/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><Loading /><ControlPanelAccessGroupEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url === '/controlpanel/group' || props.url === '/en/controlpanel/group') {
    return <><LoadingServer /><ControlPanelAccessGroup queryLink={queryLink} token={getToken} /></>
  } else if(props.url.startsWith('/en/account/access/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><ControlPanelAccessEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url.startsWith('/account/access/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><ControlPanelAccessEdit queryLink={queryLink} token={getToken} /></>
    }
  } else if(props.url === '/controlpanel/access' || props.url === '/en/controlpanel/access') {
    return <><LoadingServer /><ControlPanelAccess queryLink={queryLink} token={getToken} /></>
  } else if(props.url === '/controlpanel/payments' || props.url === '/en/controlpanel/payments') {
    return <><LoadingServer /><ControlPanelPayments queryLink={queryLink} token={getToken} /></>
  } else if(props.url === '/controlpanel/account' || props.url === '/en/controlpanel/account') {
    return <><LoadingServer /><ControlPanelAccount queryLink={queryLink} token={getToken} /></>
  } else if(props.url === '/controlpanel/companies' || props.url === '/en/controlpanel/companies') {
    return <><LoadingServer /><ControlPanelCompanies queryLink={queryLink} token={getToken} /></>
  } else if (props.url.startsWith('/controlpanel/companies/')) {
  const regex = /^\/controlpanel\/companies\/[a-zA-Z0-9]+\/users$/;
  if (regex.test(props.url)) {
    return <><LoadingServer /><ControlPanelCompanyUsers queryLink={queryLink} token={getToken} /></>
  }
} else if(props.url === '/controlpanel/messages' || props.url === '/en/controlpanel/messages') {
    return <><LoadingServer /><ControlPanelMessages queryLink={queryLink} token={getToken} /></>
  } else if(props.url === '/controlpanel/translations' || props.url === '/en/controlpanel/translations') {
   return <><LoadingServer /><ControlPanelTranslations queryLink={queryLink} token={getToken} /></>
 } else if(props.url === '/controlpanel/dashboard' || props.url === '/en/controlpanel/dashboard') {
    return <><LoadingServer /><ControlPanelDashboard queryLink={queryLink} token={getToken} /></>
  } else if(props.url === '/controlpanel' || props.url === '/en/controlpanel') {
    return <><LoadingServer /><ControlPanel queryLink={queryLink} /></>
  } else if(props.url === '/account/notification' || props.url === '/en/account/notification') {
    return <><LoadingServer /><Notification getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/settings' || props.url === '/en/account/settings') {
    return <><LoadingServer /><Settings getPermalink={getPermalink} queryLink={queryLink} token={getToken} modalStatusLoading={modalStatusLoading} modalDisabledSettingStatus={modalDisabledSettingStatus} handlerStatusSetting={handlerStatusSetting} configsApp={configApp} modalStatus={modalStatus} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/faq/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><FaqEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/faq/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><FaqEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/faq/create' || props.url === '/en/account/faq/create') {
    return <><LoadingServer /><FaqCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed}handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/faq' || props.url === '/en/account/faq') {
    return <><LoadingServer /><Faq getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/reminder/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><ReminderEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/reminder/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><ReminderEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/reminder/create' || props.url === '/en/account/reminder/create') {
    return <><LoadingServer /><ReminderCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed}handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/reminder' || props.url === '/en/account/reminder') {
   return <><LoadingServer /><Reminder getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/rewards' || props.url === '/en/account/rewards') {
   return <><LoadingServer /><Rewards getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/rewards/create' || props.url === '/en/account/rewards/create') {
    return <><LoadingServer /><RewardsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed}handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/rewards/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><RewardsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/rewards/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><RewardsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/en/account/rewards/view/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><RewardsView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/rewards/view/')) {
    const regex = /^[0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><RewardsView getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/reminder' || props.url === '/en/account/reminder') {
   return <><LoadingServer /><Reminder getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
 } else if(props.url === '/account/accrual' || props.url === '/en/account/accrual') {
    return <><LoadingServer /><Benefits getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/library/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><LibraryEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/library/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><LibraryEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/library/create' || props.url === '/en/account/library/create') {
    return <><LoadingServer /><LibraryCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed}handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/library/request' || props.url === '/en/account/library/request') {
    return <><LoadingServer /><LibraryRequest getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/library/reviews' || props.url === '/en/account/library/reviews') {
    return <><LoadingServer /><LibraryReviews getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/library' || props.url === '/en/account/library') {
    return <><LoadingServer /><Library getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/events/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><EventsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/events/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><EventsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/events/create' || props.url === '/en/account/events/create') {
    return <><Loading /><EventsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed}handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/events' || props.url === '/en/account/events') {
    return <><LoadingServer /><Events getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/marketplace/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><AdsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/marketplace/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><AdsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/marketplace/create' || props.url === '/en/account/marketplace/create') {
    return <><LoadingServer /><AdsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed}handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/marketplace' || props.url === '/en/account/marketplace') {
    return <><LoadingServer /><Ads getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/surveys/result/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><SurveysResult getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/surveys/result/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><SurveysResult getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/surveys/create/default' || props.url === '/en/account/surveys/create/default') {
    return <><LoadingServer /><SurveysCreateDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/surveys/create/quick' || props.url === '/en/account/surveys/create/quick') {
    return <><LoadingServer /><SurveysCreateQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/surveys/edit/default/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[6];
    if(regex.test(id)){
      return <><LoadingServer /><SurveysEditDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/surveys/edit/default/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><SurveysEditDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/en/account/surveys/edit/quick/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[6];
    if(regex.test(id)){
      return <><LoadingServer /><SurveysEditQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/surveys/edit/quick/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><SurveysEditQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/surveys/default' || props.url === '/en/account/surveys/default') {
    return <><LoadingServer /><SurveysDefault getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/surveys/quick' || props.url === '/en/account/surveys/quick') {
    return <><LoadingServer /><SurveysQuick getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/benefits/view/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><BonusesView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/benefits/view/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><BonusesView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/en/account/benefits/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><BonusesEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/benefits/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><BonusesEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/benefits/create' || props.url === '/en/account/benefits/create') {
    return <><LoadingServer /><BonusesCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/benefits' || props.url === '/en/account/benefits') {
    return <><LoadingServer /><Bonuses getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/order/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><OrdersEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/order/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><OrdersEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/order' || props.url === '/en/account/order') {
    return <><LoadingServer /><Orders getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/shops' || props.url === '/en/account/shops') {
    return <><LoadingServer /><Shops getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/products/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><ProductsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/products/edit/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><ProductsEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/en/account/products/create/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><ProductsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/products/create/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><ProductsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/products/no-category' || props.url === '/en/account/products/no-category') {
    return <><LoadingServer /><ProductsNoCategory getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/products/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><ProductsView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/products/')) {
    const regex = /^[a-zA-Z0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[3];
    if(regex.test(id)){
      return <><LoadingServer /><ProductsView getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/products' || props.url === '/en/account/products') {
    return <><LoadingServer /><Products getPermalink={getPermalink} queryLink={queryLink} queryHeaders={getHeadersForQuery} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/messages' || props.url === '/en/account/messages') {
    return <><LoadingServer /><Messages getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/deposits/create' || props.url === '/en/account/deposits/create') {
    return <><LoadingServer /><DepositsCreate getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/deposits/')) {
    const regex = /^[0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><Deposits getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/deposits/')) {
    const regex = /^[0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[3];
    if(regex.test(id)){
      return <><LoadingServer /><Deposits getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/teams' || props.url === '/en/account/teams') {
    return <><LoadingServer /><Teams getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/company' || props.url === '/en/account/company') {
    return <><LoadingServer /><Company getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} updateCompany={updateCompanyData} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url.startsWith('/en/account/users/edit/')) {
    const regex = /^[0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><UsersEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/users/edit/')) {
    const regex = /^[0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><UsersEdit getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/en/account/users/view/')) {
    const regex = /^[0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><UsersView getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/en/account/users/view/')) {
    const regex = /^[0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[5];
    if(regex.test(id)){
      return <><LoadingServer /><UsersView getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url.startsWith('/account/users/view/')) {
    const regex = /^[0-9]+$/;
    const parts = props.url.split('/');
    const id = parts[4];
    if(regex.test(id)){
      return <><LoadingServer /><UsersView getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  } else if(props.url === '/account/users' || props.url === '/en/account/users') {
    return <><LoadingServer /><Users getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} companyLogo={companyLogo} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account/user' || props.url === '/en/account/user') {
    return <><LoadingServer /><User getPermalink={getPermalink} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.url === '/account' || props.url === '/en/account') {
    return <><LoadingServer /><Dashboard getPermalink={getPermalink} number_format={number_format} queryLink={queryLink} token={getToken} configsApp={configApp} newReview={newReview} currency={currency} companyName={companyName} completed={completed} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else if(props.statusServer && props.statusServer === '404') {
    return <><LoadingServer /><NotFound getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
  } else {
    if(props.url.startsWith('/') && props.pageData && !props.statusServer) {
      const regex = /^[a-zA-Z0-9]+$/;
      const parts = props.url.split('/');
      const slug = parts[1];
      return <><LoadingServer /><StandartPages getPermalink={getPermalink} PageWithCanonical={<PageWithCanonical />} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} pageData={props.pageData} /></>
    } else {
        return <><LoadingServer /><NotFound getPermalink={getPermalink} queryLink={queryLink} logged={user} userPhoto={userPhoto} userName={userName} handlerChangeLanguage={handlerChangeLanguage} chooseLanguage={chooseLanguage} getTranslate={getTranslate} /></>
    }
  }
}
}
export default App;
