import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import ButtonIcon from './ButtonIcon';
import DateFormating from './DateFormating';
import Loading from './Loading';
import sprite from '../media/icons.svg';
class InfoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
      modalDisabled: false,
      heightInfo: null
    }
  }
  componentDidMount() {
    document.querySelector('body').style.overflow = "hidden";
    this.setState(prevState => ({
      modalStatus: true
    }));
    let infoDiv = document.querySelector('.modal__order-content-col-info');
    let height = infoDiv.clientHeight;
    this.setState(prevState => ({
      heightInfo: height,
    }));
    if(this.props.listData){
      setTimeout(() => {
        let thumb = document.querySelector('.thumb-vertical');
        let thumbHeight = thumb.clientHeight;
        this.setState(prevState => ({
          heightThumb: thumbHeight
        }));
      }, 100)
    }
  }
  handlerModalDisabled(event) {
    document.querySelector('body').style.overflow = "";
    this.setState(prevState => ({
      modalDisabled: true,
      modalStatus: false
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalStatus: false
      }));
      this.props.handlerClose();
    }, 500);
  }
  render() {
    return <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
      <div className="modal-background" onClick={this.handlerModalDisabled.bind(this)}></div>
        <div className="modal modal__order">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.title ? this.props.title : ''}</h2>
            <button className="close-modal" type="button" onClick={this.handlerModalDisabled.bind(this)}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          {this.props.navigation ? <div className="modal__order-nav">
            {this.props.orderNavDropDownStatus ? this.props.orderNavDropDownStatus : ''}
            {this.props.orderAddComment ? this.props.orderAddComment : ''}
            {this.props.navigationEditPath ? <ButtonIcon className="modal__order-nav-button" type="link" theme="edit" path={this.props.navigationEditPath} /> : ''}
          </div> : ''}
          <div className="modal__order-content">
            <div className="modal__order-content-col modal__order-content-col-info">
              <div className="modal__order-info-headline">{this.props.infoTitle ? this.props.infoTitle : ''}</div>
              {this.props.orderData ?
                <div className={`modal__order-info-product ${this.props.orderData.orderType !== 'COMPANY_SHOP' ? 'modal__order-info-product_no_flex' : ''}`}>
                  <h2 className="modal__order-info-product-name">{this.props.orderData.orderProductTitle}</h2>
                  {this.props.orderData.orderType === 'COMPANY_SHOP' ? <img className="modal__order-info-product-img" src={this.props.orderData.orderProductPhoto} alt="" /> : ''}
                </div> : ''}
              {this.props.dataContent.map((item, index) => {
                return item ? <div className="modal__order-info-product-table"><table key={index}>
                        <tbody>
                          {item.map((tableItem, tableIndex) => {
                            let itemValue = '';
                            if(this.props.translationValueItems && typeof this.props.translationValueItems[tableItem.value] !== "undefined" && tableItem.value){
                              if(this.props.labelsStatus && typeof this.props.labelsStatus[tableItem.value] !== "undefined" && tableItem.value){
                                itemValue = <span className={`modal-item-status modal-item-status_theme_${this.props.labelsStatus[tableItem.value]}`}>{this.props.translationValueItems[tableItem.value]}</span>;
                              } else {
                                itemValue = this.props.translationValueItems[tableItem.value];
                              }
                            } else {
                              itemValue = tableItem.value;
                            }
                            return tableItem ? <tr key={tableIndex}>
                                    <td>{tableItem.title}</td>
                                    <td>{tableItem.type === 'link' ? <a className="modal__order-link" href={itemValue} target="_blank">{itemValue && itemValue.length > 30 ? itemValue.slice(0, 30) + '...' : itemValue}</a> : itemValue}</td>
                                   </tr> : ''
                          })}
                        </tbody>
                      </table></div> : ''
              })}
              {this.props.noteValue ? <div className="modal__order-info-comment">
                <div className="modal__order-info-comment-headline">{this.props.noteTitle}</div>
                <div className="modal__order-info-comment-text">{this.props.noteValue}</div>
              </div> : ''}
            </div>
            <div className="modal__order-content-col modal__order-content-col-change">
              <div className="modal__order-content-history">
                <div className="modal__order-info-headline">{this.props.listTitle ? this.props.listTitle : ''}<span className="users-tab__link-amount">{this.props.listAmount}</span></div>
                <Scrollbars
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={this.state.heightInfo}
                      renderTrackVertical={props => <div {...props} className={`track-vertical ${this.state.heightThumb > 0 ? 'track-vertical__active' : ''}`} />}
                      renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                        renderView={props => <div {...props} />}>
                  {this.props.listData ? <>
                    {this.props.listData.map((item, index) => {
                      if(this.props.orderModal) {
                        let value = '';
                        if(item.type === 'STATUS'){
                          value = this.props.orderListStatusTranslation[item.changes];
                        } else if(item.type === 'QUANTITY') {
                          let quantityChange = item.changes.split(",");
                          value = this.props.orderListStatusTranslation['QUANTITY_FROM'] + ' ' + quantityChange[0] + ' ' + this.props.orderListStatusTranslation['QUANTITY_TO'] + ' ' + quantityChange[1] + ' ' + this.props.orderListStatusTranslation['QUANTITY_UNITS'];
                        } else {
                          value = this.props.addCommentTitle + ' ' + '<b>' + item.changes + '</b>';
                        }
                        return <div className="modal__order-content-history-row" key={index}>
                                <div className="modal__order-content-history-date"><DateFormating start={item.createDate} /></div>
                                <div className="modal__order-content-history-value" dangerouslySetInnerHTML={{ __html: value }}></div>
                              </div>
                      }
                    })}
                  </> : ''}
                  </Scrollbars>
              </div>
            </div>
          </div>
      </div>
    </div>;
  }
}
export default InfoModal;
