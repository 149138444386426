import React from "react";
import DateFormating from './components/DateFormating';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import Filter from './components/Filter';
import FilterSearch from './components/FilterSearch';
import Button from './components/Button';
import Loading from './components/Loading';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import TabItems from './components/TabItems';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import { Link, Navigate } from "react-router-dom";
import DeleteModal from './components/DeleteModal';
import Table from './components/Table';
import Pagination from './components/Pagination';
import survey_img from './media/surveys.png';
import sprite from './media/icons.svg';
import tooltipDefault from './media/default-survey.png';
import tooltipQuick from './media/quick-survey.png';
import { getAuth } from "firebase/auth";
class SurveysDefault extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      indexRowSurveyQuick: null,
      removeIndex: null,
      modalClass: null,
      modalDisabled: false,
      modal: false,
      modalData: {},
      questionModal: false,
      endedModal: false,
      typeQuestion: null,
      success: false,
      error: false,
      errorQuick: null,
      isLoaded: false,
      isLoadedQuick: false,
      surveyNormal: [],
      surveyQuick: [],
      totalPages: null,
      openRow: null,
      surveyName: '',
      tooltip: false,
      surveyNameError: false,
      surveyTypeError: false,
      currentPage: 0,
      totalItemsDefault: null,
      totalItemsQuick: null,
      successSurveySubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? JSON.parse(localStorage.getItem("successSurvey")) : '',
      currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
      errors: null,
      modalSurvey: false,
      dataSurveyModal: [],
      modalDeleteSurvey: false,
      idDeleteSurvey: null,
      controlRow: null,
      scrollPage: 1,
      heightInfo: 'auto',
      isLoadedSearch: false,
      searchSurveyValue: '',
      searchEmpty: false,
      isSearch: false,
      addUserContent: false,
      addUserData: [],
      searchUserValue: '',
      addUserIndex: null,
      clickedInside: false,
      scrollPageAddUser: 1,
      scrollBarThumb: false,
      errorNameOnlySpace: false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handlerClickInside(event){
    this.setState({
      addUserContent: false
    });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        addUserContent: false
      });
    }
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setWrapperRefButton(node){
    this.wrapperRefButton = node;
  }


  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        questionModal: false,
        endedModal: false,
        modalData: {},
        modalSurvey: false,
        modalDeleteSurvey: false,
        idDeleteSurvey: null,
        addUserContent: false,
        errorNameOnlySpace: false
      }));
    }, 500);
  }
  handlerTooltip(type, event){
    this.setState({
      tooltip: type
    });
  }
  handlerAddQuestionModal (event) {
    event.preventDefault();
    this.setState(prevState => ({
      modal: !prevState.modal,
      questionModal: !prevState.questionModal,
      modalClass: null,
      modalData: {}
    }))
  }
  handlerModalEnded (event) {
    event.preventDefault();
    this.setState(prevState => ({
      modal: !prevState.modal,
      endedModal: !prevState.endedModal,
      modalClass: null,
      modalData: {}
    }))
  }
  chooseTypeQuestion (type, event) {
    event.preventDefault();
    this.setState(prevState => ({
      typeQuestion: type
    }))
  }

  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }

  handlerQuestionCreateSubmit (event) {
    event.preventDefault();
    let error = false;
    if(this.state.surveyNameError || this.state.surveyTypeError){
      this.setState({
        surveyNameError: false,
        surveyTypeError: false
      });
    }
    if(!this.state.surveyName){
      this.setState({
        surveyNameError: true
      });
    }
    if(!this.state.typeQuestion){
      this.setState({
        surveyTypeError: true
      });
    }

    let nameValue = this.validItemEmpty('surveyName');
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }

    if(nameValue && this.state.typeQuestion && !error){
      if(typeof window !== "undefined" && typeof document !== 'undefined'){
        localStorage.setItem("surveyName", nameValue);
      }
      this.setState(prevState => ({
        success: true
      }));
    }
  }
  handlerSurveyName (event) {
    if(this.state.errorNameOnlySpace){
      this.setState({
        errorNameOnlySpace: false
      });
    }
    if(this.state.surveyNameError){
      this.setState({
        surveyNameError: false
      });
    }
    this.setState({
      surveyName: event.target.value
    });
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    let that = this;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/surveys/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "itemsPerPage": 20,
        "surveyType": "NORMAL",
        "orderField": "creationDate"
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          })
        });
      } else {
        res.json().then(function(data) {
          that.setState({
            surveyNormal: data.data,
            totalPages: data.totalPages,
            totalItemsDefault: data.totalItems
          });
        });
          fetch(`${that.props.queryLink}/api/v1/surveys/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "itemsPerPage": 20,
              "surveyType": "QUICK"
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                })
              });
            } else {
              res.json().then(function(data) {
                that.setState({
                  isLoaded: true,
                  totalItemsQuick: data.totalItems
                });
              });
            }
          })
      }
    })
  })
  }

  handlerSearch(event) {
    let that = this;
      this.setState({
      isLoadedSearch: true,
      searchSurveyValue: event.target.value,
      searchEmpty: false
    });
    let timeoutId = setTimeout(() => {
      getAuth().currentUser.getIdToken().then(function(idToken) {
              const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/surveys/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({"itemsPerPage": 20, "surveyType": "NORMAL", "query": that.state.searchSurveyValue})
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false
              });
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedSearch: false,
                surveyNormal: data.data,
                totalPages: data.totalPages,
                totalItemsDefault: data.totalItems
              });
              if(data.data.length < 1){
                that.setState({
                  searchEmpty: true
                });
              }
            });
          }
        });
      });
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = timeoutId;
  }

  handlerOpenEnded (index, event) {
    if(!event.target.closest('.tgl') && !event.target.closest('.tgl-btn')) {
      event.preventDefault();
      this.setState(prevState => ({
        openRow: index
      }))
    }
  }
  handlerEnabledSurvey (id, index, event) {
    let that = this;
    let arr = that.state.surveyNormal;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/surveys/${id}/enable`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({"enable": !arr[index].enabled})
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
      } else {
        arr[index].enabled = !arr[index].enabled;
          that.setState(prevState => ({
            surveyNormal: arr
          }))
      }
    })
    })
  }
  handlerDeleteSurveyModal(id, index, event){
      this.setState({
        modalDeleteSurvey: true,
        removeIndex: index,
        idDeleteSurvey: id
      });
  }
  hanlderSurveyDelete (event) {
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/surveys/${that.state.idDeleteSurvey}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
      } else {
        that.handleModalDisabled();
        that.setState({
          indexRowSurveyQuick: that.state.removeIndex
        });
        fetch(`${that.props.queryLink}/api/v1/surveys/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "itemsPerPage": 20,
            "page": that.state.surveyNormal.length < 2 ? 1 : that.state.currentPage,
            "surveyType": "NORMAL"
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                isLoaded: true,
                errors: data.errors,
                error: true,
                loadingDeleteSumbit: false
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                indexRowSurveyQuick: null,
                removeIndex: null,
                surveyNormal: data.data,
                totalPages: data.totalPages,
                totalItemsDefault: data.totalItems,
                loadingDeleteSumbit: false
              });
            });
                fetch(`${that.props.queryLink}/api/v1/surveys/search`, {
                  method: 'POST',
                  headers: myHeaders,
                  body: JSON.stringify({
                    "surveyType": "QUICK"
                  })
                }).then(res => {
                  if(res.status !== 200) {
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      })
                    });
                  } else {
                    res.json().then(function(data) {
                      that.setState({
                        totalItemsQuick: data.totalItems
                      })
                    });
                  }
                })
          }
        })
      }
    })
  })
  }
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSurveySubmit: null
    }));
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      localStorage.removeItem('successSurvey');
    }
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerPaginationItems = (items, current) => {
    this.setState({
      surveyNormal: items,
      currentPage: current
    });
  }
  handlerModalGetSurvey(id, index, event) {
      let that = this;
      this.setState(prevState => ({
        scrollBarThumb: false
      }));
      if(!event.target.closest('.control-wgt')) {
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/surveys/${id}`, {
          method: 'GET',
          headers: myHeaders,
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState(prevState => ({
                dataSurveyModal: data,
                modalSurvey: true
              }));
              setTimeout(function() {
                if(typeof window !== "undefined" && typeof document !== 'undefined'){
                  let infoDiv = document.querySelector('.modal__order-content-col-info');
                  if(infoDiv){
                    let height = infoDiv.clientHeight;
                    that.setState(prevState => ({
                      heightInfo: height
                    }));
                  }
                }
              }, 500);
            })
          }
        })
      })
    }
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }

  handleScroll = ({ scrollTop, scrollHeight, clientHeight }) => {
    let that = this;
    if (scrollTop + clientHeight === scrollHeight) {
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/surveys/${that.state.dataSurveyModal.surveyID}/invitation/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "page": that.state.scrollPage
        })
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            let dataModal = that.state.dataSurveyModal;
            let scrollPage = that.state.scrollPage;
            that.setState(prevState => ({
              dataSurveyModal: dataModal,
              scrollPage: scrollPage.scrollPage + 1
            }))
          })
        }
      })
    })
    }
  }

  handleScrollAddUsers = ({ scrollTop, scrollHeight, clientHeight }) => {
    let that = this;
    if (scrollTop + clientHeight === scrollHeight) {
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(
          {
            "itemsPerPage": 20,
            "page": that.state.scrollPageAddUser
          }
        )
      }).then(res => {
        if(res.status === 200) {
          res.json().then(function(data) {
            let usersData = that.state.addUserData;
            let newUsers = usersData.concat(data.data);
            usersData.push(data.data);
            that.setState(prevState => ({
              addUserData: newUsers,
              scrollPageAddUser: prevState.scrollPageAddUser + 1
            }));
          });
        } else if(res.status === 401) {
          res.json().then(function(data) {

          });
        } else {
          res.json().then(function(data) {

          });
        }
      })
    })

    }
  }

  handlerReminder(id, event) {
    let that = this;
    let now = Date.now();
    let canSubmit = false;
    let arr;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      arr = localStorage.getItem('reminder');
    }


    if (arr) {
      arr = JSON.parse(arr);
    } else {
      arr = [];
    }

    function findIndexById(array, id) {
      return array.findIndex(function (element) {
        return element.id === id;
      });
    }

    let index = findIndexById(arr, id);

    if (!arr || index === -1 || Date.now() > arr[index].time) {
      canSubmit = true;
    } else {
      canSubmit = false;
    }

    if (canSubmit) {
      getAuth().currentUser.getIdToken().then(function (idToken) {
        const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
        });

        fetch(`${that.props.queryLink}/api/v1/surveys/${that.state.dataSurveyModal.surveyID}/reminder`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "invitationID": id
          })
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function (data) {
              that.setState({
                errors: data.errors
              });
            });
          } else {
            let success = {};
            success.status = true;
            success.text = that.props.getTranslate('successStatus-Send');
            that.setState({
              successSurveySubmit: success
            });
            if (!arr) {
              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                localStorage.setItem('reminder', JSON.stringify([{ id: id, time: (now + 60000) }]));
              }
            } else {
              if (index !== -1) {
                arr[index].time = now + 60000;
              } else {
                arr.push({ id: id, time: now + 60000 });
              }
              if(typeof window !== "undefined" && typeof document !== 'undefined'){
                localStorage.setItem('reminder', JSON.stringify(arr));
              }
            }
          }
        });
      });
    }
  }

  handlerGetSurveyStatus(status, event){
    let that= this;
    that.setState({
      isSearch: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/surveys/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "itemsPerPage": 20,
        "surveyType": "NORMAL",
        "surveyStatus": status
      })
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          })
        });
      } else {
        res.json().then(function(data) {
          that.setState({
            surveyNormal: data.data,
            totalPages: data.totalPages,
            totalItemsDefault: data.totalItems
          });
        });
      }
    })
  })
  }
  handlerAddUsers(event){
    let that = this;
    this.setState(prevState => ({
      addUserContent: !prevState.addUserContent
    }));
    if(this.state.addUserData.length < 1){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/users/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(
            {
              "itemsPerPage": 20
            }
          )
        }).then(res => {
          if(res.status === 200) {
            res.json().then(function(data) {
              that.setState(prevState => ({
                addUserData: data.data
              }));
            });
          } else if(res.status === 401) {
            res.json().then(function(data) {

            });
          } else {
            res.json().then(function(data) {

            });
          }
        })
      })
    }
  }
  handlerSearchUsers(event){
    let that = this;
    this.setState({
      searchUserValue: event.target.value
    });
    let timeoutId = setTimeout(() => {
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/users/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(
          {
            "itemsPerPage": 20,
            "query": that.state.searchUserValue
          }
        )
      }).then(res => {
        if(res.status === 200) {
          res.json().then(function(data) {
            that.setState({
              addUserData: data.data,
            })
          });
        }
      })
    })
    }, 500);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = timeoutId;
  }
  handlerAddUser(id, index, event){
    let that = this;
    that.setState({
      addUserIndex: index
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/surveys/${that.state.dataSurveyModal.surveyID}/invitations`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "userID": id
        })
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function (data) {
            that.setState({
              errors: data.errors,
              addUserIndex: null
            });
          });
        } else {
          let users = that.state.addUserData;
          users.splice(index, 1);
          let dataModal = that.state.dataSurveyModal;
          res.json().then(function (data) {
            dataModal.invitations.unshift(data);
            that.setState({
              addUserIndex: null,
              addUserData: users,
              dataSurveyModal: dataModal
            });
          });
        }
      });
    })
  }
  hanlderSurveyDeleteId(id, event){
    this.setState({
      idDeleteSurvey: id
    });
    this.hanlderSurveyDelete();
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('polls-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.successSurveySubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let statusTextModal = '';
    if(this.state.dataSurveyModal.surveyStatus === 'ACTIVE'){
      statusTextModal = this.props.getTranslate('polls-StatusActive');
    } else if (this.state.dataSurveyModal.surveyStatus === 'NEW') {
      statusTextModal = this.props.getTranslate('polls-StatusWait');
    } else if (this.state.dataSurveyModal.surveyStatus === 'DELETED') {
      statusTextModal = this.props.getTranslate('polls-StatusDelete');
    } else {
      statusTextModal = this.props.getTranslate('polls-StatusEnded');
    }

    let resultSurvey = '';
    if(this.state.error) {
      resultSurvey = <div>{this.props.getTranslate('error-GlobalError')}</div>;
    } else if(!this.state.isLoaded) {
      resultSurvey = <Loading />;
    } else {
      resultSurvey = <div className="account-wgt account-wgt__questions">

      {/* Table component */}
        <Table
          header={[
            this.props.getTranslate('polls-Name'),
            this.props.getTranslate('polls-Users'),
            this.props.getTranslate('polls-Date'),
            this.props.getTranslate('polls-Progress'),
            this.props.getTranslate('polls-Bonus'),
            this.props.getTranslate('polls-Status'),
            '',
            ''
          ]}
          handlerRow={this.handlerModalGetSurvey.bind(this)}
          bodyData={this.state.surveyNormal}
          bodyDataNames={['title', 'employeeCount', ['startTime', 'endTime'], 'progress', 'bonus', 'surveyStatus', 'pollsResult']}
          controlItemsStatus={true}
          controlItems={{
            'enabled': {'status': true, 'handler': this.handlerEnabledSurvey.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusLabel'), 'show': this.props.getTranslate('tableDropdown-StatusLabelShow')}},
            'editButton': false,
            'editLink': {
              'status': true,
              'handler': this.props.getPermalink(`/account/surveys/edit/default/`),
              'label': this.props.getTranslate('tableDropdown-EditLabel')
            },
            'delete': {
              'status': true,
              'handler': this.handlerDeleteSurveyModal.bind(this),
              'label': this.props.getTranslate('tableDropdown-EditRemove')
            }
          }}
          translationValueItems={{
              'ACTIVE': this.props.getTranslate('polls-StatusActive'),
              'NEW': this.props.getTranslate('polls-StatusWait'),
              'DELETED': this.props.getTranslate('polls-StatusDelete'),
              'DONE': this.props.getTranslate('polls-StatusEnded')
            }}
            dateValueFormat={{
              start: 'startTime',
              end: 'endTime'
            }}
          labelsStatus={{
            'surveyStatus': {
              'ACTIVE': 'blue',
              'NEW': 'yellow',
              'DELETED': 'red',
              'DONE': 'green',
            }
          }}
          pollsToResult={this.props.getPermalink(`/account/surveys/result/`)}
          pollsToResultTxt={this.props.getTranslate('polls-ResultsTitle')}
          itemIDName={'surveyID'}
          itemEnableName={'enabled'}
          enabledMobileContent={true}
          mobileContentType={'block'}
          mobileContentHeader={[
            this.props.getTranslate('polls-Users'),
            this.props.getTranslate('polls-Date'),
            this.props.getTranslate('polls-Progress'),
            this.props.getTranslate('polls-Status')
          ]}
          currencyLabel={{
            'bonus': this.props.currency
          }}
          hightlightsCol={['bonus']}
          mobileContentData={['title', 'employeeCount', ['startTime', 'endTime'], 'progress', 'surveyStatus', 'pollsResult']}
          removeIndex={this.state.removeIndex}
        />

      </div>;
    }
    return <>
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.totalItemsDefault === 0 && this.state.totalItemsQuick > 0 && this.state.isLoaded && !this.state.isSearch ? <Navigate to={this.props.getPermalink('/account/surveys/quick')} replace={true} /> : '') : ''}

    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Query result handler */}
    {this.state.successSurveySubmit && <ResultQuery
      success={this.state.successSurveySubmit.status}
      resultText={this.state.successSurveySubmit.text}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    />}

    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.success ? (this.state.typeQuestion === 1) ? <Navigate to={this.props.getPermalink('/account/surveys/create/default')} replace={true} /> : <Navigate to={this.props.getPermalink('/account/surveys/create/quick')} replace={true} /> : '') : ''}

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title={this.props.getTranslate('polls-Title')}
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

        {/* Tabs component */}
        <TabItems items={[
          {
            label: this.props.getTranslate('polls-TabRegular'),
            path: this.props.getPermalink("/account/surveys/default"),
            amount: this.state.totalItemsDefault
          },
          {
            label: this.props.getTranslate('polls-TabQuick'),
            path: this.props.getPermalink("/account/surveys/quick"),
            amount: this.state.totalItemsQuick
          }
        ]} />

        <div className="tab-navs">

          {/* Button for add polls */}
          <Button
            type="button"
            icon={true}
            iconItem="#plus-icon"
            text={this.props.getTranslate('polls-AddButton')}
            addClass="header-content-nav__btn"
            handler={this.handlerAddQuestionModal.bind(this)}
          />

          {/* Filter component */}
          <Filter
            className="library-filter-dropdown"
            buttonName={this.props.getTranslate('ordersFilter-Title')}
            dataSort={[
              {
                name: this.props.getTranslate('pollsFilterStatus-All'),
                value: null
              },
              {
                name: this.props.getTranslate('pollsFilterStatus-New'),
                value: 'NEW'
              },
              {
                name: this.props.getTranslate('pollsFilterStatus-Active'),
                value: 'ACTIVE'
              },
              {
                name: this.props.getTranslate('pollsFilterStatus-Done'),
                value: 'DONE'
              },
            ]}
          handler={this.handlerGetSurveyStatus.bind(this)}
          labelDisabled={true}
        />

          {/* Filter search input component */}
          <FilterSearch
            placeholder={this.props.getTranslate('pollsSearch-Placeholder')}
            value={this.state.searchSurveyValue}
            handler={this.handlerSearch.bind(this)}
          />

        </div>


        {this.state.totalItemsDefault === 0 && this.state.totalItemsQuick === 0 && this.state.isLoaded && !this.state.error ? <>
          <div className="account-content__content account-content__content_empty">
            <div className="empty-wrapper">

              {/* If the response is empty */}
              <AccountContentEmpty
                img={survey_img}
                title={this.props.getTranslate('surveys-EmptySurveysTitle')}
                description={this.props.getTranslate('surveys-EmptySurveysDescription')}
                buttonType="button"
                buttonText={this.props.getTranslate('polls-AddButton')}
                buttonIcon="#plus-icon"
                buttonHandler={this.handlerAddQuestionModal.bind(this)}
              />

            </div>
            </div></> : <><div className="account-content__content">

            {/* Query result */}
            {resultSurvey}

          </div></>}



        <div className="account-content__footer">

          {/* Pagination component */}
          <Pagination
            total={this.state.totalPages}
            perPage="20"
            path={`${this.props.queryLink}/api/v1/surveys/search`}
            currentPage={this.state.currentPage}
            otherParams={{"surveyType": 'NORMAL'}}
            items={this.handlerPaginationItems}
          />

        </div>
      </div>
    </div>
    </div>
    {this.state.modal && this.state.questionModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.modalClass}` : `two ${this.state.modalClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('polls-AddTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <form onSubmit={this.handlerQuestionCreateSubmit.bind(this)}>
          <div className="field-wrap">
            <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="name">{this.props.getTranslate('polls-Caption')}</label>
            <input id="name" className={`field-wrap__input ${this.state.surveyNameError || this.state.errorNameOnlySpace ? 'field-wrap__input_error' : ''}`} type="text" placeholder={this.props.getTranslate('polls-CaptionFieldCaption')} value={this.state.surveyName} onChange={this.handlerSurveyName.bind(this)} />
            {this.state.surveyNameError && <div className="validation-error">{this.props.getTranslate('polls-ErrorNameCreate')}</div>}
            {this.state.errorNameOnlySpace && <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div>}
          </div>
          <div className="field-wrap">
            <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('polls-Type')}</label>
            <div className={`choose-type-question ${this.state.typeQuestion === 1 ? 'active' : ''}`} onClick={this.chooseTypeQuestion.bind(this, 1)}>
              <div className="choose-type-question__inner">
                <div className={`choose-type-question__field ${this.state.surveyTypeError ? 'field-wrap__input_error' : ''}`}>{this.props.getTranslate('polls-TypeStandart')}</div>
                <div className={`choose-type-question__icon ${this.state.surveyTypeError ? 'field-wrap__input_error' : ''}`} onMouseEnter={this.handlerTooltip.bind(this, 'Default')} onMouseLeave={this.handlerTooltip.bind(this, false)}>
                  <svg className="choose-type-question">
                    <use href={`${sprite}#phone-company`}></use>
                  </svg>
                  {this.state.tooltip === 'Default' && <div className="tooltip-img-wrap"><img className="tooltip-img-wrap__img" src={tooltipDefault} alt="" /></div>}
                </div>
              </div>
            </div>
            <div className={`choose-type-question choose-type-question__quick ${this.state.typeQuestion === 2 ? 'active' : ''}`} onClick={this.chooseTypeQuestion.bind(this, 2)}>
              <div className="choose-type-question__inner">
                <div className={`choose-type-question__field ${this.state.surveyTypeError ? 'field-wrap__input_error' : ''}`}>{this.props.getTranslate('polls-TypeQuick')}</div>
                <div className={`choose-type-question__icon ${this.state.surveyTypeError ? 'field-wrap__input_error' : ''}`} onMouseEnter={this.handlerTooltip.bind(this, 'Quick')} onMouseLeave={this.handlerTooltip.bind(this, false)}>
                  <svg className="choose-type-question">
                    <use href={`${sprite}#phone-company`}></use>
                  </svg>
                  {this.state.tooltip === 'Quick' && <div className="tooltip-img-wrap"><img className="tooltip-img-wrap__img" src={tooltipQuick} alt="" /></div>}
                </div>
              </div>
            </div>
            {this.state.surveyTypeError && <div className="validation-error">{this.props.getTranslate('polls-ErrorTypeCreate')}</div>}
          </div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_width_100" type="submit">{this.props.getTranslate('polls-AddQuestionButton')}</button>
          </div>
        </form>
      </div>
    </div> : ''}
    {this.state.modal && this.state.endedModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.modalClass}` : `two ${this.state.modalClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">Wizz Air 50 eur</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <table className="modal-questions-ended">
          <tbody>
            <tr>
              <td>11.02.22</td>
              <td className="modal-questions-ended__type modal-questions-ended__type_active">Активный</td>
              <td>
                <Link className="questions-table__result" to={this.props.getPermalink("/account/result-questions/1")}>Результаты</Link>
              </td>
            </tr>
            <tr>
              <td>11.02.22</td>
              <td className="modal-questions-ended__type modal-questions-ended__type_ended">Закончился</td>
              <td>
                <Link className="questions-table__result" to={this.props.getPermalink("/account/result-questions/1")}>Результаты</Link>
              </td>
            </tr>
            <tr>
              <td>11.02.22</td>
              <td className="modal-questions-ended__type modal-questions-ended__type_ended">Закончился</td>
              <td>
                <Link className="questions-table__result" to={this.props.getPermalink("/account/result-questions/1")}>Результаты</Link>
              </td>
            </tr>
            <tr>
              <td>11.02.22</td>
              <td className="modal-questions-ended__type modal-questions-ended__type_ended">Закончился</td>
              <td>
                <Link className="questions-table__result" to={this.props.getPermalink("/account/result-questions/1")}>Результаты</Link>
              </td>
            </tr>
            <tr>
              <td>11.02.22</td>
              <td className="modal-questions-ended__type modal-questions-ended__type_ended">Закончился</td>
              <td>
                <Link className="questions-table__result" to={this.props.getPermalink("/account/result-questions/1")}>Результаты</Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> : ''}
    {this.state.modalSurvey ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal modal__order modal_pr_n">
        <div className="modal-header">
          <h2 className="modal-headline">{this.state.dataSurveyModal.title}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="modal-nav">
          <div className="modal-nav__item">
            <Link className="btn btn_size_lg btn_theme_secondary" to={this.props.getPermalink(`/account/surveys/result/${this.state.dataSurveyModal.surveyID}`)}>{this.props.getTranslate('surveyModal-ResultButton')}</Link>
          </div>
          <div className="modal-nav__item">
            <Link className="btn-edit" to={this.props.getPermalink(`/account/surveys/edit/default/${this.state.dataSurveyModal.surveyID}`)}>
              <svg className="btn-edit__icon">
                <use href={`${sprite}#edit-icon`}></use>
              </svg>
            </Link>
          </div>
          <div className="modal-nav__item">
            <button className="btn-edit btn-edit_theme_remove" onClick={this.hanlderSurveyDeleteId.bind(this, this.state.dataSurveyModal.surveyID)}>
              <svg className="btn-edit__icon">
                <use href={`${sprite}#delete-red`}></use>
              </svg>
            </button>
          </div>
        </div>
        <div className="modal__order-content">
          <div className="modal__order-content-col modal__order-content-col-info">
            <div className="modal__order-info-headline">{this.props.getTranslate('polls-modalInfoTitle')}</div>
            <div className="modal-event-content">
              <div className="modal-event-content__inner">
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-DateCreatedTitle')}:</div>
                  <div className="modal-event-content__value"><DateFormating start={this.state.dataSurveyModal.creationDate} /></div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-DateTitle')}:</div>
                  <div className="modal-event-content__value"><DateFormating start={this.state.dataSurveyModal.startTime} end={this.state.dataSurveyModal.endTime} /></div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-UsersCountTitle')}:</div>
                  <div className="modal-event-content__value">{this.state.dataSurveyModal.users.length}</div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-TypeTitle')}:</div>
                  <div className="modal-event-content__value">{this.state.dataSurveyModal.anonymous ? this.props.getTranslate('surveyModal-TypeAnonymousValue') : this.props.getTranslate('surveyModal-TypeAnonymousValue')}</div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-QuestionsCountTitle')}:</div>
                  <div className="modal-event-content__value">{this.state.dataSurveyModal.questions ? this.state.dataSurveyModal.questions.length : 0}</div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-BonuseTitle')}:</div>
                  <div className="modal-event-content__value">{this.state.dataSurveyModal.bonus}</div>
                  <div className="modal-event-content__headline">{this.props.getTranslate('surveyModal-StatusTitle')}:</div>
                  <div className="modal-event-content__value">{statusTextModal}</div>
                </div>
              </div>
          </div>
          <div className="modal__order-content-col modal__order-content-col-change">
            {this.state.heightInfo !== 'auto' ? <div className="modal__order-content-history">
              <div className="modal__order-info-headline modal__order-info-headline_bn"><div className="modal__order-info-headline-inner">{this.props.getTranslate('polls-modalUsersTitle')}<span className="users-tab__link-amount">{this.state.dataSurveyModal.employeeCount}</span>
              <div className="modal-add-user"><button className="modal-add-user__btn" type="button" onClick={this.handlerAddUsers.bind(this)}><span className="modal-add-user__icon">
                <svg>
                  <use href={`${sprite}#plus-icon`}></use>
                </svg>
              </span></button>
                <div className={`modal-add-user__content${this.state.addUserContent ? ' active' : ''}`} ref={this.setWrapperRef}>
                <div className="field-wrap__search-members-wrap">
                  <div className="field-wrap field-wrap__search-members">
                    <input className="field-wrap__input" type="text" value={this.state.searchUserValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
                    <svg className="field-wrap__search-icon">
                      <use href={`${sprite}#search-icon`}></use>
                    </svg>
                  </div>
                </div>
                <Scrollbars
                    onScrollFrame={this.handleScrollAddUsers}
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                      autoHeight
                      renderView={props => <div {...props} />}>
                {this.state.addUserData.map((item, index) => {
                  const fullName = (item.name ? (item.name + ' ') : '') + '' + (item.middleName ? (item.middleName + ' ') : '') + '' + (item.lastName ? item.lastName : '');
                  const userExists = this.state.dataSurveyModal.invitations.find(user => user.userID === item.userID);
                  if(!userExists){
                    return <div className="user-modal-wgt__info" key={index} onClick={this.handlerAddUser.bind(this, item.userID, index)}>
                    <img className="user-modal-wgt__img" src={item.photo} alt="" />
                    <span className="user-modal-wgt__name">{fullName}</span>
                    {this.state.addUserIndex === index ? <Loading /> : ''}
                  </div>
                }
              })}</Scrollbars>
              </div>
              </div>
              </div>
              </div>
              <div style={{height: this.state.heightInfo}}>
              <Scrollbars
                  className="scrollbar-users"
                  onScrollFrame={this.handleScroll}
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                  renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                  renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={this.state.heightInfo}
                    renderView={props => <div {...props} />}>
                    <div className={`modal__scroll-content${this.state.scrollBarThumb ? ' modal__scroll-content-thumb' : ''}`}>
                    {this.state.dataSurveyModal.invitations ? <>
                      {this.state.dataSurveyModal.invitations.map((item, index) => {
                        let iconStatus = '';
                        if(item.invitationStatus === 'NEW'){
                          iconStatus = <div className="tooltip-wgt"><svg className="users-modal__check"><use href={`${sprite}#status-modal-waiting`}></use></svg>
                            <div className="tooltip-wgt__content">
                              <p>Очікує</p>
                            </div>
                          </div>;
                        } else {
                            iconStatus = <div className="users-modal__check-wrap tooltip-wgt"><svg className="users-modal__check"><use href={`${sprite}#check-icon`}></use></svg>
                                <div className="tooltip-wgt__content">
                                  <p>Підтвердив</p>
                              </div>
                            </div>;
                        }
                        return <div className="users-modal-wgt" key={index}>
                          <div className="users-modal-wgt__inner">
                            <Link className="user-modal-wgt__info" to={this.props.getPermalink(`/account/users/view/${item.userID}`)}>
                              <img className="user-modal-wgt__img" src={item.userPhoto} alt="" />
                              <span className="user-modal-wgt__name">{item.userName}</span>
                            </Link>
                            <div className="users-modal-wgt__nav">
                            <div className="tooltip-wgt"><button className="user-modal-wgt__notification" type="button" onClick={this.handlerReminder.bind(this, item.invitationID)}><svg className="user-modal-wgt__notification-icon"><use href={`${sprite}#notification-icon`}></use></svg></button>
                                <div className="tooltip-wgt__content">
                                  <p>{this.props.getTranslate('modal-notificationTitle')}</p>
                              </div>
                            </div>
                              {iconStatus}
                            </div>
                          </div>
                        </div>
                      })}</> : ''}
                    </div>
              </Scrollbars>
              </div>
            </div> : <Loading />}
          </div>
        </div>
      </div>
    </div> : ''}

    {/* Delete item modal */}
    <DeleteModal
      modalStatus={this.state.modalDeleteSurvey}
      modalDisabledStatus={this.state.modalDisabled}
      modalDisabledClass={this.state.addClass}
      handlerModalDisabled={this.handleModalDisabled}
      modalTitle={this.props.getTranslate('modal-deletePollsTitle')}
      modalDescription={this.props.getTranslate('modal-deletePollsDescription')}
      modalHandlerDelete={this.hanlderSurveyDelete.bind(this)}
      modalHandlerBtnTxt={this.props.getTranslate('removeModal-ButtonSubmit')}
      modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
    />

    </>;
  }
}
export default SurveysDefault;
