import React from "react";
import { Link } from "react-router-dom";
import Header from './components/Header';
import Footer from './components/Footer';
import BlogHeader from './components/BlogPage/BlogHeader';
import BlogContent from './components/BlogPage/BlogContent';
import sprite from './media/icons.svg';
class Blog extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        blogData: [],
        categoriesData: [],
        currentPage: 0,
        totalPages: null,
        categoryCurrent: null
      };
    }

    /* Pagination handler */
    handlerPaginationItems = (bonusesItems, current) => {
      this.setState({
        blogData: bonusesItems,
        currentPage: current
      });
    }

    handlerUpdateLocal(local, event) {
      let that = this;
        const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Accept-Language': local
        });

        fetch(`${that.props.queryLink}/bo/v1/external/configs/blogs/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "blogType": "GLOSSARY",
            "direction": "DESC",
            "itemsPerPage": 14
          })
        }).then(res => {
          if (res.status === 200) {
            res.json().then(function (data) {
              that.setState({
                blogData: data.data
              });
            })
          }
        });
    }

    componentDidMount() {
      let location;
      let language = 'uk-UA';
      if(typeof window !== "undefined" && typeof document !== 'undefined') {
        location = document.location.pathname;
        const isEnglish = location.startsWith('/en');
        if (isEnglish) {
          language = 'en-US'
        }
        window.scrollTo(0, 0);
      }
        let that = this;
        const blogSearch = async (formData) => {
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Accept-Language': language
          });
          fetch(`${that.props.queryLink}/bo/v1/external/configs/blogs/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "blogType": "GLOSSARY",
              "direction": "DESC",
              "itemsPerPage": 14
            })
          }).then(res => {
            if (res.status === 200) {
              res.json().then(function(data) {
                that.setState({
                  totalPages: data.totalPages,
                  blogData: data.data
                });
              })
            }
          })
        }
        const blogCategories = async (formData) => {
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Accept-Language': language
          });
          fetch(`${that.props.queryLink}/bo/v1/external/configs/blogs/categories`, {
            method: 'GET',
            headers: myHeaders
          }).then(res => {
            if (res.status === 200) {
              res.json().then(function(data) {
                that.setState({
                  categoriesData: data
                });
              })
            }
          })
        }
      const postBlogSearch = async () => {
        await blogSearch();
      };
      const getCategories = async () => {
        await blogCategories();
      };
      postBlogSearch()
        .then(() => {
          return getCategories();
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    handlerSortCategories(category, event){
      event.preventDefault();
      that.setState({
        categoryCurrent: category
      })
      let that = this;
          const myHeaders = new Headers({
              'Content-Type': 'application/json'
          });
          fetch(`${that.props.queryLink}/bo/v1/external/configs/blogs/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "blogType": "GLOSSARY",
              "category": category,
              "direction": "DESC",
              "itemsPerPage": 14
            })
          }).then(res => {
            if (res.status === 200) {
              res.json().then(function(data) {
                that.setState({
                  blogData: data.data
                });
              })
            }
          })
    }
  render() {
    let location;
    let isEnglish;
    let targetPath;
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      location = window.location.pathname;
      isEnglish = location.startsWith('/en');
      if (isEnglish) {
        targetPath = location === '/en' ? '/' : location.substring('/en'.length);
      } else {
        targetPath = `/en${location}`;
      }
      let title;
      if(typeof window !== "undefined" && typeof document !== 'undefined'){
        title = this.props.getTranslate('blogPage-Title');
        document.title = title ? title : 'MateSpace.io';
        const metaDescription = document.querySelector('meta[name="description"]');
        let description = this.props.getTranslate('blogPage-Description');
        if (metaDescription) {
          metaDescription.setAttribute('content', description ? description : 'MateSpace.io');
        }
      }
    }
    return <>
    {this.props.PageWithCanonical}
    <Header getPermalink={this.props.getPermalink} logged={this.props.logged} userPhoto={this.props.userPhoto} userName={this.props.userName} handlerUpdateLocal={this.handlerUpdateLocal.bind(this)} handlerChangeLanguage={this.props.handlerChangeLanguage} chooseLanguage={this.props.chooseLanguage} getTranslate={this.props.getTranslate} />
    <main>
      <section className="blog-list">
        <div className="wrapper">
          <div className="breadcrumbs breadcrumbs__blog-list">
            <Link className="breadcrumbs__item breadcrumbs__link" to={this.props.getPermalink("/")}>Головна</Link>
            <span className="breadcrumbs__sep">
              <svg className="breadcrumbs__sep-icon">
                <use href={`${sprite}#right-icon`}></use>
              </svg>
            </span>
            <span className="breadcrumbs__item breadcrumbs__active">Глосарій</span>
          </div>
        </div>
      </section>
      <BlogHeader handlerSortCategories={this.handlerSortCategories.bind(this)} categories={this.state.categoriesData} type="glossary" />
      <BlogContent getPermalink={this.props.getPermalink} blogData={this.state.blogData} isEnglish={isEnglish} type="glossary" handlerPaginationItems={this.handlerPaginationItems} currentPage={this.state.currentPage} totalPages={this.state.totalPages} path={`${this.props.queryLink}/bo/v1/external/configs/blogs/search`} otherParams={{category: this.props.categoryCurrent, blogType: 'GLOSSARY', direction: 'DESC'}}/>
    </main>
    <Footer getPermalink={this.props.getPermalink} getTranslate={this.props.getTranslate} />
    </>;
  }
}
export default Blog;
