import React from "react";
import { Link } from "react-router-dom";
import BlogWgt from './BlogWgt';
import DateFormating from '../DateFormating';
import Pagination from '../Pagination';
import sprite from '../../media/icons.svg';
class BlogContent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let blogData = this.props.blogData;
    const blogDataFirst = blogData.slice(0, 4);
    const blogDataSecond = blogData.slice(4, 6);
    const blogDataThird = blogData.slice(6);
    return <section className="blog-page-content">
      <div className="wrapper">
          {blogDataFirst && blogDataFirst.length > 0 ? <div className="blog-page-content__row">
            {blogDataFirst.map((item, index) => {
              return <BlogWgt
                key={index}
                to={this.props.getPermalink(`${this.props.type === 'blog' ? '/blog/' : '/glossary/'}${item.link}`)}
                headline={item.name}
                category={item.category}
                date={<DateFormating start={item.modificationDate} time="off" />}
                thumb={item.photoUrl}
              />
            })}
        </div> : ''}
        {blogDataSecond && blogDataSecond.length > 0 ? <div className="blog-page-content__row blog-page-content__row_lg">
          {blogDataSecond.map((item, index) => {
            return <BlogWgt
              key={index}
              to={this.props.getPermalink(`${this.props.type === 'blog' ? '/blog/' : '/glossary/'}${item.link}`)}
              headline={item.name}
              category={item.category}
              date={<DateFormating start={item.modificationDate} time="off" />}
              thumb={item.photoUrl}
            />
          })}
        </div> : ''}
        {blogDataThird && blogDataThird.length > 0 ? <div className="blog-page-content__row">
          {blogDataFirst.map((item, index) => {
            return <BlogWgt
              key={index}
              to={this.props.getPermalink(`/${this.props.type === 'blog' ? '/blog/' : '/glossary/'}/${item.link}`)}
              headline={item.name}
              category={item.category}
              date={<DateFormating start={item.modificationDate} time="off" />}
              thumb={item.photoUrl}
            />
          })}
      </div> : ''}
        <nav className="pagination-nav pagination-nav__blog">
        <Pagination
          total={this.props.totalPages}
          perPage="14"
          path={this.props.path}
          currentPage={this.props.currentPage}
          items={this.props.handlerPaginationItems}
          otherParams={this.props.otherParams}
        />
        </nav>
      </div>
    </section>;
  }
}
export default BlogContent;
