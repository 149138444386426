import React from "react";
import GetEmoji from './components/GetEmoji';
import DateFormating from './components/DateFormating';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Loading from './components/Loading';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { Link, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import CalendarDropdown from './components/CalendarDropdown';
import sprite from './media/icons.svg';
import img_preview from './media/item-preview-event.png';
import member_1 from './media/avatar-1.png';
import smile from './media/emoji-icon.svg';
import { getAuth } from "firebase/auth";
let Editor;
let EditorState;
let stateToHTML;
let ContentState;
let convertFromHTML;
let convertToRaw;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  convertToRaw = require('draft-js').convertToRaw;
  stateToHTML = require('draft-js-export-html').stateToHTML;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const SortableItem = SortableElement(({item, value, handlerDelete}) => <div><button className="edit-product-img__remove" type="button" onClick={handlerDelete.bind(this, item)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><span className="product-num">{item + 1}</span>{item < 1 ? <span className="product-label">Обложка</span> : ''}<img src={URL.createObjectURL(value)} alt="" /></div>);

const SortableList = SortableContainer(({items, handlerDelete}) => {
  return (
    <div className="edit-product-img">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} item={index} index={index} value={value} handlerDelete={handlerDelete} />
      ))}
    </div>
  );
});
class EventsCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      getAllUsers: false,
      allUsers: false,
      getUsersIds: [],
      usersIds: [],
      isLoadedSubmit: false,
      headlineValue: '',
      descriptionValue: '',
      linkValue: '',
      repeatValue: '',
      startDate: '',
      endDate: '',
      placeValue: '',
      memberItems: '',
      addressValue: '',
      confirmation: false,
      open: false,
      typeValue: '',
      users: [],
      errorUser: null,
      isLoadedUser: false,
      modalDisabled: false,
      modal: false,
      modalAddMembers: false,
      chooseAllMembers: false,
      chooseMembersIds: [],
      contentState: '',
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      formData: null,
      percentValue: 0,
      searchValue: '',
      usersSearch: [],
      errorFields: false,
      errorFieldsName: false,
      smileValue: null,
      getSmileModal: false,
      chooseDateType: 'start',
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      objForSubmit: {},
      previewStatus: false,
      modalDeleteEvents: false,
      bonuseValue: '',
      bonuseConfirmation: false,
      sendNotifications: false,
      startDateCalendar: null,
      endDateCalendar: null,
      startTimeCalendar: '00:00',
      endTimeCalendar: '22:00',
      iterStartCalendar: null,
      iterFinishCalendar: null,
      weeklyDay: null,
      nthWeekday: null,
      dateNum: null,
      monthNum: null,
      changeStatus: false,
      errorNameOnlySpace: false,
      errorLinkOnlySpace: false,
      errorPlaceOnlySpace: false,
      errorAddressOnlySpace: false,
      bonuseValueNumError: false,
      bonuseValueCountError: false,
      getUsersIdsHover: []
    }
  }
  handlerDeleteEventModal(event){
    this.setState({
      modalDeleteEvents: true
    });
  }
  getNameDay(num){
    const days = [
      'неділя',
      'понеділок',
      'вівторок',
      'середа',
      'четвер',
      'п\'ятниця',
      'субота'
    ];
    return days[num];
  }
  getMonthsName(num){
    const monthes = [
      'січня',
      'лютого',
      'березня',
      'квітня',
      'травня',
      'червня',
      'липня',
      'серпня',
      'вересня',
      'жовтня',
      'листопада',
      'грудня'
    ];
    return monthes[num];
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let nowDate = new Date();
    let nowDateFull = nowDate.getDate() + '.' + (nowDate.getMonth() + 1) + '.' + nowDate.getFullYear();
    let weeklyDay = nowDate.getDay();
    let nthWeekday = this.getNthWeekdayOfMonth(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate()));
    this.setState( {
      startDateCalendar: nowDateFull,
      endDateCalendar: nowDateFull,
      weeklyDay: weeklyDay,
      nthWeekday: nthWeekday - 1,
      dateNum: nowDate.getDate(),
      monthNum: nowDate.getMonth()
    } );
    const div = document.querySelector( '.field-wrap-smile');
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if ( ! withinBoundaries ) {
          this.setState( {
            getSmileModal: false
          } );
        }
      });
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      formData: arrayMoveImmutable(prevState.formData, oldIndex, newIndex)
    }));
  }
  onEditorStateChange = (contentState) => {
    if(this.state.errorDescriptionOnlySpace){
      let s = contentState.getCurrentContent();
      const blocks = s.getBlockMap().toArray();
      const isOnlySpaces = blocks.every(block => block.getText().trim() === '');
      if (!isOnlySpaces) {
        this.setState({
          errorDescriptionOnlySpace: false
        });
      }
    }
    this.setState({
      contentState: contentState
    });
  };
  handlerGetAllUsers(){
    this.setState(prevState => ({
      getAllUsers: !prevState.getAllUsers,
      getUsersIds: [],
    }));
  }
  handlerToggleUser(index, name, id){
    if(this.state.getAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {id: id, name: name}];
    }
    this.setState(prevState => ({
      getUsersIds: arr
    }));
  }
  handlerRemoveAllUsers(){
    this.setState(prevState => ({
      getAllUsers: false,
      allUsers: false
    }));
  }
  handlerRemoveUser(id){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))]
    }
    this.setState(prevState => ({
      getUsersIds: arr,
      usersIDs: ids
    }));
  }
  handlerSetUsers(){
      let ids = [];
      if(this.state.getUsersIds){
        ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
      }
      this.setState(prevState => ({
        allUsers: this.state.getAllUsers ? true : false,
        usersIds: ids
      }));
    this.handleModalDisabled();
  }
  handlerHeadlineValue(event) {
    this.setState({
      headlineValue: event.target.value
    });
  }
  handlerRepeatEvent(event){
    this.setState({
      repeatValue: event.value
    });
  }
  handlerTypeEvent(event){
    this.setState({
      typeValue: event.value
    });
  }
  handlerFullDateValue(event){
    let dateArr = event.target.value.split(' - ');
    this.setState({
      startDate: dateArr[0],
      endDate: dateArr[1]
    });
  }
  handlerPlaceValue(event){
    if(this.state.errorPlaceOnlySpace){
      this.setState({
        errorPlaceOnlySpace: false
      });
    }
    this.setState({
      placeValue: event.target.value
    });
  }
  handlerAddressValue(event){
    if(this.state.errorAddressOnlySpace){
      this.setState({
        errorAddressOnlySpace: false
      });
    }
    this.setState({
      addressValue: event.target.value
    });
  }
  handlerLinkValue(event){
    if(this.state.errorLinkOnlySpace){
      this.setState({
        errorLinkOnlySpace: false
      });
    }
    this.setState({
      linkValue: event.target.value
    });
  }
  handlerConfirmation(event) {
    this.setState(prevState => ({
      confirmation: !prevState.confirmation
    }));
  }
  handlerOpen(event) {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }
  handlerBonuseValue(event) {
      const inputValue = event.target.value;
      if (inputValue === "") {
          this.setState({
              bonuseValue: "",
              bonuseValueNumError: false,
              bonuseValueCountError: false
          });
          return;
      }
      if (/^\d+(\.\d{0,2})?$/.test(inputValue)) {
          const floatValue = parseFloat(inputValue);
          if (!isNaN(floatValue)) {
              if (floatValue > 999999.99) {
                  this.setState({
                      bonuseValueCountError: true,
                      bonuseValueNumError: false
                  });
              } else {
                  this.setState({
                      bonuseValue: inputValue,
                      bonuseValueNumError: false,
                      bonuseValueCountError: false
                  });
              }
          } else {
              this.setState({
                  bonuseValueCountError: false,
                  bonuseValueNumError: true
              });
          }
      } else {
        const floatValue = parseFloat(inputValue);
        if (!isNaN(floatValue)) {
          this.setState({
              bonuseValueCountError: true
          });
        } else {
          this.setState({
              bonuseValueNumError: true
          });
        }
      }
  }


  handlerBonuseConfirmation(event){
    this.setState(prevState => ({
      bonuseConfirmation: !prevState.bonuseConfirmation
    }));
  }
  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }
  handlerNextPreview(type, event){
    if(type === 'back'){
      if(typeof window !== "undefined" && typeof document !== 'undefined'){
        document.querySelector('.account-preview__wgt_col-logo').innerHTML = '';
      }
    }
    let error = false;
    this.setState(prevState => ({
      errorFields: false,
      errorNameOnlySpace: false,
      errorDescriptionOnlySpace: false,
      errorLinkOnlySpace: false,
      errorPlaceOnlySpace: false,
      errorAddressOnlySpace: false
    }));
    let nameValue = this.validItemEmpty('headlineValue');
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    const contentState = this.state.contentState;
    let s;
    let blocks;
    let isOnlySpaces = true;
    if(contentState === ''){
      isOnlySpaces = true;
    } else {
      s = contentState.getCurrentContent();
      blocks = s.getBlockMap().toArray();
      isOnlySpaces = blocks.every(block => block.getText().trim() === '');
    }
    if(isOnlySpaces){
      this.setState({
        errorDescriptionOnlySpace: true
      });
    }
    let linkValue = this.validItemEmpty('linkValue');
    if(/^\s*$/.test(linkValue) && linkValue !== ''){
      error = true;
      this.setState({errorLinkOnlySpace: true });
    }
    let placeValue = this.validItemEmpty('placeValue');
    if(/^\s*$/.test(placeValue) && placeValue !== ''){
      error = true;
      this.setState({errorPlaceOnlySpace: true });
    }
    let addressValue = this.validItemEmpty('addressValue');
    if(/^\s*$/.test(addressValue) && addressValue !== ''){
      error = true;
      this.setState({errorAddressOnlySpace: true });
    }
    if(!error && nameValue && !isOnlySpaces && this.state.startDateCalendar && this.state.startTimeCalendar && (this.state.getAllUsers || this.state.usersIds.length > 0)){
      if(typeof window !== "undefined" && typeof document !== 'undefined'){
        document.body.classList.add('preview');
      }
      this.setState(prevState => ({
        previewStatus: !prevState.previewStatus
      }));
      let obj = {
        "address": this.state.addressValue,
        "allUsers": this.state.getAllUsers,
        "bonus": parseInt(this.state.bonuseValue),
        "bonusConfirmation": this.state.bonuseConfirmation,
        "description": (typeof window !== "undefined" && typeof document !== 'undefined') ? stateToHTML(this.state.contentState.getCurrentContent()) : '',
        "endTime": this.state.endDateCalendar ? (this.state.endDateCalendar.split('.').reverse().join('-') + ' ' + this.state.endTimeCalendar + ':00') : (this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.endTimeCalendar + ':00'),
        "link": this.state.linkValue,
        "eventStatus": "ACTIVE",
        "eventType": "EVENT",
        "openEvent": this.state.open,
        "place": this.state.placeValue,
        "sendNotifications": this.state.sendNotifications,
        "smile": this.state.smileValue,
        "startTime": this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.startTimeCalendar + ':00',
        "title": this.state.headlineValue,
        "userIds": this.state.usersIds
      };
      if(this.state.repeatValue){
        obj['recurringType'] = this.state.repeatValue;
      }
      obj.startTime = new Date(obj.startTime).toISOString();
      obj.endTime = new Date(obj.endTime).toISOString();
      this.setState({
        objForSubmit: obj
      });
      if(this.state.formData){
        this.handleFileSelect();
      }
    } else {
      this.setState({
        errorFields: true
      });
    }
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      setTimeout(() => {
        const errorElement = document.querySelector('.field-wrap__input_error');
        if (errorElement) {
            const offsetTop = errorElement.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: offsetTop, behavior: 'smooth' });
        }
      }, 100);
    }
  }
  handlerEventSubmit (event) {
    event.preventDefault();
    let that = this;
    this.setState({
      errorFields: false
    });
    if(this.state.headlineValue && this.state.startDateCalendar && this.state.startTimeCalendar){
          this.setState({
            isLoadedSubmit: true
          });
          getAuth().currentUser.getIdToken().then(function(idToken){
            let location;
            let language = 'uk-UA';
            if(typeof window !== "undefined" && typeof document !== 'undefined') {
              location = document.location.pathname;
              const isEnglish = location.startsWith('/en');
              if (isEnglish) {
                language = 'en-US'
              }
            }
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken,
              'Accept-Language': language
          });
          fetch(`${that.props.queryLink}/api/v1/events`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(that.state.objForSubmit)
          }).then(res => {
            if (res.status !== 200) {
              that.setState({
                isLoadedSubmit: false
              });
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors ? data.errors : data
                })
              });
            } else {
              res.json().then(function(data) {
                if(!that.state.formData){
                  if(typeof window !== "undefined" && typeof document !== 'undefined'){
                    localStorage.setItem("successEventSubmit", JSON.stringify({status: true, text: 'Успешно сохранено'}))
                  }
                  that.setState({
                    isLoadedSubmit: false,
                    successSubmit: true
                  });
                  setTimeout(() => {
                    that.setState({
                      successSubmit: false
                    });
                  }, 5000);
                }
                if(that.state.formData){
                  const myHeaders = new Headers({
                    'Authorization': 'Bearer ' + idToken
                  });
                  let formData = new FormData();
                  let i = that.state.formData.length;
                  let countPercent = 100 / i;
                  for (let file of that.state.formData) {
                    formData.append('file', file);
                    fetch(`${that.props.queryLink}/api/v1/events/${data.eventID}/photos`, {
                      method: 'POST',
                      headers: myHeaders,
                      body: formData
                    }).then(res => {
                      if (res.status !== 200) {
                        res.json().then(function(data) {
                          that.setState({
                            errors: data.errors
                          })
                        })
                      } else {
                        that.setState(prevState => ({
                          percentValue: (Math.ceil(prevState.percentValue + countPercent))
                        }));
                        i--;
                        if(i < 1){
                          if(typeof window !== "undefined" && typeof document !== 'undefined'){
                            localStorage.setItem("successEventSubmit", JSON.stringify({status: true, text: 'Успешно сохранено'}));
                          }
                          that.setState({
                            isLoadedSubmit: false,
                            successSubmit: true
                          });
                          setTimeout(() => {
                            that.setState({
                              successSubmit: false
                            });
                          }, 5000);
                        }
                      }
                    })
                    formData.delete('file');
                  }
                  Promise.all(that.state.formData).then(function() {
                    console.log (i);
                  });
                }
              })
            }
          })
        })
    } else {
      this.setState({
        errorFields: true
      });
    }
  }
  handlerAddMembers (event) {
    event.preventDefault();
    let that = this;
    if(!event.target.closest('.select-users__label')){
      this.setState({
        modal: true,
        modalAddMembers: true,
        users: [],
        isLoadedUser: false,
        errorUsers: null
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              errorUsers: data.errors,
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              users: data
            })
          });
        }
      })
    })
    }
  }
  setChooseMembers (event) {
    this.handleModalDisabled();
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalAddMembers: false,
        modalDisabled: false,
        modalDeleteEvents: false,
        modalData: {}
      }));
    }, 500);
  }
  handlerAddPhotos(event){
    let files = event.target.files;
    let formData = this.state.formData;
    if(formData){
      const dt = new DataTransfer();
      for (let file of formData) {
        dt.items.add(file)
      }
      for (let file of files) {
        dt.items.add(file)
      }
      this.setState(prevState => ({
        formData: Array.from(dt.files)
      }));
    } else {
      this.setState(prevState => ({
        formData: Array.from(files)
      }));
    }
  }
  handlerDeleteImg(index, event){
    let formData = this.state.formData;
    formData.splice(index, 1);
    this.setState(prevState => ({
      formData: formData
    }));
  }
  handlerSearchUsers(event) {
    let arr = this.state.users;
    let newArr = [];
    this.setState({
      searchValue: event.target.value,
      usersSearch: []
    });
    let that = this;
    arr.map(function(item) {
      item.users.map(function(users) {
        if (users.name.includes(event.target.value)) {
           newArr.push(users);
          that.setState(prevState => ({
            usersSearch: [...prevState.usersSearch, users]
          }));
        }
      })
    });
  }
  chooseDateHandler(choose, event){
    this.setState({
      chooseDateType: choose
    });
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handleFileSelect() {
    var file = this.state.formData;
    var f = file[0];
    var reader = new FileReader();
    reader.onload = (function(theFile) {
        return function(e) {
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            var span = document.querySelector('.account-preview__wgt_col-logo');
            span.innerHTML = ['<img class="thumb" title="', escape(theFile.name), '" src="', e.target.result, '" />'].join('');
          }
        };
    })(f);
    reader.readAsDataURL(f);
  }
  handlerStatusEvent(event){
    let obj = this.state.objForSubmit;
    let status = obj.eventStatus;
    if(status === 'NOT_ACTIVE'){
      obj.eventStatus = 'ACTIVE';
    } else {
      obj.eventStatus = 'NOT_ACTIVE';
      obj.sendNotifications = false;
    }
    this.setState(prevState => ({
      objForSubmit: obj
    }));
  }
  handlerNotificationEvent(event){
    let obj = this.state.objForSubmit;
    if(obj.eventStatus === 'ACTIVE'){
      obj.sendNotifications = !obj.sendNotifications;
    }
    this.setState(prevState => ({
      objForSubmit: obj
    }));
  }

  handlerGetStartDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    let nthWeekday = this.getNthWeekdayOfMonth(new Date(year, (monthValue - 1), day));
    this.setState({
      startDateCalendar: day + '.' + monthValue + '.' + year,
      iterStartCalendar: iter,
      weeklyDay: new Date(year, (monthValue - 1), day).getDay(),
      nthWeekday: nthWeekday - 1,
      dateNum: day,
      monthNum: month
    });
  }

  handlerGetStartTime(time, event){
    event.preventDefault();
    this.setState({
      startTimeCalendar: time
    });
  }

  handlerGetEndDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    this.setState({
      endDateCalendar: day + '.' + monthValue + '.' + year,
      iterFinishCalendar: iter
    });
  }

  handlerGetEndTime(time, event){
    event.preventDefault();
    this.setState({
      endTimeCalendar: time
    });
  }

  getNameNumberDay(weekday, num){
    const numberNameOne = [
      'перший',
      'другий',
      'третій',
      'чертвертий',
      'п\'ятий'
    ];
    const numberNameSecond = [
      'перша',
      'друга',
      'третя',
      'чертверта',
      'п\'ята'
    ];
    if(weekday === 1 || weekday === 2 || weekday === 4){
      return numberNameOne[num];
    } else {
      return numberNameSecond[num];
    }
  }

  getNthWeekdayOfMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const weekday = date.getDay();
    let nthWeekday = 0;

    for (let i = 1; i <= date.getDate(); i++) {
      const currentDate = new Date(year, month, i);
      if (currentDate.getDay() === weekday) {
        nthWeekday++;
        if (currentDate.getTime() === date.getTime()) {
          return nthWeekday;
        }
      }
    }

    return null;
  }

  handlerChangDateInfo(status){
    this.setState(prevState => ({
      changeStatus: status
    }));
  }

  handlerGetEmoji(smile, event){
    this.setState({
      smileValue: smile
    });
  }

  handlerToggleUsersTeam(teamID, event) {
      const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
      const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
      const existingIds = new Set(this.state.getUsersIds.map(user => user.id));
      const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
      let updatedGetUsersIds;
      if (filteredUsers.length > 0) {
          updatedGetUsersIds = this.state.getUsersIds.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
      } else {
          updatedGetUsersIds = [...this.state.getUsersIds, ...newUsers];
      }
      this.setState({ getUsersIds: updatedGetUsersIds });
  }

  handlerHoverEnterUserTeam(teamID, event){
    const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
    const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
    const existingIds = new Set(this.state.getUsersIdsHover.map(user => user.id));
    const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
    let updatedGetUsersIds;
    if (filteredUsers.length > 0) {
        updatedGetUsersIds = this.state.getUsersIdsHover.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
    } else {
        updatedGetUsersIds = [...this.state.getUsersIdsHover, ...newUsers];
    }
    this.setState({ getUsersIdsHover: updatedGetUsersIds });
  }

  handlerHoverLeaveUserTeam(event){
    this.setState({ getUsersIdsHover: [] });
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('events-CreateTitle') + ' | ' + this.props.companyName;
    }
    const events = [
      { value: null, label: this.props.getTranslate('events-RepeaNull')},
      { value: 'DAILY', label: this.props.getTranslate('events-RepeatDay') },
      { value: 'WEEKLY', label: this.props.getTranslate('events-RepeatWeek') + ' - ' + this.getNameDay(this.state.weeklyDay) },
      { value: 'MONTHLY', label: this.props.getTranslate('events-RepeatMonth') + ' - ' + this.getNameNumberDay(this.state.weeklyDay, this.state.nthWeekday) + ' ' + this.getNameDay(this.state.weeklyDay) },
      { value: 'YEAR', label: 'Кожного року' + ' - ' + this.state.dateNum + ' ' + this.getMonthsName(this.state.monthNum) },
      { value: 'WEEKLY2', label: 'Кожний будній день (з понеділка по п’ятницю)' },
    ];
    let chooseUsers = '';
    if(this.state.usersIds.length > 0 && !this.state.allUsers){
      chooseUsers = this.state.getUsersIds.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.name}<span onClick={this.handlerRemoveUser.bind(this, item.id)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span></span>
      })
    }
    let totalItem = this.state.dates.length * 7;
    let firstItems = this.state.countFirstElems;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let tableDate = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iter++;
          if((this.state.date < this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth())){
            return <td className="ended" key={i}>{elem}</td>
          } else if(this.state.currentDate >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + (elem + 1)) && iter < endedItems && (this.state.date.getMonth()) <= (this.state.currentDate.getMonth()) && this.state.date.getFullYear() <= this.state.currentDate.getFullYear()){
            return <td className="ended" key={i}>{elem}</td>
          } else if(new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem) && new Date(this.state.hoverDate) >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem)) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(this.state.startDate && (new Date(this.state.startDate).getDate() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getDate()) && (new Date(this.state.startDate).getMonth() - 1 === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getMonth()) && (new Date(this.state.startDate).getFullYear() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getFullYear())) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(this.state.iterStart && (this.state.countMonth >= this.state.countMonthSave && this.state.iterStart <= iter && this.state.iterHover >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && (this.state.countMonth <= this.state.countMonthFinish && this.state.countMonth >= this.state.countMonthSave) && (this.state.iterStart <= iter && this.state.iterFinish >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && this.state.countMonth < this.state.countMonthFinish && this.state.countMonth > this.state.countMonthSave){
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if((this.state.iterStart && this.state.iterFinish) && (this.state.countMonthSave !== this.state.countMonthFinish) && (this.state.countMonth === this.state.countMonthSave) && iter > this.state.iterStart) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else {
            return <td key={i} onMouseEnter={this.chooseDateHover.bind(this, iter)} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          }
        })}
      </tr>
    });
    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ?       <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div></Scrollbars> : <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        <div className={`${this.state.getAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">{this.props.getTranslate('modalAddUser-AllUsersLabel')}</span>
          </div>
          {this.state.getAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label" onClick={this.handlerToggleUsersTeam.bind(this, item.teamID)} onMouseEnter={this.handlerHoverEnterUserTeam.bind(this, item.teamID)} onMouseLeave={this.handlerHoverLeaveUserTeam.bind(this)}>{item.name ? item.name : this.props.getTranslate('modalAddUser-NoTeamLabel')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            let hoverActive = this.state.getUsersIdsHover && this.state.getUsersIdsHover.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
              return <div className={`${this.state.getAllUsers || active || hoverActive ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {this.state.getAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }
    let previewReccuring = '';
    if(this.state.objForSubmit){
      if(this.state.objForSubmit.recurringType === "DAILY"){
        previewReccuring = this.props.getTranslate('events-RepeatDay');
      } else if(this.state.objForSubmit.recurringType === "WEEKLY") {
        previewReccuring = this.props.getTranslate('events-RepeatWeek');
      } else if(this.state.objForSubmit.recurringType === "MONTHLY"){
        previewReccuring = this.props.getTranslate('events-RepeatMonth');
      } else {
        previewReccuring = this.props.getTranslate('events-RepeaNull');
      }
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink("/account/events")} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        {this.state.previewStatus ?
            <div className="account-content__inner account-preview">

              {/* Account header component */}
              <AccountContentHeader
                handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
                languageActive={this.props.chooseLanguage}
                preview={true}
                backBtn={true}
                backBtnType="btn"
                backBtnClass="button-back_theme_gray"
                backBtnHandler={this.handlerNextPreview.bind(this)}
                backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
              />

              <h2 className="account-preview__headline">{this.props.getTranslate('event-previewTitle')}</h2>
              <div className="account-preview__inner account-preview__inner_preview">
                <div className="account-preview__info">
                  <div className="account-preview__wgt">
                    <div className="account-preview__wgt_row">
                      <div className="account-preview__wgt_col-info">
                        <div className="account-preview__wgt-headline">
                          {this.state.objForSubmit.smile ? <div className="account-preview__wgt-smile" dangerouslySetInnerHTML={{__html: this.state.objForSubmit.smile}}></div> : <img className="account-preview__wgt-smile" src={smile} alt="" />}
                          <div className="account-preview__wgt-headline-text">{this.state.objForSubmit.title}</div>
                        </div>
                        <div className="account-preview__info-row">
                          <div className="account-preview__info-wgt">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('event-labelDescription')}</div>
                            <div className="account-preview__info-wgt-value" dangerouslySetInnerHTML={{__html: this.state.objForSubmit.description}}></div>
                          </div>
                          <div className="account-preview__info-wgt">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('event-labelLink')}</div>
                            <a className="account-preview__info-wgt-link" href={this.state.objForSubmit.link} target="_blank">{this.state.objForSubmit.link}</a>
                          </div>
                          <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('event-labelDate')}</div>
                            <div className="account-preview__info-wgt-value"><DateFormating start={this.state.objForSubmit.startTime} end={this.state.objForSubmit.endTime} /></div>
                          </div>
                          <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('event-labelRepeat')}</div>
                            <div className="account-preview__info-wgt-value">{previewReccuring}</div>
                          </div>
                          <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('event-labelVenue')}</div>
                            <div className="account-preview__info-wgt-value">{this.state.objForSubmit.place}</div>
                          </div>
                          <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('event-labelAddress')}</div>
                            <div className="account-preview__info-wgt-value">{this.state.objForSubmit.address}</div>
                          </div>
                          <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('event-labelOpenEvent')}</div>
                            <div className="account-preview__info-wgt-value">{this.state.objForSubmit.openEvent ? this.props.getTranslate('event-labelOpenEventYes') : this.props.getTranslate('event-labelOpenEventNot')}</div>
                          </div>
                          {this.state.objForSubmit.bonus ? <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('event-labelBonusesEvent')}</div>
                            <div className="account-preview__info-wgt-value">{this.state.objForSubmit.bonus + ' ' + this.props.currency + ', ' + (this.state.objForSubmit.bonusConfirmation ? this.props.getTranslate('event-previewBonuseConfirmYes') : this.props.getTranslate('event-previewBonuseConfirmNo'))}</div>
                          </div> : ''}
                        </div>
                      </div>
                      <div className="account-preview__wgt_col-logo"></div>
                    </div>
                    <div className="account-preview__info-wgt">
                      <div className="field-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('events-Members')}</label>
                        <div className="select-modal" onClick={this.handlerAddMembers.bind(this)}>
                        {this.state.getUsersIds.length < 1 ?
                        <div className="select-users__input">
                          {this.state.allUsers && this.state.usersIds.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                                    <use href={`${sprite}#close-icon`}></use>
                                                  </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('getEmployees-FieldSelectPlaceholder')}</div>}
                        </div> : <div className="select-users__input">{chooseUsers}</div>}
                        </div>
                      </div>
                    </div>
                    <div className="account-preview__info-nav">
                      <button className="button-standart button-standart_theme_blue" type="button" onClick={this.handlerNextPreview.bind(this, 'back')}>{this.props.getTranslate('event-EventPreviewBtnEdit')}</button>
                      <button className="button-standart button-standart_theme_red" type="button" onClick={this.handlerDeleteEventModal.bind(this)}>{this.props.getTranslate('event-EventPreviewBtnDelete')}</button>
                    </div>
                  </div>
                  <div className="account-preview__wgt">
                    <div className="account-preview__wgt-headline-text">{this.props.getTranslate('event-previewAdditionalTitle')}</div>
                    <div className="field-wrap field-wrap__tooltip">
                      <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" checked={this.state.objForSubmit.eventStatus === 'ACTIVE' ? false : true} onChange={this.handlerStatusEvent.bind(this)} /><label htmlFor="styled-checkbox-0"></label></div><span>{this.props.getTranslate('event-PreviewHideCheckbox')}</span></div>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('events-OpenEventTooltipPreview')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="field-wrap">
                      <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-1" checked={this.state.objForSubmit.sendNotifications} onChange={this.handlerNotificationEvent.bind(this)} type="checkbox" /><label className={`checkbox-label ${this.state.objForSubmit.eventStatus === 'ACTIVE' ? '' : 'checkbox-label__disabled'}`} htmlFor="styled-checkbox-1"></label></div><span>{this.props.getTranslate('event-PreviewNotifyCheckbox')}</span></div>
                    </div>
                  </div>
                  <button className="preview-submit-btn" type="button" disabled={this.state.isLoadedSubmit ? true : false} onClick={this.handlerEventSubmit.bind(this)}>{this.props.getTranslate('events-SubmitPublish')}{this.state.isLoadedSubmit && <Loading />}</button>
                </div>
                <div className="account-preview__img">
                  <div className="account-preview__img-description">{this.props.getTranslate('event-EventPreviewExample')}</div>
                  <img src={img_preview} alt="" />
                </div>
              </div>
            </div> : <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              backBtn={true}
              backBtnType="link"
              backBtnClass="button-back_theme_gray"
              backBtnHandler={this.props.getPermalink("/account/events")}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('events-CreateTitle')}</h1>
          </div>
          <div className="event-form-wrap">
            <div className="event-form">
              <div className="event-form__inner">
                  <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('events-Caption')}</label>
                    </div>
                      <div className="field-wrap__smile">
                        <div className="field-wrap__smile__inner field-wrap__input_width_75">
                          <input id="headline" className={`field-wrap__input ${(this.state.errorFields && !this.state.headlineValue) || (this.state.errorFields && this.state.errorNameOnlySpace && this.state.headlineValue !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.headlineValue} onChange={this.handlerHeadlineValue.bind(this)} placeholder={this.props.getTranslate('events-CaptionFieldCaption')} />
                          {this.state.errorFields && !this.state.headlineValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                          {this.state.errorFields && this.state.errorNameOnlySpace && this.state.headlineValue !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                        </div>

                        <GetEmoji
                          tooltipValue={this.props.getTranslate('event-tooltipEmoji')}
                          onChangeHandler={this.handlerGetEmoji.bind(this)}
                        />


                    </div>
                  </div>
                <div className="field-wrap">
                  <div className="field-wrap__label-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('events-Description')}</label>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                        <p>{this.props.getTranslate('tooltipEvent-DescriptionEvent')}</p>
                      </div>
                    </div>
                  </div>
                  {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                    toolbar={{
                      options: ['inline', 'blockType', 'list'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline']
                      },
                      blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered']
                      }
                    }}
                    wrapperClassName={`wrapper-class editor-field ${this.state.errorDescriptionOnlySpace ? 'field-wrap__input_error' : ''}`}
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class editor-toolbar"
                    editorState={this.state.contentState}
                    onEditorStateChange={this.onEditorStateChange}
                  /> : ''}
                  {this.state.errorDescriptionOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                </div>
                <div className="field-wrap">
                  <div className="field-wrap__label-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="link">{this.props.getTranslate('events-Link')}</label>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                        <p>{this.props.getTranslate('tooltipEvent-LinkEvent')}</p>
                      </div>
                    </div>
                  </div>
                  <input id="link" className={`field-wrap__input ${this.state.errorLinkOnlySpace ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.linkValue} onChange={this.handlerLinkValue.bind(this)} />
                  {this.state.errorLinkOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                </div>
                <div className="event-form__row">
                  <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="place">{this.props.getTranslate('events-LocationName')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipEvent-LocationEvent')}</p>
                        </div>
                      </div>
                    </div>
                    <input id="place" className={`field-wrap__input ${this.state.errorPlaceOnlySpace ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.placeValue} onChange={this.handlerPlaceValue.bind(this)} />
                    {this.state.errorPlaceOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="address">{this.props.getTranslate('events-Location')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipEvent-AddressEvent')}</p>
                        </div>
                      </div>
                    </div>
                    <input id="address" className={`field-wrap__input ${this.state.errorAddressOnlySpace ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.addressValue} onChange={this.handlerAddressValue.bind(this)} />
                    {this.state.errorAddressOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                  </div>
                </div>
                <div className="event-form__row">
                  <CalendarDropdown
                    label={this.props.getTranslate('events-Date')}
                    handlerGetStartDate={this.handlerGetStartDate.bind(this)}
                    handlerGetEndDate={this.handlerGetEndDate.bind(this)}
                    handlerGetStartTime={this.handlerGetStartTime.bind(this)}
                    handlerGetEndTime={this.handlerGetEndTime.bind(this)}
                    startDate={this.state.startDateCalendar}
                    startTime={this.state.startTimeCalendar}
                    endDate={this.state.endDateCalendar}
                    endTime={this.state.endTimeCalendar}
                    iterStartCalendar={this.state.iterStartCalendar}
                    iterFinishCalendar={this.state.iterFinishCalendar}
                    handlerChangeDateForInfo={this.handlerChangDateInfo.bind(this)}
                  />
                  <div className="field-wrap">
                    {this.state.repeatValue && this.state.changeStatus ? <div className="tooltip-reccuring-info">{this.props.getTranslate('events-ReccuringNoteInfo')}</div> : ''}
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('events-Reccuring')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipEvent-ReccuringEvent')}</p>
                        </div>
                      </div>
                    </div>
                    {events ? <Select
                      styles={customStyles}
                      options={events}
                      placeholder={this.props.getTranslate('events-ReccuringPlaceholder')}
                      value={this.state.repeatValue ? events[events.findIndex(i => i.value == this.state.repeatValue)] : events[0]}
                      onChange={this.handlerRepeatEvent.bind(this)}
                    /> : ''}
                  </div>
                </div>
                <div className="event-form__row">
                  <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="bonuse-amount">{this.props.getTranslate('events-Bonuses')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>Бонуси які отримає юзер після закінчення події</p>
                        </div>
                      </div>
                    </div>
                    <div className="field-wrap__currency-name">
                      <input id="bonuse-amount" className={`field-wrap__input ${this.state.bonuseValueNumError || this.state.bonuseValueCountError ? 'field-wrap__input_error' : ''}`} value={this.state.bonuseValue} onChange={this.handlerBonuseValue.bind(this)} placeholder={this.props.getTranslate('events-BonusesPlaceholder')} />
                      {this.props.currency ? <label className="field-wrap__currency-name-item" htmlFor="price">{this.props.currency}</label> : ''}
                    </div>
                    {this.state.bonuseValueNumError && !this.state.bonuseValueCountError ? <div className="validation-error">В полі повинні бути тільки цифри</div> : ''}
                    {!this.state.bonuseValueNumError && this.state.bonuseValueCountError ? <div className="validation-error">Не повинно перевищувати 999 999.99</div> : ''}
                  </div>
                  <div className="field-wrap field-wrap__tooltip field-wrap__tooltip-bonuses">
                    <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" checked={this.state.bonuseConfirmation} onChange={this.handlerBonuseConfirmation.bind(this)} /><label htmlFor="styled-checkbox-2"></label></div><span>{this.props.getTranslate('events-Confirm')}</span></div>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                        <p>{this.props.getTranslate('events-ConfirmEventTooltip')}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="event-form__row">
                  <div className="field-wrap field-wrap__members">
                    <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('events-Members')}</label>
                    <div className={`select-modal ${this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? 'field-wrap__input_error' : ''}`} onClick={this.handlerAddMembers.bind(this)}>
                    {this.state.getUsersIds.length < 1 ?
                    <div className="select-users__input">
                      {this.state.allUsers && this.state.usersIds.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                                <use href={`${sprite}#close-icon`}></use>
                                              </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('getEmployees-FieldSelectPlaceholder')}</div>}
                    </div> : <div className="select-users__input">{chooseUsers}</div>}
                    </div>
                    {this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  </div>
                  <div className="tooltips-wrap tooltips-wrap__event field-wrap__tooltip-bonuses">
                    <div className="field-wrap field-wrap__tooltip">
                      <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" checked={this.state.open} onChange={this.handlerOpen.bind(this)} /><label htmlFor="styled-checkbox-1"></label></div><span>{this.props.getTranslate('events-OpenStatus')}</span></div>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('events-OpenEventTooltip')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="edit-user-form__imgs">
                  <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('events-Photo')}</label>
                  <div className="edit-user-form__imgs-inner">
                    <div className="edit-user-upload">
                      <input type="file" id="upload" multiple onChange={this.handlerAddPhotos.bind(this)} />
                      <label htmlFor="upload">
                        <svg className="upload__icon">
                          <use href={`${sprite}#img-upload`}></use>
                        </svg>
                        {this.props.getTranslate('events-AddPhoto')} {this.state.formData && <span style={{marginTop: '5px'}}>{this.props.getTranslate('loadingImg-AmountTitle')} {this.state.formData.length}</span>}</label>
                    </div>
                    {this.state.formData && this.state.isLoadedSubmit ? <div className="edit-user-upload">
                      <div className="edit-user-upload__percent">
                        <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                        <div className="edit-user-upload__bar">
                          <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                        </div>
                      </div>
                    </div> : ''}
                    {this.state.formData ? <SortableList items={this.state.formData} onSortEnd={this.onSortEnd} axis={'xy'} handlerDelete={this.handlerDeleteImg.bind(this) }/> : ''}
                  </div>
                </div>
              </div>
              <div className="field-wrap__submit event-form__nav">
                <button className="btn btn_size_lg" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('addEvent-buttonGoPreview')}</button>
              </div>
            </div>
          </div>

        </div>}
      </div>
    </div>
    {this.state.modal && this.state.modalAddMembers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="field-wrap field-wrap__search-members">
        <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
        <svg className="field-wrap__search-icon">
          <use href={`${sprite}#search-icon`}></use>
        </svg>
      </div>
      <div className="question-members">
        <div className="question-members-wgt">
          {users}
        </div>
        <div className="field-wrap__submit">
          <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
        </div>
      </div>
    </div>
  </div> : ''}
  {this.state.dateModal && <div className="date-modal">
    <div className="date-modal__overlay" onClick={this.handlerCloseGetDate.bind(this)}></div>
    <div className="date-modal__content">
      <div className="date-modal__header">
        <div className="date-modal__headline">{this.props.getTranslate('modal-calendarAddEventTitle')}</div>
        <button className="date-modal__close-modal" type="button" onClick={this.handlerCloseGetDate.bind(this)}>
          <svg className="date-modal__close-modal-icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="date-fields">
        <div className={`date-field ${this.state.chooseDateType === 'start' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'start')}>
          <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishStartDate ? this.state.finishStartDate : ''} onKeyPress={this.onKeyPress.bind(this)} />
          <input className="date-field__time" type="text" maxLength="5" placeHolder="00:00" onChange={this.handlerStartTime.bind(this)} onKeyPress={this.onKeyPress2.bind(this)} />
        </div>
        <div className={`date-field ${this.state.chooseDateType === 'finish' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'finish')}>
          <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishEndDate ? this.state.finishEndDate : ''} onKeyPress={this.onKeyPress.bind(this)} />
          <input className="date-field__time" type="text" maxLength="5" placeHolder="00:00" onChange={this.handlerEndTime.bind(this)} onKeyPress={this.onKeyPress2.bind(this)} />
        </div>
      </div>
      <div id="calendar" className="calendar">
        <div className="calendar__info">
          <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
            <svg className="calendar__month-nav-icon">
              <use href={`${sprite}#chevron-left`}></use>
            </svg>
          </button>
          <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
          <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
            <svg className="calendar__month-nav-icon">
              <use href={`${sprite}#chevron-right`}></use>
            </svg>
          </button>
        </div>
        <table className="calendar-table">
          <thead className="calendar-table__head">
            <tr>
              <th>{this.props.getTranslate('modal-calendarDayWeekMonday')}</th>
              <th>{this.props.getTranslate('modal-calendarDayWeekTuesday')}</th>
              <th>{this.props.getTranslate('modal-calendarDayWeekWednesday')}</th>
              <th>{this.props.getTranslate('modal-calendarDayWeekThursday')}</th>
              <th>{this.props.getTranslate('modal-calendarDayWeekFriday')}</th>
              <th>{this.props.getTranslate('modal-calendarDayWeekSutarday')}</th>
              <th>{this.props.getTranslate('modal-calendarDayWeekSunday')}</th>
            </tr>
          </thead>
          <tbody className="calendar-table__dates">{tableDate}</tbody>
        </table>
      </div>
      <div className="form-nav__submit">
        <button className="btn btn_size_lg btn_width_100" type="button" onClick={this.setDateInput.bind(this)}>{this.props.getTranslate('modal-calendarAddBtn')}</button>
      </div>
    </div>
  </div>}

  {this.state.modalDeleteEvents ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
  <div className="modal-background" onClick={this.handleModalDisabled}></div>
  <div className="modal">
    <div className="modal-header">
      <h2 className="modal-headline">{this.props.getTranslate('modal-deleteEventTitle')}</h2>
      <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
        <svg className="close-modal__icon">
          <use href={`${sprite}#close-icon`}></use>
        </svg>
      </button>
    </div>
      <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteEventDescription')}</div>
      <div className="field-wrap__submit">
        <Link className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" to={this.props.getPermalink("/account/events")}>{this.props.getTranslate('removeModal-ButtonSubmit')}</Link>
      </div>
      </div>
  </div> : ''}

    </>;
  }
}
export default EventsCreate;
