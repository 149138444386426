import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import Loading from './components/Loading';
import { useParams, Link, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import sprite from './media/icons.svg';
import img_preview from './media/item-preview-rewards.png';
import member_1 from './media/avatar-1.png';
import { getAuth } from "firebase/auth";
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class RewardsCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      previewStatus: false,
      inputHeadline: '',
      inputDescription: '',
      inputPrice: '',
      checkboxAuto: false,
      selectType: null,
      selectSubType: null,
      rewardTypes: [],
      objForSubmit: {},
      mainPhotoModal: false,
      modalEditTab: false,
      mainImgName: '',
      imageSrc: null,
      errorFormatImgs: false,
      errorSizeImgs: false,
      filesSubmit: null,
      formData: null,
      successSubmit: false,
      iconImgs: [],
      iconDefault: null,
      setImage: false,
      modalDeleteReward: false,
      errorNameOnlySpace: false,
      errorImageEmpty: false,
      getAllUsers: false,
      allUsers: false,
      getUsersIds: [],
      usersIds: [],
      chooseAllMembers: false,
      chooseMembersIds: [],
      errorNotChooseImage: false,
      bonuseValueNumError: false,
      bonuseValueCountError: false,
      inputBonuses: '',
      getUsersIdsHover: []
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let location;
    let language = 'uk-UA';
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US'
      }
    }
    let that = this;
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken,
            'Accept-Language': language
        });
            fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/types`, {
              method: 'GET',
              headers: myHeaders
            }).then(res => {
              if(res.status !== 200) {

              } else {
                res.json().then(function(data) {
                  if(data && data.length > 0){
                    const transformedData = data.map(item => ({
                      label: item.name,
                      value: item.rewardType
                    }));
                    let transformedDataSub = [];
                    if(data.subTypes){
                      transformedDataSub = data.subTypes.map(item => ({
                        label: item.name,
                        value: item.rewardSubType
                      }));
                    }
                    that.setState({
                      rewardTypes: transformedData,
                      rewardTypesSub: transformedDataSub
                    });
                  } else {
                    const transformed = [
                      {
                        "name": "Час роботи в компанії",
                        "rewardType": "COMPANY_EMPLOYEE_ANNIVERSARY",
                        "subTypes": [
                          {
                            "name": "1 рік",
                            "rewardSubType": "ONE_YEAR"
                          }
                        ]
                      }
                    ];
                    const transformedData = transformed.map(item => ({
                      label: item.name,
                      value: item.rewardType
                    }));
                    const transformedDataSub = transformed[0].subTypes.map(item => ({
                      label: item.name,
                      value: item.rewardSubType
                    }));
                    that.setState({
                      rewardTypes: transformedData,
                      rewardTypesSub: transformedDataSub
                    });
                  }
                })
              }
            })
      });
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalData: {},
        modalEditTab: false,
        errorSizeImgs: false,
        errorFormatImgs: false,
        filesSubmit: null,
        modalDeleteReward: false
      }));
      if(this.state.mainPhotoModal){
        this.setState(prevState => ({
          previewImg: null,
          indexImg: null,
          imageSrc: null,
          formData: null,
          mainPhotoModal: false
        }))
      }
    }, 500);
  }

  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }
  handlerNextPreview(event){
    let that = this;
    let error = false;
    this.setState(prevState => ({
      errorFields: false,
      errorNameOnlySpace: false,
      errorDescriptionOnlySpace: false,
      errorImageEmpty: false,
      inputBonuses: prevState.inputBonuses.replace(/\.$/, '')
    }));
    let nameValue = this.validItemEmpty('inputHeadline');
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    let descriptionValue = this.validItemEmpty('inputDescription');
    if(/^\s*$/.test(descriptionValue) && descriptionValue !== ''){
      error = true;
      this.setState({errorDescriptionOnlySpace: true });
    }
    if(!this.state.imageSrc){
      error = true;
      this.setState({errorImageEmpty: true });
    }
    if(that.state.checkboxAuto && !that.state.selectType){
      error = true;
    }
    if(!error && nameValue && descriptionValue && (this.state.getAllUsers || this.state.usersIds.length > 0)){
      if(typeof window !== "undefined" && typeof document !== 'undefined'){
        document.body.classList.add('preview');
      }
    this.setState(prevState => ({
      previewStatus: !prevState.previewStatus
    }));
    let obj = {
      "allUsers": that.state.allUsers,
      "auto": that.state.checkboxAuto,
      "bonus": that.state.inputBonuses,
      "description": descriptionValue,
      "name": nameValue,
      "userIds": that.state.usersIds
    };
    if(that.state.imageSrc){
      obj.photo = {
        url: that.state.imageSrc
      }
    }
    if(that.state.checkboxAuto){
      if(that.state.selectSubType && that.state.selectSubType.length > 0 && that.state.selectSubType.value){
        obj.rewardSubType = that.state.selectSubType.value;
      }
      if(that.state.selectType){
        obj.rewardType = that.state.selectType.value;
      }
    }
    this.setState({
      objForSubmit: obj
    });
    } else {
      this.setState({
        errorFields: true
      });
    }
  }

  handlerSubmit(event){
    event.preventDefault();
    let that = this;
    let error = false;
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    this.setState({
      isLoadedSubmit: true
    });
    if(!error) {
      let objForSubmit = that.state.objForSubmit;
      if(that.state.formData){
        let formData = new FormData();
        for (let file of that.state.formData) {
          formData.append('file', file);
          getAuth().currentUser.getIdToken().then(function(idToken){
            const myHeaders = new Headers({
                'Authorization': 'Bearer ' + idToken
            });
            fetch(`${that.props.queryLink}/api/v1/encouragement/photos`, {
              method: 'POST',
              headers: myHeaders,
              body: formData
            }).then(res => {
                if (res.status !== 200) {
                  that.setState({
                    isLoadedSubmit: false
                  });
                  res.json().then(function(data) {
                    that.setState({
                      errors: data.errors
                    })
                  });
                } else {
                  res.json().then(function(data) {
                    objForSubmit.photo = data;
                    const myHeaders = new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + idToken
                    });
                    fetch(`${that.props.queryLink}/api/v1/encouragement/rewards`, {
                      method: 'POST',
                      headers: myHeaders,
                      body: JSON.stringify(objForSubmit)
                    }).then(res => {
                        if (res.status !== 200) {
                          that.setState({
                            isLoadedSubmit: false
                          });
                          res.json().then(function(data) {
                            that.setState({
                              errors: data.errors
                            })
                          });
                        } else {
                            if(typeof window !== "undefined" && typeof document !== 'undefined'){
                              localStorage.setItem("successReward", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}));
                            }
                            that.setState({
                              isLoadedSubmit: false,
                              successSubmit: true
                            });
                        }
                      })
                  });
                }
              })
            })
        }
      } else {
        getAuth().currentUser.getIdToken().then(function(idToken){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });
          fetch(`${that.props.queryLink}/api/v1/encouragement/rewards`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(objForSubmit)
          }).then(res => {
              if (res.status !== 200) {
                that.setState({
                  isLoadedSubmit: false
                });
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  })
                });
              } else {
                  if(typeof window !== "undefined" && typeof document !== 'undefined'){
                    localStorage.setItem("successReward", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}));
                  }
                  that.setState({
                    isLoadedSubmit: false,
                    successSubmit: true
                  });
              }
            })
          })
      }

    } else {
      this.setState({
        errorFields: true,
        isLoadedSubmit: false
      });
    }
  }

  handlerHeadline(event){
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    if(event.target.value.length <= 100){
      this.setState({
        inputHeadline: event.target.value,
        inputHeadlineLength: false
      });
    } else {
      this.setState({
        inputHeadlineLength: true
      });
    }
  }

  handlerDescription(event){
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    if(event.target.value.length <= 300){
      this.setState({
        inputDescription: event.target.value,
        inputDescriptionLength: false
      });
    } else {
      this.setState({
        inputDescriptionLength: true
      });
    }
  }

  handlerBonuseValue(event) {
      const inputValue = event.target.value;
      if (inputValue === "") {
          this.setState({
              inputBonuses: "",
              bonuseValueNumError: false,
              bonuseValueCountError: false
          });
          return;
      }
      if (/^\d+(\.\d{0,2})?$/.test(inputValue)) {
          const floatValue = parseFloat(inputValue);
          if (!isNaN(floatValue)) {
              if (floatValue > 999999.99) {
                  this.setState({
                      bonuseValueCountError: true,
                      bonuseValueNumError: false
                  });
              } else {
                  this.setState({
                      inputBonuses: inputValue,
                      bonuseValueNumError: false,
                      bonuseValueCountError: false
                  });
              }
          } else {
              this.setState({
                  bonuseValueCountError: false,
                  bonuseValueNumError: true
              });
          }
      } else {
        const floatValue = parseFloat(inputValue);
        if (!isNaN(floatValue)) {
          this.setState({
              bonuseValueCountError: true
          });
        } else {
          this.setState({
              bonuseValueNumError: true
          });
        }
      }
  }

  handlerCheckboxAuto(event){
    this.setState(prevState => ({
      checkboxAuto: !prevState.checkboxAuto
    }));
  }

  handlerType(event){
    this.setState({
      selectType: event
    });
  }

  handlerSubType(event){
    this.setState({
      selectSubType: event
    });
  }

  handlerUploadImageModal(event){
    let that = this;
    that.setState(prevState => ({
      mainPhotoModal: !prevState.mainPhotoModal
    }));
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/photos`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              previewImg: data[0],
              indexImg: 0,
              imageSrc: data[0],
              mainImgName: '',
              iconImgs: data
            });
          });
          that.setState(prevState => ({
            iconPhotoModal: !prevState.iconPhotoModal
          }));
        }
      })
    })

  }

  handlerModalEditTab(status, event){
    event.preventDefault();
    this.setState({
      modalEditTab: status
    })
  }

  handlerPreviewImg(event){
    let error = false;
    this.setState(prevState => ({
      errorFormatImgs: false,
      errorSizeImgs: false,
      imageSrc: '',
      previewImg: null,
      mainImgName: ''
    }));
    let files = event.target.files;
    if(files){
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          const fileSizeInMB = file.size / (1024 * 1024);
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
              this.setState(prevState => ({
                errorFormatImgs: true
              }));
          }
          if (fileSizeInMB > 25) {
              error = true;
              this.setState(prevState => ({
                errorSizeImgs: true
              }));
          }
      }
    }
    if(!error){
      let nameFile = files[0].name;
      let name = nameFile.split('.')[0].substring(0, 10);
      let type = nameFile.split('.')[1];
      let fullName = name + '.' + type;
      this.setState({
        imageSrc: URL.createObjectURL(files[0]),
        previewImg: URL.createObjectURL(files[0]),
        mainImgName: fullName
      });
      let formData = this.state.formData;
      if(formData){
        const dt = new DataTransfer();
        for (let file of formData) {
          dt.items.add(file)
        }
        for (let file of files) {
          dt.items.add(file)
        }
        this.setState(prevState => ({
          formData: Array.from(dt.files)
        }));
      } else {
        this.setState(prevState => ({
          formData: Array.from(files)
        }));
      }

    }
  }

  handlerDeleteRewardsModal(event){
    this.setState({
      modalDeleteReward: true
    });
  }

  handlerDeletePreview(){
    this.setState({
      imageSrc: null,
      mainImgName: '',
      previewImg: null
    })
  }

  handlerSetDefaultImg(url, index, event){
    this.setState({
      previewImg: url,
      indexImg: index,
      imageSrc: url,
      mainImgName: ''
    });
  }

  handlerSetImage(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    if(this.state.imageSrc){
      if(this.state.errorNotChooseImage){
        this.setState(prevState => ({
          errorNotChooseImage: false
        }));
      }
      this.setState(prevState => ({
        modalDisabled: true,
        setImage: true
      }));
      setTimeout(() => {
        this.setState(prevState => ({
          modalDisabled: false,
          mainPhotoModal: false
        }));
      }, 500);
    } else {
      this.setState(prevState => ({
        errorNotChooseImage: true
      }));
    }
  }
  handlerDeleteImg(event){
    this.setState(prevState => ({
      setImage: false,
      previewImg: null,
      indexImg: null,
      imageSrc: null,
      formData: null,
      mainImgName: ''
    }));
  }

  handlerAddMembers (event) {
    event.preventDefault();
    let that = this;
    if(event.target.tagName !== 'SPAN'){
      this.setState({
        modal: true,
        modalAddMembers: true,
        users: [],
        isLoadedUser: false,
        errorUsers: null
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                isLoadedUser: true,
                errorUsers: data.errors
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                isLoadedUser: true,
                users: data
              })
            });
          }
        })
      })
    }
  }
handlerSearchUsers(event) {
  let arr = this.state.users;
  let newArr = [];
  let searchValueLowerCase = event.target.value.toLowerCase();

  this.setState({
    searchValue: event.target.value,
    usersSearch: []
  });

  let that = this;
  arr.map(function(item) {
    item.users.map(function(users) {
      if (users.name.toLowerCase().includes(searchValueLowerCase)) {
        newArr.push(users);
        that.setState(prevState => ({
          usersSearch: [...prevState.usersSearch, users]
        }));
      }
    });
  });
}

  handlerSetUsers(){
      let ids = [];
      if(this.state.getUsersIds){
        ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
      }
      this.setState(prevState => ({
        allUsers: this.state.getAllUsers ? true : false,
        usersIds: ids
      }));
    this.handleModalDisabled();
  }
  handlerGetAllUsers(){
    this.setState(prevState => ({
      getAllUsers: !prevState.getAllUsers,
      getUsersIds: [],
    }));
  }
  handlerToggleUser(index, name, id){
    if(this.state.getAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {id: id, name: name}];
    }
    this.setState(prevState => ({
      getUsersIds: arr
    }));
  }
  handlerRemoveAllUsers(event){
    let data = this.state.resultData;
    data.allUsers = false;
    data.users = [];
    this.setState(prevState => ({
      getAllUsers: false,
      allUsers: false
    }));
  }
  handlerRemoveUser(id, event){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))];
    }
          console.log(ids);

    this.setState(prevState => ({
      getUsersIds: arr,
      usersIds: ids
    }));
  }
  handlerToggleUsersTeam(teamID, event) {
      const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
      const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
      const existingIds = new Set(this.state.getUsersIds.map(user => user.id));
      const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
      let updatedGetUsersIds;
      if (filteredUsers.length > 0) {
          updatedGetUsersIds = this.state.getUsersIds.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
      } else {
          updatedGetUsersIds = [...this.state.getUsersIds, ...newUsers];
      }
      this.setState({ getUsersIds: updatedGetUsersIds });
  }

  handlerHoverEnterUserTeam(teamID, event){
    const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
    const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
    const existingIds = new Set(this.state.getUsersIdsHover.map(user => user.id));
    const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
    let updatedGetUsersIds;
    if (filteredUsers.length > 0) {
        updatedGetUsersIds = this.state.getUsersIdsHover.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
    } else {
        updatedGetUsersIds = [...this.state.getUsersIdsHover, ...newUsers];
    }
    this.setState({ getUsersIdsHover: updatedGetUsersIds });
  }

  handlerHoverLeaveUserTeam(event){
    this.setState({ getUsersIdsHover: [] });
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = "Створення нагороди" + ' | ' + this.props.companyName;
    }
    let chooseUsers = '';
    if(this.state.usersIds.length > 0 && !this.state.allUsers){
      chooseUsers = this.state.getUsersIds.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.name}<span onClick={this.handlerRemoveUser.bind(this, item.id)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span></span>
      })
    }
    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ?       <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
          let hoverActive = this.state.getUsersIdsHover && this.state.getUsersIdsHover.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            return <div className={`${this.state.getAllUsers || active || hoverActive ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div></Scrollbars> : <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        <div className={`${this.state.getAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">{this.props.getTranslate('modalAddUser-AllUsersLabel')}</span>
          </div>
          {this.state.getAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label" onClick={this.handlerToggleUsersTeam.bind(this, item.teamID)} onMouseEnter={this.handlerHoverEnterUserTeam.bind(this, item.teamID)} onMouseLeave={this.handlerHoverLeaveUserTeam.bind(this)}>{item.name ? item.name : this.props.getTranslate('modalAddUser-NoTeamLabel')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            let hoverActive = this.state.getUsersIdsHover && this.state.getUsersIdsHover.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
              return <div className={`${this.state.getAllUsers || active || hoverActive ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {this.state.getAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink("/account/rewards")} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        {this.state.previewStatus ?
            <div className="account-content__inner account-preview">

              {/* Account header component */}
              <AccountContentHeader
                handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
                languageActive={this.props.chooseLanguage}
                preview={true}
                backBtn={true}
                backBtnType="btn"
                backBtnClass="button-back_theme_gray"
                backBtnHandler={this.handlerNextPreview.bind(this)}
                backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
              />

              <h2 className="account-preview__headline">{this.props.getTranslate('rewards-AddItemTitle')}</h2>
              <div className="account-preview__inner account-preview__inner_preview">
                <div className="account-preview__info">
                  <div className="account-preview__wgt">
                    <div className="account-preview__wgt_row">
                      <div className="account-preview__wgt_col-info">
                        <div className="account-preview__wgt-headline">
                          <div className="account-preview__wgt-headline-text">{this.state.inputHeadline}</div>
                        </div>
                        <div className="account-preview__info-row">
                          <div className="account-preview__info-wgt">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('rewards-FormLabelDescription')}:</div>
                            <div className="account-preview__info-wgt-value">{this.state.inputDescription}</div>
                          </div>
                          {this.state.inputBonuses ? <div className="account-preview__info-wgt">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('rewards-FormLabelBonuseAmount')}:</div>
                            <div className="account-preview__info-wgt-value">{this.state.inputBonuses}{this.props.currency ? ' ' + this.props.currency : ''}</div>
                          </div> : ''}
                          {this.state.selectType ? <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('rewards-FormLabelTypes')}:</div>
                            <div className="account-preview__info-wgt-value">{this.state.selectType.label}</div>
                          </div> : ''}
                          {this.state.selectSubType ? <div className="account-preview__info-wgt account-preview__info-wgt_width_50">
                            <div className="account-preview__info-wgt-label">{this.props.getTranslate('rewards-FormLabelTypesSub')}:</div>
                            <div className="account-preview__info-wgt-value">{this.state.selectSubType.label}</div>
                          </div> : ''}
                        </div>
                      </div>
                      <div className="account-preview__wgt_col-logo">{this.state.previewImg ? <img src={this.state.previewImg} alt="" /> : ''}</div>
                    </div>
                    <div className="account-preview__info-nav">
                      <button className="button-standart button-standart_theme_blue" type="button" onClick={this.handlerNextPreview.bind(this, 'back')}>{this.props.getTranslate('event-EventPreviewBtnEdit')}</button>
                      <button className="button-standart button-standart_theme_red" type="button" onClick={this.handlerDeleteRewardsModal.bind(this)}>{this.props.getTranslate('event-EventPreviewBtnDelete')}</button>
                    </div>
                  </div>
                  <button className="preview-submit-btn" type="button" disabled={this.state.isLoadedSubmit ? true : false} onClick={this.handlerSubmit.bind(this)}>{this.props.getTranslate('events-SubmitPublish')}{this.state.isLoadedSubmit && <Loading />}</button>
                </div>
                <div className="account-preview__img">
                  <div className="account-preview__img-description">{this.props.getTranslate('bonuses-PreviewExample')}</div>
                  <img src={img_preview} alt="" />
                </div>
              </div>
            </div> : <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              backBtn={true}
              backBtnType="link"
              backBtnClass="button-back_theme_gray"
              backBtnHandler={this.props.getPermalink("/account/rewards")}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('rewards-AddItemTitle')}</h1>
          </div>
          <div className="rewards-form-wrap">
            <div className="rewards-form">
              <div className="rewards-form__inner rewards-form__inner_border_top">
                <h3 className="form__headline">{this.props.getTranslate('rewards-FormInfoSection')}</h3>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('rewards-FormLabelHeadline')}</label>
                  <input id="headline" className={`field-wrap__input field-wrap__input_width_66 ${(this.state.errorFields && !this.state.inputHeadline) || (this.state.errorFields && this.state.errorNameOnlySpace && this.state.inputHeadline !== '') || this.state.inputHeadlineLength ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.inputHeadline} onChange={this.handlerHeadline.bind(this)} placeholder={this.props.getTranslate('rewards-ForPlaceholderHeadline')} autocomplete="off" />
                  {this.state.errorFields && !this.state.inputHeadline ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {this.state.errorFields && this.state.errorNameOnlySpace && this.state.inputHeadline !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                  {this.state.inputHeadlineLength ? <div className="validation-error">Максимум 100 символів</div> : ''}
                </div>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('rewards-FormLabelDescription')}</label>
                  <textarea id="description" className={`field-wrap__input field-wrap__input_textarea field-wrap__input_width_66 ${(this.state.errorFields && !this.state.inputDescription) || (this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.inputDescription !== '') || this.state.inputDescriptionLength ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.inputDescription} onChange={this.handlerDescription.bind(this)} placeholder={this.props.getTranslate('rewards-FormPlaceholderDescription')} />
                  {this.state.errorFields && !this.state.inputDescription ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.inputDescription !== '' ? <div className="validation-error">Не може містити тільки пробіли</div> : ''}
                  {this.state.inputDescriptionLength ? <div className="validation-error">Максимум 300 символів</div> : ''}
                </div>
                <div className="edit-user-form__row">
                  <div className="edit-user-form__col edit-user-form__col_size_3">
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="price">{this.props.getTranslate('rewards-FormLabelBonuseAmount')}</label>
                      <div className="field-wrap__currency-name">
                        <input id="price" className={`field-wrap__input ${this.state.bonuseValueNumError || this.state.bonuseValueCountError ? 'field-wrap__input_error' : ''}`} value={this.state.inputBonuses} onChange={this.handlerBonuseValue.bind(this)} placeholder={this.props.getTranslate('rewards-FormPlaceholderBonuseAmount')} />
                        {this.props.currency && <label className="field-wrap__currency-name-item" htmlFor="price">{this.props.currency}</label>}
                        {this.state.bonuseValueNumError && !this.state.bonuseValueCountError ? <div className="validation-error">В полі повинні бути тільки цифри</div> : ''}
                        {!this.state.bonuseValueNumError && this.state.bonuseValueCountError ? <div className="validation-error">Не повинно перевищувати 999 999.99</div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
                {!this.props.id ? <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('rewards-Users')}</label>
                  <div className="field-wrap__input_width_66">
                  <div className={`select-modal ${this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? 'field-wrap__input_error' : ''}`} onClick={this.handlerAddMembers.bind(this)}>
                  {this.state.getUsersIds.length < 1 ?
                  <div className="select-users__input">
                    {this.state.allUsers && this.state.usersIds.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                              <use href={`${sprite}#close-icon`}></use>
                                            </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('rewards-UsersFieldCaption')}</div>}
                  </div> : <div className="select-users__input">{chooseUsers}</div>}
                  </div>
                  {this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  </div>
              </div> : ''}
              </div>
              {!this.props.id ? <div className="rewards-form__inner">
                <div className="edit-user-form__row">
                  <div className="edit-user-form__col edit-user-form__col_size_3">
                    <div className="field-wrap field-wrap__tooltip field-wrap__tooltip-rewards">
                      <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox" type="checkbox" checked={this.state.checkboxAuto} onChange={this.handlerCheckboxAuto.bind(this)} /><label htmlFor="styled-checkbox"></label></div><span>{this.props.getTranslate('rewards-FormLabelAuto')}</span></div>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('rewards-AutoTooltip')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.checkboxAuto ? <div className="edit-user-form__row">
                  <div className="edit-user-form__col edit-user-form__col_size_3">
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('rewards-FormLabelTypes')}</label>
                      {this.state.rewardTypes ? <Select
                        styles={this.state.errorFields && this.state.checkboxAuto && !this.state.selectType ? customStylesError : customStyles}
                        options={this.state.rewardTypes}
                        value={this.state.rewardTypes && this.state.selectType ? this.state.rewardTypes[this.state.rewardTypes.findIndex(el => el.value === this.state.selectType.value)] : null}
                        placeholder={this.props.getTranslate('events-ReccuringPlaceholder')}
                        onChange={this.handlerType.bind(this)}
                      /> : ''}
                      {this.state.errorFields && this.state.checkboxAuto && !this.state.selectType ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                    </div>
                  </div>
                  {this.state.rewardTypesSub && this.state.rewardTypesSub.length > 0 ? <div className="edit-user-form__col edit-user-form__col_size_3">
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('rewards-FormLabelTypesSub')}</label>
                      {this.state.rewardTypesSub ? <Select
                        styles={customStyles}
                        options={this.state.rewardTypesSub}
                        placeholder={this.props.getTranslate('events-ReccuringPlaceholder')}
                        onChange={this.handlerSubType.bind(this)}
                      /> : ''}
                    </div>
                  </div> : ''}
                </div> : ''}
              </div> : ''}
              <div className="rewards-form__inner rewards-form__inner_border_none">
                <h3 className="form__headline">{this.props.getTranslate('rewards-FormImageSection')}</h3>
                <div className="edit-user-form__imgs edit-user-form__imgs_rewards">
                  <div className="edit-user-form__imgs-inner">
                    <div className={`edit-user-upload edit-user-upload__rewards${this.state.errorImageEmpty && !this.state.setImage ? ' edit-user-upload-error' : ''}`}>
                      <label onClick={this.handlerUploadImageModal.bind(this)}>
                        <svg className="upload__icon">
                          <use href={`${sprite}#img-upload`}></use>
                        </svg>{this.props.getTranslate('rewards-FormLabelImages')} <span style={{marginTop: '5px'}}></span></label>
                    </div>
                    {this.state.formData && this.state.isLoadedSubmit ? <div className="edit-user-upload">
                      <div className="edit-user-upload__percent">
                        <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                        <div className="edit-user-upload__bar">
                          <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                        </div>
                      </div>
                    </div> : ''}
                    {this.state.setImage ?
                      <div className="edit-product-img">
                        <div><button className="edit-product-img__remove" type="button" onClick={this.handlerDeleteImg.bind(this)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><img src={this.state.previewImg} alt="" /></div>
                      </div>
                      : ''}
                  </div>
                  {this.state.errorImageEmpty && !this.state.setImage ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                </div>
              </div>
              <div className="rewards-form__inner rewards-form__inner_border_none rewards-form__inner_border_top">
                <div className="field-wrap__submit event-form__nav">
                  <button className="btn btn_size_lg" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('rewards-BtnNextPreview')}</button>
                </div>
              </div>
            </div>
          </div>

        </div>}
      </div>
    </div>


    {this.state.mainPhotoModal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal modal-reward-icons">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('rewards-ModalImageHeadline')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="modal-image-edit">
        {this.state.previewImg ? <img className="modal-image-edit__preview" src={this.state.previewImg} alt="" /> : (this.state.iconImgs ? <img className="modal-image-edit__preview" src={this.state.iconImgs[0]} alt="" /> : <img className="modal-image-edit__preview" src={this.state.iconImgs[0]} alt="" />)}
        <div className="modal-image-edit__nav">
          <div className="modal-image-edit__nav-btn-wrap">
            <button className={`modal-image-edit__nav-btn ${this.state.modalEditTab ? '' : 'active'}`} type="button" onClick={this.handlerModalEditTab.bind(this, false)}>{this.props.getTranslate('rewards-ModalImageTabChooseHeadline')}</button>
          </div>
          <div className="modal-image-edit__nav-btn-wrap">
            <button className={`modal-image-edit__nav-btn ${this.state.modalEditTab ? 'active' : ''}`} type="button" onClick={this.handlerModalEditTab.bind(this, true)}>{this.props.getTranslate('rewards-ModalImageTabUploadHeadline')}</button>
          </div>
        </div>
        {this.state.modalEditTab ? <div className="modal-image-edit__content">
          <div className="modal-image-edit__upload">
          {this.state.mainImgName === '' ? <>
              <label htmlFor="upload-image-edit" className="modal-image-edit__upload-label">{this.props.getTranslate('companyinfo-modalCardTabLoadImg')}</label>
              <input id="upload-image-edit" type="file" onChange={this.handlerPreviewImg.bind(this)} />
          </> : <div className="upload-image-info">{this.state.mainImgName}
            <button className="upload-image-info__btn" type="button" onClick={this.handlerDeletePreview.bind(this)}>
              <svg className="upload-image-info__delete">
                <use href={`${sprite}#delete`}></use>
              </svg>
            </button>
          </div>}
          </div>
          {this.state.errorNotChooseImage ? <div className="validation-error" style={{textAlign: "center"}}>Оберіть зображення</div> : ''}
          {this.state.errorFormatImgs ? <div className="validation-error" style={{textAlign: "center"}}>Формат зображень повинен бути jpg png або webp</div> : ''}
          {this.state.errorSizeImgs ? <div className="validation-error" style={{textAlign: "center"}}>Файл не повинен перевищувати 25 мегабайт</div> : ''}
          <div className="modal-image-edit__nav register-form__submit form-nav">
              <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="submit" disabled={this.state.isLoadedSubmitImage ? true : false} onClick={this.handlerSetImage.bind(this)}>{this.props.getTranslate('rewards-ModalImageBtnSave')}{this.state.isLoadedSubmitImage && <Loading />}</button>
          </div>
        </div> : <div className="modal-image-edit__content">
        <div className="modal-image-edit__imgs">

        {this.state.iconImgs.map((item, index) => {
          return <div className={`modal-image-edit__img ${(index === 0 && !this.state.previewImg) || item === this.state.previewImg ? 'active' : ''}`} key={index} onClick={this.handlerSetDefaultImg.bind(this, item, index)}>
                  <img src={item.replace( /https:/g, "http:" )} alt="" />
                </div>
        })}

        </div>
        {this.state.errorNotChooseImage ? <div className="validation-error" style={{textAlign: "center"}}>Оберіть зображення</div> : ''}
        <div className="modal-image-edit__nav register-form__submit form-nav">
            <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="button" disabled={this.state.isLoadedSubmitImage ? true : false} onClick={this.handlerSetImage.bind(this)}>{this.props.getTranslate('rewards-ModalImageBtnSave')}{this.state.isLoadedSubmitImage && <Loading />}</button>
        </div>
        </div>}
      </div>
    </div>
    </div> : ''}

    {this.state.modalDeleteReward ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-deleteRewardTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
        <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteRewardDescription')}</div>
        <div className="field-wrap__submit">
          <Link className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" to={this.props.getPermalink("/account/rewards")}>{this.props.getTranslate('removeModal-ButtonSubmit')}</Link>
        </div>
        </div>
    </div> : ''}

    {this.state.modal && this.state.modalAddMembers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="field-wrap field-wrap__search-members">
        <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
        <svg className="field-wrap__search-icon">
          <use href={`${sprite}#search-icon`}></use>
        </svg>
      </div>
      <div className="question-members">
        <div className="question-members-wgt">
          {users}
        </div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
          </div>
      </div>
    </div>
  </div> : ''}

    </>;
  }
}
export default (props) => <RewardsCreate {...useParams()} {...props} />
